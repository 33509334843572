import { ProtectedApi } from "../Helpers/api";
import { ADMIN } from "./User";

export const getAllSociety = () => {
  return ProtectedApi.get(`${ADMIN}/society/nonclient`);
};

export const getSocietyBySearch = (search) => {
  return ProtectedApi.get(`${ADMIN}/society/search?search=${search}`);
};
