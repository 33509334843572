import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UpdateTimesheetStatus } from "../../Services/Consultant";
import ListItem from "./ListItem";
import style from "./style.module.css";

const TimesheetsTable = ({ timesheets, consultantId }) => {
  const [timesheetList, setTimesheets] = useState([]);
  useEffect(() => {
    setTimesheets(timesheets);
  }, [timesheets]);
  const onApprove = async (timesheet) => {
    await UpdateTimesheetStatus({
      id: timesheet.id,
      status: "ADMIN_APPROVED",
      consultantId,
      missionId: timesheet.mission.id,
      year: timesheet.year,
      month: timesheet.month,
    }).then((res) => {
      setTimesheets([
        ...timesheetList.filter((t) => t.id !== timesheet.id),
        res.data,
      ]);
    });
  };

  const onReject = async (timesheet) => {
    await UpdateTimesheetStatus({
      id: timesheet.id,
      status: "REFUSED",
      consultantId,
      missionId: timesheet.mission.id,
      year: timesheet.year,
      month: timesheet.month,
    }).then((res) => {
      setTimesheets([
        ...timesheetList.filter((t) => t.id !== timesheet.id),
        res.data,
      ]);
    });
  };
  return (
    <div className={style["timesheets-table"]}>
      <div
        className={`${style["timesheets-table-item"]} ${style["timesheets-table-header"]}`}
      >
        <div className="small-text">Mois</div>
        <div className="small-text d-none d-md-flex">Fichier</div>
        <div className="small-text d-none d-md-flex">Date Envoi</div>
        <div className="small-text d-none d-md-flex">Date Validation</div>
        <div className="small-text">Statut</div>
        <div className="small-text text-center">Action</div>
      </div>
      {timesheetList?.length > 0 ? (
        timesheetList.map((t, i) => {
          return (
            <ListItem
              key={i}
              timesheet={t}
              onApprove={onApprove}
              onReject={onReject}
            />
          );
        })
      ) : (
        <div className="w-100 d-flex justify-content-center small-text p-5">
          Aucun compte rendu d'activité
        </div>
      )}
    </div>
  );
};

export default TimesheetsTable;
