import React from "react";
import EndOfMonthDashboard from "./components/EndOfMonthDashboard";
import LegaLFiles from "./components/LegalFiles";
import StarterFinisher from "./components/StarterFinisher";
import styles from "./style.module.css";

const Dashboard = () => {
  return (
    <div className={`${styles["container"]}`}>
      {/* DISABLE Alerts FOR THE MOMENT SINCE IT IS NOT USED */}
      {/* <AlertCenter /> */}
      <EndOfMonthDashboard></EndOfMonthDashboard>
      <StarterFinisher></StarterFinisher>
      <LegaLFiles></LegaLFiles>
    </div>
  );
};

export default Dashboard;
