import { ProtectedApi, ProtectedFilesApiMultiPart } from "../Helpers/api";
import { ADMIN } from "./User";

export const getClient = (id) => {
  return ProtectedApi.get(`${ADMIN}/society/` + id);
};
export const addClient = (clientBody) => {
  return ProtectedApi.post(`${ADMIN}/society`, clientBody, {
    withCredentials: true,
  });
};

export const addLogoClient = (logoClient, id) => {
  return ProtectedFilesApiMultiPart.post(
    `${ADMIN}${"/society/upload/logo/" + id}`,
    logoClient
  );
};

export const editClient = (id, clientUpdatedForm) => {
  return ProtectedApi.patch(`${ADMIN}/society/${id}`, clientUpdatedForm);
};

export const getAddClient = () => {
  return ProtectedApi.get(`${ADMIN}/society/all/types`);
};
