import React from "react";
import toast from "../../Helpers/toast";
import { deleteDocument, endContract } from "../../Services/Consultant";
import ListItem from "./Listtem";
import style from "./style.module.css";

const ContratsTable = ({ contracts = [], setContracts }) => {
  const onTerminate = async (contract) => {
    await endContract(contract).then(() => {
      setContracts((old) => [
        ...old.filter((c) => c.id !== contract.id),
        { ...contract, status: "EXPIRED", expirationDate: contract.endDate },
      ]);
      toast(
        "success",
        `Le date de fin du contrat a été placée au ${contract.endDate}`
      );
    });
  };

  const onDeleteHandler = (contract) => {
    deleteDocument(contract, "contract").then(() => {
      setContracts([...contracts.filter((c) => c.id !== contract.id)]);
      toast("success", "Contrat supprimé");
    });
  };

  const headerColumnStyle = {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "21px",
  };

  return (
    <div className={`${style["contracts-table"]}`}>
      <div
        className={`${style["contracts-table-item"]} ${style["contracts-table-header"]}`}
      >
        <div style={headerColumnStyle}>Type</div>
        <div style={headerColumnStyle}>Date de signature</div>
        <div style={headerColumnStyle}>Date de début</div>
        {/* <div style={headerColumnStyle}>Date de M.A.J</div> */}
        <div style={headerColumnStyle}>Date d'expiration initiale</div>
        <div style={headerColumnStyle}>Date d'expiration</div>
        <div style={headerColumnStyle}>TJM d'achat</div>
        <div style={headerColumnStyle}>Fichier</div>
        <div style={headerColumnStyle}>Statut</div>
        <div className="small-text text-center">Actions</div>
      </div>
      {contracts.length > 0 ? (
        contracts.map((c, i) => {
          return (
            <ListItem
              key={c.id}
              contract={c}
              onTerminate={onTerminate}
              onDelete={onDeleteHandler}
              setContractsHandler={setContracts}
            />
          );
        })
      ) : (
        <div className="w-100 p-5 small-text text-center">Aucun contrat</div>
      )}
    </div>
  );
};

export default ContratsTable;
