import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import ClientNavigation from "../../../Components/ClientNavigation";
import { Card } from "../../../UI/Card";
import Image from "../../../UI/Image";
import Loader from "./../../../Components/Loader/index";
import { getClient } from "./../../../Services/Client";

import styles from './style.module.css';

const ClientHeaderDetails = ({ client }) => {
  return (
      <div className="d-flex flex-row">
        <div className="d-flex flex-column" style={{marginRight:"auto"}}>
          <div className="header-2">{client.corporateName}</div>
          <ClientNavigation />
        </div>
        <Image logo={client.logo} classes={`${styles['absoluteHeaderLogo']}`} />
      </div>);
}

const IdentiteClient = ({ match }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);

  useEffect(() => {
    setLoading(true);
    getClient(match.params.id)
      .then((res) => {
        setClient(res.data);
        setLoading(false);
      })
      .catch((e) => history.push("/clients/comptes"));
  }, [match, history]);

  return (
    <div>
      {loading && (
        <Card className="w-100 position-relative card" style={{ height: "300px" }}>
          <Loader />
        </Card>
      )}

      {!loading && client &&
        <ClientHeaderDetails client={client}></ClientHeaderDetails>
      }

    </div >
  );
};

export default withRouter(IdentiteClient);
