import { useEffect, useState } from "react";
import Loader from "../../../../Components/Loader";
import { findAllFinisherByQuery, findAllStarterByQuery } from "../../../../Services/Dashboard";
import StarterFinisherDashboard from "./StarterFinisherDahboard";

const StarterFinisher = () => {

    const [starters, setStarters] = useState([]);
    const [finishers, setFinishers] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false);

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');

    const [currentMonthStarters, setCurrentMonthStarters] = useState(month);
    const [currentYearStarters, setCurrentYearStarters] = useState(year);

    const [currentMonthFinishers, setCurrentMonthFinishers] = useState(month);
    const [currentYearFinishers, setCurrentYearFinishers] = useState(year);

    const getCurrentDateStarters = () => {
        return `${currentYearStarters}-${currentMonthStarters}`
    }
    const getCurrentDateFinishers = () => {
        return `${currentYearFinishers}-${currentMonthFinishers}`
    }  
    
    const handleMonthChange = (event, setMonth, setYear) => {
        const monthValue = event.target.value;
        const split = monthValue ? monthValue.split('-') : null;
        const month = split && split[1] ? split[1] : null;
        const year = split ? split[0] : today.getFullYear();
        setMonth(month);
        setYear(year);
    };

    const onChangeMonthFinisherHandler = (event) => {
        handleMonthChange(event, setCurrentMonthFinishers, setCurrentYearFinishers);
    };

    const onChangeMonthStarterHandler = (event) => {
        handleMonthChange(event, setCurrentMonthStarters, setCurrentYearStarters);
    };

    useEffect(() => {
        findAllStarterByQuery(currentMonthStarters, currentYearStarters)
                .then(res => setStarters(res.data))
    }, [currentMonthStarters, currentYearStarters]);

    useEffect(() => {
        findAllFinisherByQuery(currentMonthFinishers, currentYearFinishers)
            .then(res => setFinishers(res.data))
    }, [currentMonthFinishers, currentYearFinishers]);

    return (<div>
        {isLoading && <div
            className="w-100 position-relative card my-2 p-4"
            style={{ height: "300px" }}
        >
            <h5 className="green-text">Chargement Finishers & Starters</h5>
            <Loader />
        </div>}

        {!isLoading &&
            (<div>
                <StarterFinisherDashboard key={'starter'} title={'Starters'} list={starters}>
                    <div className="w-25">
                        <label htmlFor='month'>Mois :</label>
                        <input id='month' className="default-input" type="month" value={getCurrentDateStarters()} onChange={onChangeMonthStarterHandler}></input>
                    </div>
                </StarterFinisherDashboard>
                <StarterFinisherDashboard key={'finisher'} title={'Finishers'} list={finishers}>
                    <div className="w-25">
                        <label htmlFor='month'>Mois :</label>
                        <input id='month' className="default-input" type="month" value={getCurrentDateFinishers()} onChange={onChangeMonthFinisherHandler}></input>
                    </div>
                </StarterFinisherDashboard>
            </div>)
        }
    </div>)
}

export default StarterFinisher;

