import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import style from "./style.module.css";

const ClientNavigation = ({ match }) => {
  return (
    <ul className={`${style["ul"]}`}>
      <li>
        <NavLink
          to={`/client/${match.params.id}/carnet-adresses`}
          exact
          className={`small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          <h5>Carnets d'adresses</h5>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/client/${match.params.id}/contrats-bdc`}
          exact
          className={`small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          <h5>Contrats et BDC</h5>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/client/${match.params.id}/modifier`}
          exact
          className={`small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          <h5>Le client</h5>
        </NavLink>
      </li>
    </ul>
  );
};

export default withRouter(ClientNavigation);
