import style from "./style.module.css";
import React, { useRef, useEffect, useState } from "react";

const DropDownMenu = ({ 
    menuOptions, elementRef, closeCallback
}) => {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const menuRef = useRef();

    useEffect(() => {
        if (menuRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const parentRect = elementRef.current.offsetParent.getBoundingClientRect();
            setMenuPosition({ 
                top: `${rect.bottom - parentRect.top}px`,
                left: `${rect.left - parentRect.left + elementRef.current.offsetWidth - menuRef.current.offsetWidth}px`,
            });
        }
    }, []);

    const closeOpenMenus = (e) => {
        if(menuRef.current && !menuRef.current.contains(e.target)){
            closeCallback();
        }
      }

    // document.addEventListener('mousedown',closeOpenMenus,{once: true})

    document.body.addEventListener('click', closeOpenMenus,{once: true});
  
   return (
    <div 
        ref={menuRef}
        className={style['drop-down-menu']}
        style={menuPosition}>        
        {menuOptions?.length > 0 && (
            menuOptions.map((option, key) => (
                <button type="button" key={key} onClick={(e) => {
                    e.stopPropagation();
                    option.callback();
                    closeCallback();
                }}>{option.label}</button>
            ))
        )}
    </div>
    );
};

export default DropDownMenu;