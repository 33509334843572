import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "../../Helpers/toast";
import style from "./style.module.css";
import { getAllJobDepartments, addJobDepartment, editJobDepartment, deleteJobDepartment } from "./../../Services/JobDepartments";
import { levenshteinDistance } from "../../Helpers/string"
import { FiX, FiEdit } from "react-icons/fi";
import Popup from "./../../Components/Popup";
import Loader from "./../../Components/Loader/index";
import { EBusinessUnit } from '../../Helpers/enums/business-unit.enum'
import { getColorCode } from '../../Helpers/styles/get-color-by-bu'
import SearchBar from "../../Components/SearchBar";

const JobDepartments = () => {
  const [loading, setLoading] = useState(false);
  const [jobDepartments, setJobDepartments] = useState([]);
  const [closeJobDepartments, setCloseJobDepartments] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedJobDepartment, setSelectedJobDepartment] = useState({ label: "" });
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(EBusinessUnit.BU_TECH);
  const [existingJobDepartment, setExistingJobDepartment] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [isCreatePopup, setIsCreatePopup] = useState(false);
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const [createInputValue, setCreateInputValue] = useState("");
  const [editInputValue, setEditInputValue] = useState("");

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    getAllJobDepartments()
    .then((res) => {
      setJobDepartments([...res.data]);
    })
    .catch((error) => {
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  const openCreatePopup = () => {
    setCloseJobDepartments([])
    setExistingJobDepartment(false);
    setSelectedBusinessUnit(EBusinessUnit.BU_TECH);
    setCreateInputValue("");
    setIsCreatePopup(true);
  };

  const onChangeBusinessUnit = (e) => {
    setSelectedBusinessUnit(e.target.value);
  }

  const openEditPopup = (jobDepartment) => {
    setCloseJobDepartments([])
    setExistingJobDepartment(false);
    setEditInputValue(jobDepartment.label);
    setSelectedJobDepartment(jobDepartment);
    setSelectedBusinessUnit(jobDepartment.businessUnit[0]);
    setIsEditPopup(true);
  };

  const openRemovePopup = (jobDepartment) => {
    setSelectedJobDepartment(jobDepartment);
    setIsRemovePopup(true);
  };

  const isExistingJobDepartment = (jobDepartmentName) => {
    return jobDepartments.find((jd) => {
      if (jd.label.trim().toLowerCase() === jobDepartmentName.trim().toLowerCase()) {
        return true;
      }
      return false;
    });
  }

  const getCloseJobDepartments = (jobName) => {
    return jobDepartments.filter((s) => {
      const distance = levenshteinDistance(jobName.trim().toLowerCase(), s.label.trim().toLowerCase());
      if (distance) return distance < 3;
      return false
    })
  }

  const filterJobDepartmentsByLabel = (jobDepartments) => {
    return jobDepartments.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }
  
  
  const submitAddJob = (e) => {
    e.preventDefault();
    const existing = isExistingJobDepartment(e.target.createLabel.value);
    setExistingJobDepartment(existing);
    if (!existing) {
      setIsCreatePopup(false);
      addJobDepartment(e.target.createLabel.value.trim(), selectedBusinessUnit).then(() => {
        toast("success", "La catégorie métier a été créée !");
        reload();
      },
      (error) => {
        toast("error", error.response.data.reason?.message[0] ?? error.response.data.reason);
      })
      .finally(() => {
        setCreateInputValue("");
      })
    }
  }

  const submitEditJob = (e) => {
    e.preventDefault();
    setIsEditPopup(false);
    editJobDepartment(selectedJobDepartment.id, e.target.editLabel.value.trim(), selectedBusinessUnit).then((res) => {
      toast("success", "La catégorie métier a été modifiée !");
      reload();
    },
    (error) => {
      toast("error", error.response.data.reason?.message[0] ?? error.response.data.reason);
    });
    setEditInputValue("");
  };

  const submitDeleteJob = () => {
    deleteJobDepartment(selectedJobDepartment?.id).then(() => {
      toast("success", "La catégorie métier a été supprimée !");
      reload();
    },
    (error) => {
      toast("error", error.response.data.reason);
    });
    setIsRemovePopup(false)
  };

  return (
    <div className={`${style['container']} py-4 px-5`}>
    <Popup show={isEditPopup} size="sm" onClose={() => setIsEditPopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitEditJob}
      >
        <div className="col-12 wow-subtitle bold">
          Modifier la catégorie métier "{selectedJobDepartment?.label}"
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom
          </label>
          <input
            id="editLabel"
            name="editLabel"
            className="default-input"
            type="text"
            value={editInputValue}
            onChange={(e) => {  setEditInputValue(e.target.value );
                                const closeJobDepartments = getCloseJobDepartments(e.target.value);
                                setCloseJobDepartments(closeJobDepartments); }}
          />
          {existingJobDepartment ? (
          <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
            La catégorie métier est déjà existante.
          </div>
          ) : null}
          {!!closeJobDepartments.length && (
          <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Catégories métier(s) semblable(s) :
            <div className={style["job-container"]}>
                {closeJobDepartments.map((jd, i) => {
                  return  <span key={i} className={style["job"]} style={{paddingRight: "24px",background: getColorCode(jd.businessUnit[0])}} >
                            {jd.label}
                          </span>
                })}
              </div>
          </div>
          )}
          <label className="small-text mt-1">Business Unit :</label>
              <select className="default-input" style={{background: getColorCode(selectedBusinessUnit)}}  onChange={onChangeBusinessUnit} value={selectedBusinessUnit}>
                  {
                  Object.keys(EBusinessUnit).map((key) => <option style={{background: getColorCode(EBusinessUnit[key])}} value={EBusinessUnit[key]} key={key}>
                    {EBusinessUnit[key]}
                    </option>)
                  }
          </select>
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={() => setIsEditPopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isCreatePopup} size="sm" onClose={() => setIsCreatePopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitAddJob}
      >
        <div className="col-12 wow-subtitle bold">
          Ajouter une nouvelle catégorie métier
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom :
          </label>
          <input
            id="createLabel"
            name="createLabel"
            className="default-input"
            type="text"
            value={createInputValue}
            onChange={(e) => {  setCreateInputValue(e.target.value );
                                const closeJobDepartments = getCloseJobDepartments(e.target.value);
                                setCloseJobDepartments(closeJobDepartments); }}
          />
          {existingJobDepartment ? (
            <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
              Catégorie métier déjà existante.
            </div>
          ) : null}
          {!!closeJobDepartments.length && (
            <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Catégories métier(s) semblable(s) :
            <div className={style["job-container"]}>
                {closeJobDepartments.map((jd, i) => {
                  return  <span key={i}  className={style["job"]} style={{paddingRight: "24px",background: getColorCode(jd.businessUnit[0])}} >
                            {jd.label}
                          </span>
                })}
              </div>
          </div>
          )}
          <label className="small-text mt-1">Business Unit :</label>
              <select className="default-input" style={{background: getColorCode(selectedBusinessUnit)}} onChange={onChangeBusinessUnit} value={selectedBusinessUnit}>
                  {
                  Object.keys(EBusinessUnit).map((key) => <option style={{background: getColorCode(EBusinessUnit[key])}} value={EBusinessUnit[key]} key={key}>
                    {EBusinessUnit[key]}
                    </option>)
                  }
          </select>
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={() => setIsCreatePopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isRemovePopup} size="sm" onClose={() => setIsRemovePopup(false)}>
      <div className="row justify-content-between p-3 py-4 gap-2">
        <div className="wow-subtitle bold">
          Supprimer la catégorie métier "{selectedJobDepartment?.label}"
        </div>
        <div>
          Confirmer pour supprimer cette métier.
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button className="default-btn danger-btn p-2" onClick={() => setIsRemovePopup(false)}>
            Annuler
          </button>
          <button type="button" className="default-btn p-2" onClick={submitDeleteJob}>
            Confirmer
          </button>
        </div>
      </div>
    </Popup>
        <div className="mb-4">
          <div className="header-2">Toutes les catégories métiers</div>
          <div className="d-flex flex-row gap-2">
            <SearchBar onSubmit={setSearch} placeholder='.NET, Scrum,...'/>
            <button style={{width:'120px'}} className="default-btn p-2" onClick={openCreatePopup}>
              Ajouter
            </button>
          </div>
        </div>
        <div>
          <div className="col-12 flex-column gap-3 align-items-center">
            {loading ? (
              <div
                className="w-100 position-relative card"
                style={{ height: "300px" }}
              >
                <Loader />
              </div>
            ) : jobDepartments.length ? (
              <div className={style["job-container"]}>
                {filterJobDepartmentsByLabel(jobDepartments.filter(job => {
                  return (job.label.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                })).map((jd, i) => {
                  return <span key={i} style={{background: getColorCode(jd.businessUnit[0])}} className={style["job"]}>
                    {jd.label}
                    <FiEdit
                      className={`${style["edit-job"]} ${style["rotate-icon"]}`}
                      onClick={() => openEditPopup(jd)}
                    />
                    <FiX 
                      className={`${style["delete-job"]} ${style["rotate-icon"]}`}
                      onClick={() => openRemovePopup(jd)}
                    />
                  </span>
                })}
              </div>
            ) : (
              <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
                Aucune catégorie métier
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default JobDepartments;
