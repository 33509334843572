import React from "react";
import ListItemContratApplication from "./Listtem";
import style from "./style.module.css";

const ContratApplicationTable = ({ contracts, onDelete, openPopup }) => {
  return (
    <div className={`${style["table"]}`}>
      <div className={`${style["table-item"]} ${style["table-header"]}`}>
        <div className="small-text">NOM CONSULTANT</div>
        <div className="small-text">INTITULE MISSION</div>
        <div className="small-text">DOCUMENTS</div>
        <div className="small-text">DATE DE SIGNATURE</div>
        <div className="small-text">DATE DEBUT</div>
        <div className="small-text">DATE FIN</div>
        <div className="small-text">TJM</div>
        <div className="small-text text-center">MANAGER</div>
        <div className="small-text text-center">REFERENCE SPECIFIQUE</div>
        <div className="small-text text-center">STATUT</div>
        <div className="small-text">ACTIONS</div>
      </div>
      {contracts.length > 0 ? (
        contracts.map((c, i) => {
          return (
            <ListItemContratApplication
              key={i}
              contract={c}
              onDelete={onDelete}
              openPopup={openPopup}
            />
          );
        })
      ) : (
        <div className="w-100 p-5 small-text text-center">
          Aucun contrat(s) d'application
        </div>
      )}
    </div>
  );
};

export default ContratApplicationTable;
