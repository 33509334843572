import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListItem } from "./ListItem";
import style from "./style.module.css";

export const CandidaturesTables = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    if (currentConsultant) {
      setApplications(currentConsultant.applications ?? []);
    }
  }, [currentConsultant]);
  return (
    <div className={style["applications-table"]}>
      <div
        className={`${style["applications-table-item"]} ${style["applications-table-header"]}`}
      >
        <div className="small-text">Mission</div>
        <div className="small-text d-none d-md-flex">Référence</div>
        <div className="small-text d-none d-md-flex">Client</div>
        <div className="small-text d-none d-md-flex">
          Date de la candidature
        </div>
        <div className="small-text">Statut</div>
      </div>
      {!applications.length ? (
        <div className="w-100 d-flex justify-content-center small-text p-5">
          Aucune candidature
        </div>
      ) : (
        applications.map((application, i) => {
          return <ListItem key={i} application={application} />;
        })
      )}
    </div>
  );
};
