import React, { useEffect, useState } from "react";
import { getAllSociety } from "../../Services/Society";
import Popup from "../Popup";

const SocietyPopup = ({ show, onClose, onValidate }) => {
	const [society, setSociety] = useState(undefined);
	const [societies, setSocieties] = useState([]);

	useEffect(() => {
		getAllSociety().then((res) => {
			setSociety(res.data[0]);
			setSocieties(res.data);
		});
	}, []);

	const handleValidation = () => onValidate(society);

	const handleChange = (e) => {
		setSociety(societies[e.target.value]);
	}

	return (
		<Popup
			show={show}
			onClose={(e) => {
				onClose(e);
			}}
		>
			<form className="row px-2 px-md-5 py-5 gap-4">
				<div className="col-12 d-flex flex-column">
					<div className="d-flex flex-row justify-content-between">
						<select onChange={handleChange}>
							{societies.map((soc, i) => (<option value={i}>{soc.label}</option>))}
						</select>
					</div>
				</div>

				<div className="col-12 d-flex flex-row justify-content-center justify-content-md-end gap-4">
					<div className="w-auto">
						<button
							type="reset"
							className="default-btn danger-btn"
							onClick={onClose}
						>
							Annuler
						</button>
					</div>
					<div className="w-auto">
						<button
							type="button"
							className="default-btn"
							onClick={handleValidation}
						>
							Enregistrer
						</button>
					</div>
				</div>
			</form>
		</Popup>
	);
};

export default SocietyPopup;
