import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import ManagerCard from "../../../Components/ManagerCard";
import { getManagers } from "../../../Services/Manager";
import { Link } from "react-router-dom";
import Loader from "./../../../Components/Loader/index";

import styles from './style.module.css';
import { FiPlus } from "react-icons/fi";

const CarnetAdresses = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [managers, setManagers] = useState([]);

  useEffect(() => {
    setLoading(true);
    getManagers({id: match.params.id})
      .then((res) => {
        setManagers(res.data);
        setLoading(false);
      })
      .catch((e) => history.push("/clients/comptes"));
  }, [match, history]);
  return (
    <div className="container">
      <div className={`${styles['btn']}`}>
        <Link to={`/client/${match.params.id}/ajouter-manager`}>
          <button className="default-btn">Ajouter un contact<FiPlus /></button>
        </Link>
      </div>
      <div className={`${styles['managers']}`}>
        {loading ? (
          <div
            className="w-100 position-relative card"
            style={{ height: "300px" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <ul>
              {managers && managers.length ? (
                managers.map((m, i) => {
                  return <li><ManagerCard manager={m} key={i} /></li>;
                })
              ) : (
                <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
                  Aucun manager
                </div>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default CarnetAdresses;
