import { ProtectedApi, ProtectedFilesApiMultiPart } from "../Helpers/api";
import { ADMIN } from "./User";

export const getConsultant = (id) => {
  return ProtectedApi.get(`${ADMIN}/consultant/${id}`);
};

export const getEditConsultant = (id) => {
  return ProtectedApi.get(`${ADMIN}/consultant/dashboard/modify`, {
    params: { id },
  });
};

export const editConsultant = (id, payload) => {
  return ProtectedApi.patch(`${ADMIN}/consultant/${id}`, payload);
};

export const editConsultantSkillMarks = async (id, payload) => {
  return ProtectedApi.patch(`${ADMIN}/consultant/consultantSkillMarks/${id}`, {
    ...payload,
  });
};

export const detachSociety = (id) => {
  return ProtectedApi.delete(`${ADMIN}/consultant/${id}/society`);
};

export const attachSociety = (consultantId, societyId) => {
  return ProtectedApi.post(
    `${ADMIN}/consultant/${consultantId}/society/${societyId}`
  );
};

export const UploadProfileFiles = async (formData, type, args) => {
  const urlArgs = Object.keys(args)
    .map((key) => `&${key}=${args[key]}`)
    .join("");
  const url = `/consultant-document/files/type?type=${type}${urlArgs}`;
  return ProtectedFilesApiMultiPart.post(url, formData);
};

export const deleteDocument = async (contract, type) => {
  const documentId = contract.id;
  const fileId = contract.file ? contract.file._id : "";
  const url = "/consultant-document/file";
  return ProtectedApi.delete(url, { params: { type, documentId, fileId } });
};

export const updateFile = async (type, fileId, file) => {
  const url = "/consultant-document/file";
  return ProtectedFilesApiMultiPart.patch(url, file, {
    params: { type, fileId },
  });
};

export const updateContract = async (contract) => {
  const url = `${ADMIN}/contract`;
  return ProtectedApi.patch(url, contract);
};

export const RejectConsultantFile = async (id, consultantId, type) => {
  return ProtectedApi.patch(`${ADMIN}/consultant/reject/${id}`, {
    consultantId,
    type,
  });
};
export const ApproveConsultantFile = async (
  id,
  consultantId,
  type,
  expirationDate
) => {
  const payload = { consultantId, type };
  if (expirationDate) {
    payload.expirationDate = expirationDate;
  }
  return ProtectedApi.patch(`${ADMIN}/consultant/approve/${id}`, payload);
};

// Contrats
export const getContracts = ({ consultantId, missionId }) => {
  return ProtectedApi.get(`${ADMIN}/contract`, {
    params: { consultantId, missionId },
  });
};
export const addContract = (payload) => {
  return ProtectedApi.post(`${ADMIN}/contract`, payload);
};

export const endContract = (contract) => {
  return ProtectedApi.patch(`${ADMIN}/contract/expire/${contract.id}`, {
    missionId: contract.mission.id,
    consultantId: contract.consultant.id,
    endDate: contract.endDate,
  });
};

//Fin de mois
export const getTimesheets = (id, idMission) => {
  return ProtectedApi.get(`/timesheet/consultant`, {
    params: { id, idMission },
  });
};
export const UpdateTimesheetStatus = async ({
  id,
  status,
  consultantId,
  year,
  month,
  missionId,
}) => {
  return ProtectedApi.patch(`/timesheet`, {
    id,
    status,
    consultant: { id: consultantId },
    mission: { id: missionId },
    year,
    month,
  });
};
export const GetInvoicesByMissionAndConsultant = (id, idMission) => {
  return ProtectedApi.get(
    `${ADMIN}/invoice/consultant/${id}/mission/${idMission}`
  );
};
export const RejectInvoice = async (id) => {
  return ProtectedApi.patch(`${ADMIN}/invoice/reject/${id}`);
};
export const ApproveInvoice = async (id) => {
  return ProtectedApi.patch(`${ADMIN}/invoice/approve/${id}`);
};
export const PayInvoice = async (id) => {
  return ProtectedApi.patch(`${ADMIN}/invoice/pay/${id}`);
};

export const rejectApplication = async (
  id,
  sendMailRejectedApplicationBody
) => {
  const url = `${ADMIN}/application/${id}${
    sendMailRejectedApplicationBody
      ? `?sendMailRejectedApplication=true&missionId=${sendMailRejectedApplicationBody.missionId}&consultantId=${sendMailRejectedApplicationBody.consultantId}`
      : ""
  }`;
  return ProtectedApi.patch(url, {
    id,
    status: "Refusée",
  });
};


export const approveApplication = async (
  id,
  sendMailRejectedApplicationBody
) => {
  const url = `${ADMIN}/application/${id}${
    sendMailRejectedApplicationBody
      ? `?sendMailRejectedApplication=false&missionId=${sendMailRejectedApplicationBody.missionId}&consultantId=${sendMailRejectedApplicationBody.consultantId}`
      : ""
  }`;
  return ProtectedApi.patch(url, {
    id,
    status: "Approuvée",
  });
};

export const getDocumentByIdAndType = (id, type) => {
  const url = `/consultant-document/files/documents/${id}/${type}`;
  return ProtectedApi.get(url);
};
