import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiMinus, FiPlus, FiSearch, FiX } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router";
import CustomCheckbox from "../../../Components/CustomCheckbox/index";
import toast from "../../../Helpers/toast";
import {
  addMission,
  getAddMission
} from "../../../Services/Mission";
import { getSkills } from "../../../Services/Skills";
import style from "./style.module.css";

const AjouterMission = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [selectedMissionType, setSelectedMissionType] = useState("");
  const [selectedWorkRythmn, setSelectedWorkRythmn] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");

  const [durations, setDurations] = useState([]);
  const [workRythms, setRythms] = useState([]);
  const [missionTypes, setTypes] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tasks, setTasks] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: "",
      dailyAverage: "",
      ref: "",
      isConfidential: false,
      isHidden: false,
      society: "",
      description: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
      },
      type: "",
      duration: "",
      monthsDuration: 1,
      startDate: "",
      workRythmn: "",
      task: "",
    },
    onSubmit: (values) => {
      let formValues = {
        ...values,
        skills: skills.map((skill) => ({
          id: skill.id,
        })),
        tasks,
        society: {
          id: match.params.id,
        },
        type: selectedMissionType,
        duration: selectedDuration,
        workRythmn: selectedWorkRythmn,
      };

      addMission(formValues).then(() => {
        toast("success", "Mission ajoutée");
        history.push(`/client/${match.params.id}`);
      });
    },
    validate: (values) => {
      const duration = values.duration || selectedDuration;
      let errors = {};
      if (duration === "LONG" && values.monthsDuration < 12) {
        errors.monthsDuration =
          "La durée en mois d'une mission longue doit être > 12.";
      } else if (
        duration === "MEAN" &&
        (values.monthsDuration < 6 || values.monthsDuration > 12)
      ) {
        errors.monthsDuration =
          "La durée en mois d'une mission moyenne doit être comprise entre 6 et 12 mois.";
      } else if (duration === "SHORT" && values.monthsDuration > 6) {
        errors.monthsDuration =
          "La durée en mois d'une mission courte doit être comprise entre 1 et 6 mois.";
      }
      return errors;
    },
  });
  const onSearch = (search) => {
    getSkills(search).then((res) => setSearchData(res.data));
  };
  const onCloseSearch = () => {
    setSearchData([]);
  };

  const onAddSkill = (skill) => {
    const exist = skills.reduce((prev, cur) => {
      return prev || cur.label === skill.label;
    }, false);
    if (!exist) {
      setSkills([...skills, { ...skill }]);
    }
    setSearchData([]);
  };
  const onDeleteSkill = (skill) => {
    setSkills(skills.filter((s) => s.label !== skill.label));
  };

  const addTask = (e) => {
    if (formik.values.task !== "") {
      setTasks([...tasks, formik.values.task]);
      formik.setFieldValue("task", "");
    }
  };
  const deleteTask = (i) => {
    setTasks(tasks.filter((t, index) => index !== i));
  };

  const onChangeMissionType = (e) => {
    const value = e.target.value;
    setSelectedMissionType(value);
  };

  const onChangeDuration = (e) => {
    const value = e.target.value;
    setSelectedDuration(value);
    formik.validateForm({ ...formik.values, duration: value });
  };

  const onChangeWorkRythmn = (e) => {
    const value = e.target.value;
    setSelectedWorkRythmn(value);
  };

  useEffect(() => {
    getAddMission().then((res) => {
      setDurations(res.data.duration);
      const selectedDuration = res.data.duration[0];
      setSelectedDuration(selectedDuration[0]);
      setRythms(res.data.workRythmn);
      const selectedWorkRythmn = res.data.workRythmn[0];
      setSelectedWorkRythmn(selectedWorkRythmn[0]);
      setTypes(res.data.missionType);
      const selectedMissionType = res.data.missionType[0];
      setSelectedMissionType(selectedMissionType[0]);
    });
  }, []);

  return (
    <form className="container py-4 px-5 border-light-grey" onSubmit={formik.handleSubmit}>
      <div className="row card px-4 py-4 gap-4">
        <div className="col-12 d-flex flex-column gap-2">
          <div className="header-4 green-text">Ajouter une mission</div>
          <label className="small-text bold cursor-pointer" htmlFor="label">
            Titre
          </label>
          <input
            id="label"
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex flex-row gap-4 align-items-end">
          <div className="w-100 mb-3">
            <CustomCheckbox
              value={formik.values.isConfidential}
              name="isConfidential"
              label="Entreprise confidentielle"
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-3">
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="dailyAverage"
            >
              TJM achat
            </label>
            <input
              id="dailyAverage"
              name="dailyAverage"
              value={formik.values.dailyAverage}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="dailyAverageResale"
            >
              TJM revente
            </label>
            <input
              id="dailyAverageResale"
              name="dailyAverageResale"
              value={formik.values.dailyAverageResale}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="ref">
              Ref inter
            </label>
            <input
              id="ref"
              name="ref"
              value={formik.values.ref}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-3">
          <div className="w-100 d-flex flex-row justify-content-between">
            <label className="small-text bold cursor-pointer" htmlFor="address">
              Adresse
            </label>
            <div className="w-50 d-flex flex-column justify-content-end flex-md-row gap-4 gap-md-5">
              {missionTypes.map((mt, i) => {
                return (
                  <div key={i} className="d-flex flex-row align-items-center">
                    <input
                      type="radio"
                      id={mt[0]}
                      name="type"
                      value={mt[0]}
                      checked={selectedMissionType === mt[0]}
                      onChange={onChangeMissionType}
                      hidden
                    />
                    <label className="custom-radio" htmlFor={mt[0]} />
                    <label
                      htmlFor={mt[0]}
                      className="small-text mx-2 cursor-pointer"
                    >
                      {mt[1]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-100 d-flex flex-row gap-3">
            <input
              id="address.street"
              name="address.street"
              value={formik.values.address.street}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              placeholder="Adresse"
            />
            <input
              id="address.postalCode"
              name="address.postalCode"
              value={formik.values.address.postalCode}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              placeholder="Code postal"
            />
            <input
              id="address.city"
              name="address.city"
              value={formik.values.address.city}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              placeholder="Ville ou région"
            />
            <input
              id="address.country"
              name="address.country"
              value={formik.values.address.country}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              placeholder="Pays"
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-3">
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer">Durée</label>
            <select
              id="duration"
              name="duration"
              value={selectedDuration}
              onChange={onChangeDuration}
              className="default-input"
              type="text"
              required
            >
              {durations.map((duration, i) => {
                return (
                  <option key={i} value={duration[0]}>
                    {duration[1]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer">
              Durée (en mois)
            </label>
            <input
              id="monthsDuration"
              name="monthsDuration"
              value={formik.values.monthsDuration}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              min={1}
              required
            />
            {formik.errors.monthsDuration && (
              <div className={style["field-error"]}>
                {formik.errors.monthsDuration}
              </div>
            )}
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer">
              Type de contrat
            </label>
            <select
              id="workRythmn"
              name="workRythmn"
              value={selectedWorkRythmn}
              onChange={onChangeWorkRythmn}
              className="default-input"
              type="text"
              required
            >
              {workRythms.map((wr, i) => {
                return (
                  <option key={i} value={wr[0]}>
                    {wr[1]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="startDate"
            >
              Date début
            </label>
            <input
              id="startDate"
              name="startDate"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              className="default-input"
              type="date"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          {searchData?.length > 0 && (
            <div
              className="close-search-bg"
              onClick={onCloseSearch}
            ></div>
          )}
          <div className="small-text bold">Ajoutez des compétences</div>
          <div className="w-100 position-relative">
            <FiSearch className={style["search-icon"]} />
            <input
              type="text"
              onChange={(e) => {
                if (e.target.value !== "") {
                  onSearch(e.target.value);
                } else {
                  onCloseSearch();
                }
              }}
              className={style["search-input"] + " default-input"}
              placeholder="Rechercher ici des compétences"
            />
            <div className="search-content">
              {searchData?.map((d, i) => (
                <div key={i} onClick={(e) => onAddSkill(d)}>
                  {d.label}
                </div>
              ))}
            </div>
          </div>
          <div className="w-100 d-flex flex-row flex-wrap gap-3">
            {skills.map((s) => (
              <span key={s.id} className={style["skill"]}>
                {s.label}
                <FiX
                  className={style["delete-skill"]}
                  onClick={(e) => onDeleteSkill(s)}
                />
              </span>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-3">
          <div className="small-text bold">Les points clés</div>
          {tasks.map((t, i) => {
            return (
              <div
                key={i}
                className="w-100 d-flex flex-row align-items-center gap-3"
              >
                <div className="default-input">{t}</div>
                <div>
                  <button
                    type="button"
                    className="default-btn danger-btn"
                    onClick={(e) => deleteTask(i)}
                  >
                    <FiMinus />
                  </button>
                </div>
              </div>
            );
          })}
          <div className="w-100 d-flex flex-row align-items-center gap-3">
            <input
              name="task"
              id="task"
              value={formik.values.task}
              onChange={formik.handleChange}
              type="text"
              className="default-input"
            />
            <div>
              <button
                type="button"
                className="default-btn"
                onClick={addTask}
              >
                <FiPlus />
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <div className="small-text bold" htmlFor="description">
            Description
          </div>
          <textarea
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            className="text-area default-input"
            type="text"
            required
          />
        </div>
        <div className="w-100 mb-3">
          <CustomCheckbox
            value={formik.values.isHidden}
            name="isHidden"
            label="Masquer la mission (ne pas afficher en front consultant)"
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-12 d-flex flex-row justify-content-center">
          <div>
            <button type="submit" className="default-btn">
              Sauvegarder et partager
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AjouterMission;
