import React from "react";
import { applicationStatuses } from "../../../Helpers/data";
import style from "../style.module.css";

export const ListItem = ({ application }) => {
  return (
    <div className={`${style["applications-table-item"]}`}>
      <div className="small-text bold text-truncate">
        {application.mission.label}
      </div>
      <div className="small-text d-none d-md-flex">
        {application.mission.ref}
      </div>
      <div className="small-text d-none d-md-flex">
        {application.mission.society.label}
      </div>
      <div className="small-text d-none d-md-flex">
        {application.createdAt &&
          new Date(application.createdAt).toLocaleDateString()}
      </div>
      <div
        className={`${style["applications-status"]} small-text`}
        status={application.status}
      >
        {applicationStatuses[application.status]}
      </div>
    </div>
  );
};
