import React, { useEffect, useState } from "react";
import { EMissionStatus } from "../../Helpers/enums/mission-status.enum";
import { SuggestiveResults } from "../../Components/SuggestiveResults";
import InfoCardSmall from "../../Components/InfoCard/InfoCardSmall";
import { useSuggestions } from "../../Hooks/useSuggestions";
import toast from "../../Helpers/toast";
import Loader from "../../Components/Loader";
import SearchBar from "../../Components/SearchBar";
import { getSocietyBySearch } from "../../Services/Society"
import { getMissions } from "../../Services/Mission"
import { getCandidatesBestMatches, getCandidatesBestMatchesPrompt } from "../../Services/AI"
import style from './style.module.css'
import { FiChevronRight, FiClock, FiDollarSign, FiMapPin, FiHexagon, FiUsers } from "react-icons/fi";
import ConsultantRowCard from "../../Components/ConsultantCard/ConsultantRowCard";
import { getUrlAdminMission } from "../../config";

export const Matching = () => {
  const [consultants, setConsultants] = useState([]);
  const [loading, setLoading] = useState({mission: false,prompt: false});
  const [selectedSociety, setSelectedSociety] = useState(
  );
  const [selectedMission, setSelectedMission] = useState(
  );
  const [rules, setRules] = useState();
  const [rulesHidden, setRulesHidden] = useState(true);

  const [inputMessage, setInputMessage] = useState();
  const [inputMessageHidden, setInputMessageHidden] = useState(true);

  const [outputMessage, setOutputMessage] = useState();
  const [outputMessageHidden, setOutputMessageHidden] = useState(true);

  const suggestionModuleMission = useSuggestions({ callFunction: (s) => {
    return getMissions({society: selectedSociety?.id, search:s, orderBy:'createdAt', status: [EMissionStatus.CLASSIC]}).then((res) => res.data.missions)
  } });
  const suggestionModuleSociety = useSuggestions({ callFunction: (s) => getSocietyBySearch(s).then((res) => res.data) });

  const isUuid = (value) => {
    const regexPatternUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    const myRegex = new RegExp(regexPatternUuid);

    return myRegex.test(value);
  }

  const selectSuggestionSocietyHandler = (e) => {
    suggestionModuleSociety.setDisplaySuggestions((oldState) => !oldState);
    setSelectedSociety(e);
    setSelectedMission(null);
    setConsultants([]);
  };

  const selectSuggestionMissionHandler = (e) => {
    suggestionModuleMission.setDisplaySuggestions((oldState) => !oldState);
    setSelectedMission(e);
    setConsultants([]);
  }

  const executePrompt = () => {
    setLoading({mission: false, prompt: true});
    if(selectedMission) {
      getCandidatesBestMatches(selectedMission.id).then((res) => {
        setOutputMessage(res.data.output)
        setConsultants(res.data.consultants)
      },
      (error) => {
        toast("error", error.response.data.reason);
      }).finally(() => {
        setLoading({mission: false, prompt: false});
      });
    } else {
      setLoading({mission: false, prompt: false});
    }
  }

  const openEditMissionTab = () => {
    if(selectedMission?.society?.id && selectedMission?.id) {
      const url = getUrlAdminMission() + `editer/${selectedMission.id}`
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    setLoading({mission: true, prompt: false});
    if(selectedMission) {
        getCandidatesBestMatchesPrompt(selectedMission.id).then((res) => {
          setRules(res.data.rules);
          setInputMessage(res.data.input);
        },(error) => {
          toast("error", error.response.data.reason);
        }).finally(() => {
          setLoading({mission: false, prompt: false});
        });
    } else {
      setLoading({mission: false, prompt: false});
    }
  }, [selectedMission]);


  return (
    <div className="container py-4 px-5">
      <div className="d-flex flex-row mb-4 gap-3 justify-content-between">
        <div style={{width: "45%"}}>
          <label htmlFor="society">Société</label>
          <SearchBar searchValue={selectedSociety?.label ? selectedSociety.label : suggestionModuleSociety.search} onChange={suggestionModuleSociety.onChangeSearch} onClick={() => {
            suggestionModuleSociety.onClickSuggestion();
            setSelectedSociety(null);
          }} placeholder='Rechercher par nom de société (ex: ADEO)'/>
          {suggestionModuleSociety.displaySuggestions && suggestionModuleSociety.suggestions?.length > 0 && (
            <SuggestiveResults
              results={suggestionModuleSociety.suggestions}
              onClick={selectSuggestionSocietyHandler}
            />
          )}
        </div>
        <div style={{width: "45%"}}>
          <label htmlFor='finder'>Mission</label>
          <SearchBar searchValue={selectedMission?.label ? selectedMission.label : suggestionModuleMission.search} onChange={suggestionModuleMission.onChangeSearch} onClick={() => {
            suggestionModuleMission.onClickSuggestion();
            setSelectedMission(null);
          }} placeholder='Rechercher par nom de mission (ex: Data Engineer)'/>
          {suggestionModuleMission.displaySuggestions && suggestionModuleMission.suggestions?.length > 0 && (
            <SuggestiveResults
              results={suggestionModuleMission.suggestions}
              onClick={selectSuggestionMissionHandler}
            />
          )}
        </div>
      </div>
      {loading.mission ? (
      <div className="d-flex flex-column position-relative">
        <div
          className={`px-4 ${style['block-a']}`}
          style={{ height: "400px" }}
        >
          <Loader />
        </div>
      </div>
      ) : !selectedMission ? (
      <div className={`d-flex flex-column ${style['block-a']} align-items-center`}>
        <title>Aucune mission n’est selectionnée</title>
        <div className="px-5">
          Utilisez la barre de recherche supérieur droite pour sélectionner directement une mission.
          Pour appliquer un filtre sur la liste des missions, filtrez par société en utilisant la barre de recherche gauche.
        </div>
      </div>
      ) : loading.prompt ? (
      <div className="d-flex flex-column position-relative">
        <div
          className={`px-4 ${style['block-a']}`}
          style={{ height: "400px" }}
        >
          <Loader />
        </div>
      </div>
      ) : (
      <div className="d-flex flex-column gap-3">
        <div className={`d-flex flex-row gap-5 ${style['block-a']} align-items-start`} style={{justifyContent:'space-between'}}>
          <div className={`d-flex flex-column gap-3 align-items-start`}>
            <title>{selectedMission.label}</title>
            <div>{selectedMission.description}</div>
          </div>
          <div className={`d-flex flex-column mx-4 gap-5`} style={{width:'400px'}}>
            <div className={`d-flex flex-column gap-3`} >
              <div className={`d-flex flex-row gap-3`}>
                <InfoCardSmall label="TJM achat" value={selectedMission.dailyAverageResale} iconComponent={<FiDollarSign className="header-4 text-white"/>}/>
                <InfoCardSmall label="TJM vente" value={selectedMission.dailyAverage} iconComponent={<FiDollarSign className="header-4 text-white"/>}/>
              </div>
              <div className={`d-flex flex-row gap-3`}>
                <InfoCardSmall label="Démarrage" value={selectedMission.startDate && new Date(selectedMission.startDate).toLocaleDateString()} iconComponent={<FiClock className="header-4 text-white"/>}/>
                <InfoCardSmall label="Localisation" value={selectedMission?.address?.city} iconComponent={<FiMapPin className="header-4 text-white"/>}/>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3">
              {selectedMission.skills.map((s, i) => {
                return (
                  <div style={{backgroundColor:'var(--primary-blue)',color:'white', borderRadius:'5px', padding:'12px', fontWeight:600}} key={i}>
                    {s.label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={`pb-3`}>
          <button className="default-btn" style={{fontWeight:600, fontSize:16}} onClick={openEditMissionTab}>Modifier la mission</button>
        </div>
        {inputMessage && (
        <div className="d-flex flex-column gap-3">
          <div className={`d-flex flex-column ${style['block-a']} align-items-start gap-3`}>
            <title>Prompt</title>
            <div className='d-flex flex-row gap-4' style={{width: '-webkit-fill-available'}}>
              <div className="d-flex flex-column" style={{width: '-webkit-fill-available'}}>
                <div>
                  <button className={`d-flex flex-row ${style['toggle']}`} onClick={() => {setRulesHidden(!rulesHidden)}}>
                    <FiChevronRight style={!rulesHidden && {transform:'rotate(90deg'}}/>
                    <h1>Règles</h1>
                  </button>
                  {!rulesHidden && (
                  <pre className={style['prompt']}>{rules}</pre>
                  )}
                </div>
                <div>
                  <button className={`d-flex flex-row ${style['toggle']}`} onClick={() => {setInputMessageHidden(!inputMessageHidden)}}>
                    <FiChevronRight style={!inputMessageHidden && {transform:'rotate(90deg'}}/>
                    <h1>Question</h1>
                  </button>
                {!inputMessageHidden && (
                  <pre className={style['prompt']}>{inputMessage.message}</pre>
                )}
                </div>
              </div>
              <div className={`d-flex gap-3 ${inputMessageHidden && rulesHidden ? 'flex-row' : 'flex-column'}`}>
                    <InfoCardSmall label="Profil(s)" value={inputMessage.consultantCount} iconComponent={<FiUsers className="header-4 text-white"/>}/>
                    <InfoCardSmall label="Token(s)" value={inputMessage.tokenCount} iconComponent={<FiHexagon className="header-4 text-white"/>}/>
                    <InfoCardSmall label="Coût (input)" value={inputMessage.dollarPrice} iconComponent={<FiDollarSign className="header-4 text-white"/>}/>
              </div>
            </div>
          </div>
          { consultants.length === 0 ? (
            <div className={`pb-3`}>
              <button className="default-btn" style={{fontWeight:600, fontSize:16}} onClick={executePrompt}>Exécuter</button>
            </div>
          ) : (
          <div className={`d-flex flex-column ${style['block-a']} align-items-start gap-3`}>
            <title>Résultat</title>
            <div className='d-flex flex-row gap-4' style={{width: '-webkit-fill-available'}}>
              <div className="d-flex flex-column" style={{width: '-webkit-fill-available'}}>
                  <div>
                    <button className={`d-flex flex-row ${style['toggle']}`} onClick={() => {setOutputMessageHidden(!outputMessageHidden)}}>
                      <FiChevronRight style={!outputMessageHidden && {transform:'rotate(90deg'}}/>
                      <h1>Réponse</h1>
                    </button>
                    {!outputMessageHidden && (
                      <pre className={style['prompt']}>{outputMessage.message}</pre>
                    )}
                  </div>
                  <div>
                    <h1 className="d-flex flex-row"><span style={{display: 'block', height: '16px', width: '16px', textAlign: 'center'}}>-</span>Consultants</h1>
                    <div className="d-flex flex-column gap-3" style={{width:'100%'}}>{consultants.map((clt, i) => <ConsultantRowCard consultant={{...clt, isFromRcrm: !isUuid(clt.id)}} key={i}/>)}</div>
                  </div>
                </div>
                <div className='d-flex gap-3 flex-column'>
                      <InfoCardSmall label="Profil(s)" value={outputMessage.consultantCount} iconComponent={<FiUsers className="header-4 text-white"/>}/>
                      <InfoCardSmall label="Token(s)" value={outputMessage.tokenCount} iconComponent={<FiHexagon className="header-4 text-white"/>}/>
                      <InfoCardSmall label="Coût (output)" value={outputMessage.dollarPrice} iconComponent={<FiDollarSign className="header-4 text-white"/>}/>
                </div>
            </div>
          </div>)}
        </div>
        )}
      </div>
      )}
    </div>
  );
};
