import { ProtectedApi } from "../Helpers/api";

export const getDashboardData = () => {
  return ProtectedApi.get("/dashboard/admin");
};

export const getDashboardEndOfMonth = (month, year, actualManager) => {
  return ProtectedApi.get("/dashboard/admin/end-of-month", {
    params: { month, year, managerId: actualManager },
  });
};

export const patchConsultantState = (id, state) => {
  return ProtectedApi.patch(`/dashboard/admin/${id}/consultant-state`, state);
};

export const fetchJobAlerts = () => {
  return ProtectedApi.get("/dashboard/admin/alerts/job");
};

export const fetchReadAlerts = () => {
  return ProtectedApi.get("/dashboard/admin/alerts/read");
};

export const markAlertAsRead = (alertId) => {
  return ProtectedApi.patch(
    "/dashboard/admin/alerts/markAsRead",
    {},
    { params: { alertId } }
  );
};

export const findAllFinisherByQuery = (month, year) => {
  return ProtectedApi.get(`/dashboard/admin/consultant-status/finishers`, {
    params: {
      month,
      year,
    },
  });
};

export const findAllStarterByQuery = (month, year) => {
  return ProtectedApi.get(`/dashboard/admin/consultant-status/starters`, {
    params: {
      month,
      year,
    },
  });
};

export const getAllFilesAnalysis = () => {
  return ProtectedApi.get(`/dashboard/admin/legalFiles/analysis`);
};

export const notifyIssuedDocuments = (consultantId) => {
  return ProtectedApi.get(`/dashboard/admin/issued-documents`, {
    params: { consultantId },
  });
};

export const getRoles = () => {
  return ProtectedApi.get(`/dashboard/admin/roles`);
};

export const patchAdminRole = ({ userId, roleId }) => {
  return ProtectedApi.patch(`/dashboard/admin/roles/update`, {
    userId,
    roleId,
  });
};

export const patchSocietyVisibility = ({ clientId, roles }) => {
  return ProtectedApi.patch(
    `/dashboard/admin/settings/clients/${clientId}`,
    roles
  );
};
