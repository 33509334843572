import React, { useRef, useState } from "react";
import style from "./style.module.css";
import Popup from "./../Popup/index";
import { useFormik } from "formik";
import { addContract, UploadProfileFiles } from "../../Services/Consultant";
import { useRouteMatch } from "react-router";

const AddContract = ({ show, onClose, mission, setContracts }) => {
  const match = useRouteMatch();
  const uploadContract = useRef(null);
  const [contract, setContract] = useState(null);
  const formik = useFormik({
    initialValues: {
      idMission: mission ? mission.id : "",
      expirationDate: "",
      dailyRateAverage: null,
      type: "CONTRACT",
      signatureDate: "",
      startDate: "",
    },
    onSubmit: (values) => {
      if (contract) {
        var formToSend = {
          consultant: {
            id: match.params.id,
          },
          mission: { id: values.idMission },
          ...values,
        };
        addContract(formToSend).then((savedContract) => {
          const contractId = savedContract.data.id;
          const missionId = values.idMission;
          const consultantId = match.params.id;
          const formdata = new FormData();
          formdata.append("file", contract);
          UploadProfileFiles(formdata, "contract", {
            contractId,
            missionId,
            consultantId,
          }).then((savedDocument) => {
            setContracts((oldState) => {
              const contractToPush = {
                ...savedContract.data,
                file: savedDocument.data,
              };
              return [...oldState, contractToPush];
            });
            onClose();
          });
        });
      }
    },
  });
  return (
    <Popup show={show} onClose={onClose}>
      <form
        className="row p-4 py-5 gap-4 justify-content-center overflow-auto"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-12 d-flex flex-column gap-1">
          <div className="small-text bold">Mission sélectionnée</div>
          <input
            name="idMission"
            id="idMission"
            disabled={true}
            className="default-input"
            required
            value={mission.label}
          />
        </div>
        <div className="col-12 d-flex flex-column gap-1">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="signatureDate"
          >
            Date de signature
          </label>
          <input
            id="signatureDate"
            name="signatureDate"
            value={formik.values.signatureDate}
            onChange={formik.handleChange}
            className="default-input"
            type="date"
            required
          />
        </div>{" "}
        <div className="col-12 d-flex flex-column gap-1">
          <label className="small-text bold cursor-pointer" htmlFor="startDate">
            Date de début
          </label>
          <input
            id="startDate"
            name="startDate"
            value={formik.values.startDate}
            onChange={formik.handleChange}
            className="default-input"
            type="date"
            required
          />
        </div>
        <div className="col-12 d-flex flex-column gap-1">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="startingDate"
          >
            Date Fin
          </label>
          <input
            id="expirationDate"
            name="expirationDate"
            value={formik.values.expirationDate}
            onChange={formik.handleChange}
            className="default-input"
            type="date"
            required
          />
        </div>
        <div className="col-12 d-flex flex-column gap-1">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="startingDate"
          >
            TJM
          </label>
          <input
            id="dailyRateAverage"
            name="dailyRateAverage"
            value={formik.values.dailyRateAverage}
            onChange={formik.handleChange}
            className="default-input"
            type="number"
            required
          />
        </div>
        <div className="col-12 d-flex flex-column gap-1">
          <div className="small-text bold">Type de document</div>
          <select
            name="type"
            id="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            className="default-input"
            required
          >
            <option value="CONTRACT">Contrat</option>
            <option value="AVENANT">Avenant</option>
          </select>
        </div>
        <div className="col-12 d-flex flex-column gap-1">
          <div className="small-text bold">Votre Contrat</div>
          <div className="w-100 d-flex flex-column gap-1">
            <div
              className={
                style["contract-container"] +
                " w-100 d-flex flex-row justify-content-between align-items-center gap-3"
              }
            >
              <div className="small-text text-truncate">
                {contract ? contract.name : "Aucun Contract"}
              </div>
              <input
                type="file"
                name="contract"
                id="contract"
                ref={uploadContract}
                hidden
                onChange={(e) => {
                  if (e.target.files[0]) setContract(e.target.files[0]);
                }}
                accept="image/*, application/pdf, .xlsx, .xls, .csv"
              />
              <div>
                <button
                  type="button"
                  className="default-btn blue-btn"
                  onClick={(e) => uploadContract.current.click()}
                >
                  Télécharger un Contrat
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <button type="submit" className="default-btn">
            Ajouter le contrat
          </button>
        </div>
      </form>
    </Popup>
  );
};

export default AddContract;
