 import React, { useState } from "react";
 import style from "./style.module.css";
 import { FiChevronDown, FiChevronUp } from "react-icons/fi";
 
 const SortButton = ({ label, callback }) => {
    const [isSortDesc, setIsSortDesc] = useState(true);
    
    const handleClick = () => {
        callback(isSortDesc)
        setIsSortDesc(!isSortDesc)
    };
 
    return (
        <div className={style['sort-button-container']} onClick={handleClick}>
            <span>{label}</span>
            {isSortDesc ? (
            <FiChevronDown/>
            ) : 
            <FiChevronUp/>}
        </div>

   );
 };
 
 export default SortButton;
              