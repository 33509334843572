import React from "react";
import ListItemContractCadre from "./Listtem";
import style from "./style.module.css";

const ContratCadreTable = ({ contracts, onDelete, openPopup }) => {
  return (
    <div className={`${style["table"]}`}>
      <div className={`${style["table-item"]} ${style["table-header"]}`}>
        <div className="small-text">NOM DE DOCUMENT</div>
        <div className="small-text">DOCUMENT</div>
        <div className="small-text">DATE DE SIGNATURE</div>
        <div className="small-text">DATE DEBUT</div>
        <div className="small-text">DATE FIN</div>
        <div className="small-text">ACTIONS</div>
      </div>
      {contracts.length > 0 ? (
        contracts.map((c, i) => {
          return (
            <ListItemContractCadre
              key={i}
              contract={c}
              onDelete={onDelete}
              openPopup={openPopup}
            />
          );
        })
      ) : (
        <div className="w-100 p-5 small-text text-center">
          Aucun contrat(s) cadre
        </div>
      )}
    </div>
  );
};

export default ContratCadreTable;
