import React, { useState } from "react";

export const MultiSelect = ({
  options,
  placeholder,
  selectedOptionsInit = [],
  onSelectFn,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsInit);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const selectedOptionsKeys = selectedOptions.map((sOpt) => sOpt.optKey);
    if (selectedOptionsKeys.includes(option.optKey)) {
      const filteredOptions = selectedOptions.filter(
        (item) => item.optKey !== option.optKey
      );
      setSelectedOptions(filteredOptions);
      onSelectFn(filteredOptions);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      onSelectFn([...selectedOptions, option]);
    }
  };

  return (
    <div className="multi-select">
      <div
        className={`select-header ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((sOpt) => sOpt.optValue).join(", ")
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option) => (
            <div
              key={option.optKey}
              className={`option ${
                selectedOptions.includes(option) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.optValue}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
