import { ProtectedApi } from "../Helpers/api";
import { ADMIN } from "./User";

export const getConsultants = (page, search, active, states, businessUnit) => {
  const statesParams = states ? states.join(",") : undefined;
  return ProtectedApi.get(`${ADMIN}/consultant`, {
    params: {
      p: page,
      search,
      active,
      states: statesParams,
      businessUnit: businessUnit !== "ALL" ? businessUnit : undefined,
    },
  });
};

export const getConsultantsCount = (groupByField) => {
  return ProtectedApi.get(`${ADMIN}/consultant/count/all`, {
    params: {
      groupByField,
    },
  });
};

export const getConsultantsBySkillsAndSearchFilter = (
  requiredSkillIds,
  optionalSkillIds,
  page,
  search,
  states,
  businessUnit
) => {
  return ProtectedApi.post(`${ADMIN}/consultant/bySkillsAndSearchFilter`, {
    requiredSkillIds,
    optionalSkillIds,
    page,
    search,
    states,
    businessUnit: businessUnit !== "ALL" ? businessUnit : undefined,
  });
};

export const getConsultantsBySocietyId = (societyId) =>
  ProtectedApi.get(
    `${ADMIN}/consultant/mission/society?societyId=` + societyId
  );
