import { SettingsClient } from "../../../Components/Settings/Clients";
import { Card } from "../../../UI/Card";

import styles from "./style.module.css";

export const SettingsClientsPage = () => (
  <Card classes={`w-75 m-auto my-4 ${styles["container"]}`}>
    <SettingsClient />
  </Card>
);
