import React from 'react'
import { Card } from '../../UI/Card';

import IdentiteClient from "./../../Pages/Client/Identité";
import styles from './style.module.css';

const ClientLayout = ({ children }) => {
    return <Card classes={`p-5 ${styles['container']}`}>
        <IdentiteClient />
        {children}
    </Card>
}

export default ClientLayout