import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";

import ConsultantNavigation from "../../Components/ConsultantNavigation";
import { userActions } from "../../Redux/Admin/actions";
import { getConsultant } from "../../Services/Consultant";

export const ConsultantLayout = ({ children }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    getConsultant(match.params.id).then((res) => {
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT,
        payload: { consultant: res.data },
      });
    });
  }, [match]);

  return (
    <div className="container py-4 px-5">
      <ConsultantNavigation />
      {children}
    </div>
  );
};

export default ConsultantLayout;
