import { useRef } from "react";
import style from "./style.module.css";

export const UploadFileInput = ({
  file,
  unknownFile: unknownFileMessage,
  onChangeFile,
  buttonTitle,
  uploadTitle
}) => {
  const fileRef = useRef(null);

  // (e) => {
  //   if (e.target.files[0]) setContract(e.target.files[0]);
  // };

  return (
    <div className="col-12 d-flex flex-column gap-3">
      <div className="small-text bold">{uploadTitle}</div>
      <div className="w-100 d-flex flex-column gap-2">
        <div
          className={
            style["container"] +
            " w-100 d-flex flex-row justify-content-between align-items-center gap-3"
          }
        >
          <div className="small-text text-truncate">
            {file ? file.name : `${unknownFileMessage}`}
          </div>
          <input
            type="file"
            name="file"
            id="file"
            ref={fileRef}
            hidden
            onChange={onChangeFile}
            accept="image/*, application/pdf, .xlsx, .xls, .csv"
          />
          <div>
            <button
              type="button"
              className="default-btn blue-btn"
              onClick={(e) => fileRef.current.click()}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
