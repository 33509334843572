import React, { useState, useRef, useCallback } from "react";
import style from "./style.module.css";
import { FiMapPin, FiPhone, FiMail, FiSettings } from "react-icons/fi";
import DropDownMenu from "../../Menu/DropDownMenu";
import { getUrlAdminConsultant } from "../../../config";

const ConsultantCardV2 = ({ consultant, contentSlot, headerSlot, menuOptions }) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const fiSettingsRef = useRef();

  const openConsultantIdentite = useCallback(async () => {
    if (consultant?.id) {
      const baseUrl = await getUrlAdminConsultant();
      const url = `${baseUrl}${consultant.id}`;
      window.open(url, "_blank");
    }
  }, [consultant]);
  
  const handleSettingsClick = (e) => {
    e.stopPropagation(); 
    setIsDropDownVisible(!isDropDownVisible);
  };

  return (
    <div className={style['block-a']} onClick={() => {
      openConsultantIdentite()
      }}>
      <div className={`d-flex flex-column gap-3`}>
        <div className={'d-flex'}  style={{justifyContent:'space-between'}}>
          <div className={style['title']}>{`${consultant.user.firstName} ${consultant.user.lastName}`}</div>
          <div className={`d-flex flex-row gap-1 align-items-center`}> 
            {headerSlot && (headerSlot)}
            <div style={{paddingLeft:'8px'}} ref={fiSettingsRef} onClick={(e) => {handleSettingsClick(e)}}>
              <FiSettings
                className={style["rotate-icon"]}/>
            </div>
            { isDropDownVisible && (
              <DropDownMenu 
                elementRef={fiSettingsRef}
                menuOptions={menuOptions}
                closeCallback={() => (setIsDropDownVisible(false))}
              />
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className={`gap-2 ${style['grid-container']}`}>
            <div className={style['a']}><FiMail/> {consultant.user.email}</div>
            <div className={style['b']}><span style={{width:'16px',height:'16px'}}>€</span> {consultant?.dailyRateAverage}</div>
            <div className={style['c']}><FiMapPin/> {consultant.user?.address?.city}</div>
            <div className={style['d']}><FiPhone/> {consultant.user?.phoneNumber}</div>
          </div>
          {contentSlot && (contentSlot)}
        </div>
        <div className="d-flex flex-wrap gap-2">
          {consultant?.consultantSkillMarks?.map((csm, i) => {
            return (
              <div style={{backgroundColor:'var(--primary-blue)',color:'white', borderRadius:'5px', padding:'6px', fontWeight:500}} key={i}>
                {csm.skill.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConsultantCardV2;
