import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import AppLayout from "./AppLayout/index";

const Layout = ({ children }) => {
  const history = useHistory();
  const isUserConnected = useSelector((state) => state.userReducer.isLoggedIn);

  useEffect(() => {
    if (!isUserConnected) {
      history.push("/auth");
    }
  }, [isUserConnected]);
  return !isUserConnected ? <>{children}</> : <AppLayout>{children}</AppLayout>;
};

export default withRouter(Layout);
