
import { FiCalendar, FiMail, FiPhone } from 'react-icons/fi';
import { Card } from '../../../UI/Card';
import styles from './style.module.css';
import { getColorCode } from '../../../Helpers/styles/get-color-by-bu'
import { getUrlAdminConsultant } from '../../../config';
import { useCallback } from 'react';

const ConsultantCardPreview = ({ consultant, state, stateColor }) => {
    //const consultantAvatar = consultant.profilePicture && <ProfileLogo profilePicture={consultant.profilePicture} />

    const routeToConsultantProfile = useCallback(async () => {
        if (consultant?.id) {const baseUrl = await getUrlAdminConsultant();
            const url = `${baseUrl}${consultant.id}`;
            window.open(url, "_blank");
        }
    }, [consultant]);

    const shouldDisplaySkillCount =
    consultant?.skillCount !== undefined && consultant.skillCount > 0;

    return (<Card classes={`${styles['container']}`}>
        <div className='d-flex flex-row justify-content-between' style={{height: '-webkit-fill-available'}} onClick={routeToConsultantProfile}>
            <div className='d-flex flex-column justify-content-between gap-3'>
                {/* <header>{consultantAvatar}</header> */}
                <div className='d-flex flex-column gap-2'>
                    <div className='d-flex green-text bold' style={{fontSize: 20, fontWeight: 600}}>
                        <span>{consultant.user.fullName}</span>
                    </div>
                    <div className='d-flex flex-column gap-2' style={{overflowWrap: 'anywhere'}}>
                        <div><FiMail /> {consultant.user.email}</div>
                        <div><FiPhone /> {consultant.user.phoneNumber}</div>
                        <div><FiCalendar /> Créé le {new Date(consultant.createdAt).toLocaleDateString()}</div>
                    </div>
                </div>
                <div style={{paddingTop:'auto', color:'white', padding: 8, width: 'fit-content', background: stateColor, borderRadius: 8}}>{state}</div>
            </div>
            <div className='d-flex flex-column gap-2 align-items-center'>
            {shouldDisplaySkillCount && (
                <div  className='d-flex align-items-center justify-content-center rounded-circle'
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        width: 28,
                        height: 28,
                        lineHeight: '30px',
                        fontSize: '14px',
                }}>{consultant.skillCount}</div>)}
            {consultant?.job?.jobDepartment?.businessUnit?.length && consultant.job.jobDepartment.businessUnit.map((bu, index) => {
                    return (
                    <div key={index} style={{background: getColorCode(bu), borderRadius: '50%', height: 24, width: 24, textAlign: 'center', fontWeight: 600}}>
                        {bu.charAt(3)}
                    </div>
                    )}
            )}
            </div>
        </div>
    </Card>)
}

export default ConsultantCardPreview; 