import { userActions } from "./actions";

const initialState = {
  isLoggedIn: !!sessionStorage.getItem("USER_ID"),
  userId: sessionStorage.getItem("USER_ID"),
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        userId: action.payload.user.id,
      };
    case userActions.LOGOUT_USER:
      return { ...state, isLoggedIn: false };
    case userActions.SET_USER:
      return { ...state, user: action.payload.user };
    case userActions.SET_CURRENT_CONSULTANT:
      return { ...state, consultant: action.payload.consultant };
    case userActions.SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS:
      return {
        ...state,
        consultant: {
          ...state.consultant,
          documents: [
            ...state.consultant.documents?.filter(
              (docs) => docs.type !== "id_card"
            ),
            {
              type: "id_card",
              documents: [...action.payload],
            },
          ],
        },
      };
    case userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS:
      return {
        ...state,
        consultant: {
          ...state.consultant,
          documents: [
            ...state.consultant.documents?.filter(
              (docs) => docs.type !== "legal_files"
            ),
            {
              type: "legal_files",
              documents: [...action.payload],
            },
          ],
        },
      };
    default:
      return { ...state };
  }
};

export default userReducer;
