import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import style from "./style.module.css";

const SearchBar = ({
  onSubmit,
  onClick,
  onChange,
  onBlur,
  searchValue,
  placeholder,
}) => {
  const [selfSearchValue, setSelfSearchValue] = useState(searchValue ?? "");

  const onChangeSearchHandler = (e) => {
    setSelfSearchValue(e.target.value);
  };

  return (
    <div
      className={`${style["search-bar"]} d-flex flex-row gap-2`}
      style={{ width: "100%" }}
      onBlur={onBlur}
      onClick={onClick}
    >
      <div className="d-flex flex-row" style={{ width: "100%" }}>
        <FiSearch className={`${style["search-icon"]}`} />
        <input
          className={
            !onChange ? `default-input ${style["split"]}` : "default-input"
          }
          value={searchValue}
          onChange={onChange ?? onChangeSearchHandler}
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSubmit(searchValue ?? selfSearchValue);
            }
          }}
        />
      </div>
      {!onChange && (
        <div>
          <button
            className="default-btn"
            style={{ height: "54px" }}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(searchValue ?? selfSearchValue);
            }}
          >
            Rechercher
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
