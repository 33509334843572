import React from "react";
import {
  FiLinkedin, FiMail, FiPhone
} from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router";
import { Card } from "../../UI/Card";
import style from "./style.module.css";

const ManagerCard = ({ manager }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const routeToManagerDetails = () => {
    const url = `/client/${match.params.id}/carnet-adresses/${manager.id}/modifier`;
    history.push(url);
  }
  return (
    <Card
      classes={`${style["manager-card"]} border-light-grey-small`} onClick={routeToManagerDetails}
    >
      <div className="w-100 d-flex">
        <div className={`wow-subtitle bold ${style['presentation']}`}>
          <p className="green-text">{manager.fullName}</p>
          <p>{manager.job}</p>
          <p>{manager.department}</p>
        </div>
        <div className=" small-text">
          <p>
            <FiMail /> {manager.email}
          </p>
          <p>
            <FiPhone /> {manager.phoneNumber}
          </p>
          <a
            href={manager.linkedInURI}
            className="w-100 text-decoration-underline text-truncate"
            style={{ maxWidth: "300px" }}
          >
            <FiLinkedin /> {manager.linkedInUri}
          </a>
        </div>
      </div>
    </Card>)
};

export default ManagerCard;
