
import styles from './style.module.css';

const TableHeader = ({ header }) => {
    const headersLi = header.map((h, index) => <div className="w-100 text-center" key={index}><span>{h}</span></div>)
    return <div className={`${styles['header']}`}>{headersLi}</div>;
}

const DataTable = ({ header, children }) => {
    return (<div className={styles['table']}>
        <TableHeader header={header} />
        <div className={styles['table-content']}>
            {children ?? <p className='text-center m-3'>Pas de résultat</p>}
        </div>
    </div>);
}

export default DataTable;
