import { useState } from "react";
import toast from "../../../Helpers/toast";
import { updateContract } from "../../../Services/Consultant";

const PopupContrat = ({
  setOpen,
  setContractsHandler,
  currentContratToUpdate,
}) => {
  const [signatureDate, setSignatureDate] = useState(
    currentContratToUpdate && currentContratToUpdate.signatureDate
      ? currentContratToUpdate.signatureDate.split("T")[0]
      : ""
  );

  const [startDate, setStartDate] = useState(
    currentContratToUpdate && currentContratToUpdate.startDate
      ? currentContratToUpdate?.startDate.split("T")[0]
      : ""
  );
  const [endDate, setEndDate] = useState(
    currentContratToUpdate && currentContratToUpdate.expirationDate
      ? currentContratToUpdate?.expirationDate.split("T")[0]
      : ""
  );

  const [dailyRateAverage, setDailyRateAverage] = useState(
    currentContratToUpdate?.dailyRateAverage
  );

  const onChangeInputHandler = (index, e) => {
    if (index === 1) {
      setSignatureDate(e.target.value);
    } else if (index === 2) {
      setStartDate(e.target.value);
    } else if (index === 3) {
      setEndDate(e.target.value);
    } else if (index === 4) {
      setDailyRateAverage(e.target.value);
    }
  };

  const submitFormUpdateContract = (e) => {
    e.preventDefault();

    const contractToSend = {
      expirationDate: endDate,
      signatureDate,
      startDate,
      type: currentContratToUpdate.type,
      initialExpirationDate: currentContratToUpdate.initialExpirationDate,
      consultant: currentContratToUpdate.consultant,
      mission: currentContratToUpdate.mission,
      id: currentContratToUpdate.id,
      createdAt: currentContratToUpdate.createdAt,
      status: currentContratToUpdate.status,
      dailyRateAverage,
      updatedAt: new Date(),
    };

    updateContract(contractToSend).then(() => {
      setOpen(false);
      toast("success", "Contrat modifié");
      setContractsHandler((oldState) => {
        const index = oldState.findIndex(
          (c) => c.id === currentContratToUpdate.id
        );
        // contractToSend.file = currentContratToUpdate.file;
        oldState[index] = contractToSend;
        return [...oldState];
      });
    });
  };

  return (
    <form
      className="row justify-content-between p-3 py-4 gap-2"
      onSubmit={submitFormUpdateContract}
    >
      <div className="col-12 wow-subtitle bold">Modifier un contrat</div>

      <div className="col-12 d-flex flex-column gap-1">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de signature du contrat
        </label>
        <input
          id="signatureDate"
          name="signatureDate"
          className="default-input"
          type="date"
          value={signatureDate || ""}
          onChange={(e) => onChangeInputHandler(1, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-1">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de début du contrat
        </label>
        <input
          id="startDate"
          name="startDate"
          className="default-input"
          type="date"
          value={startDate || ""}
          onChange={(e) => onChangeInputHandler(2, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-1">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de fin du contrat
        </label>
        <input
          id="endDate"
          name="endDate"
          className="default-input"
          type="date"
          value={endDate || ""}
          onChange={(e) => onChangeInputHandler(3, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-1">
        <label
          className="small-text bold cursor-pointer"
          htmlFor="startingDate"
        >
          TJM
        </label>
        <input
          id="dailyRateAverage"
          name="dailyRateAverage"
          value={dailyRateAverage}
          onChange={(e) => onChangeInputHandler(4, e)}
          className="default-input"
          type="number"
          required
        />
      </div>
      <div className="col-12 d-flex flex-row justify-content-center gap-4">
        <div>
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setOpen(false)}
          >
            Annuler
          </button>
        </div>
        <div>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </div>
    </form>
  );
};
export default PopupContrat;
