import { useCallback, useEffect, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import CustomCheckbox from '../../../../Components/CustomCheckbox';
import { getDashboardEndOfMonth } from '../../../../Services/Dashboard';
import { getAdminUsers } from '../../../../Services/User';
import DataTable from "../../../../UI/DataTable";
import CheckboxesConfigSummary from './CheckboxesConfigSummary';
import styles from './style.module.css';

import Loader from "./../../../../Components/Loader/index";
import TotalSummary from "./../../components/EndOfMonthDashboard/TotalSummary/index";
import { routeTo, getUrlAdminClient, getUrlAdminConsultant } from '../../../../config';

const CLAIM = 'CLAIM';
const INVOICE = 'INVOICE';

const EndOfMonthDashboard = () => {
    const today = new Date();
    const currentMonth = today.getFullYear() + '-' + (today.getMonth() > 10 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`);
    const [loading, setLoading] = useState(true);
    const [strSearch, setStrSearch] = useState('');

    const [dataSource, setDataSource] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth ?? '');

    const [managers, setManagers] = useState([]);
    const [selectedManagerId, setSelectedManagerId] = useState('');

    const reducerState = useSelector(state => state.checkboxReducers);
    const [shallFilterByClaim, setShallFilterByClaim] = useState(false);

    const split = selectedMonth ? selectedMonth.split('-') : null;
    const month = split && split[0] ? split[1] : null;
    const year = split && split[1] ? split[0] : null;

    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        getDashboardEndOfMonth(month, year, selectedManagerId).then((res) => {
            setDataSource(res.data);
            setLoading(false);
        });
        if (!managers?.length) {
            getAdminUsers().then((res) => setManagers(res.data));
        }

    }, [selectedMonth, selectedManagerId]);

    const onChangeMonthHandler = (e) => {
        setSelectedMonth(e.target.value);
        setStrSearch('');
    }

    const onChangeFinderHandler = (e) => {
        const strSearch = e.target.value.toLowerCase();
        setStrSearch(strSearch);
    }

    const onChangeManagerHandler = (e) => {
        setSelectedManagerId(e.target.value);
    }

    const filterByStrSearch = (el, strSearch) => {
        return el.firstname?.toLowerCase().includes(strSearch)
            || el.lastname?.toLowerCase().includes(strSearch)
            || el.missionlabel?.toLowerCase().includes(strSearch)
            || el.societylabel?.toLowerCase().includes(strSearch)
            || (el.firstname?.toLowerCase() + ' ' + el.lastname?.toLowerCase()).includes(strSearch)
            || (el.lastname?.toLowerCase() + ' ' + el.firstname?.toLowerCase()).includes(strSearch);
    }

    const onChangeClaimHandler = (e) => {
        setShallFilterByClaim((filterByClaimOldState) => !filterByClaimOldState);
    }

    const filterByReducerState = (el) => {
        // Filter everyone in initial state
        let timesheetStatusPending = true;
        let timesheetStatusApproved = true;
        let invoiceStatusPending = true;
        let invoiceStatusApproved = true;

        if (reducerState.timesheetSended) {
            timesheetStatusPending = el.timesheetstatus === 'PENDING_APPROVAL' || el.timesheetstatus === 'ADMIN_APPROVED';
        }
        if (reducerState.timesheetApproved) {
            timesheetStatusApproved = el.timesheetstatus === 'ADMIN_APPROVED';
        }
        if (reducerState.invoiceSended) {
            invoiceStatusPending = el.invoicestatus === 'PENDING' || el.invoicestatus === 'APPROVED';
        }
        if (reducerState.invoiceApproved) {
            invoiceStatusApproved = el.invoicestatus === 'APPROVED';
        }
        return timesheetStatusApproved && timesheetStatusPending && invoiceStatusApproved && invoiceStatusPending;
    }

    const filterInvoiceClaims = (el) => {
        return shallFilterByClaim ? el.invoicetype && el.invoicetype === CLAIM : !el.invoicetype || el.invoicetype === INVOICE;
    }

    const handleConsultantClick = useCallback(async (consultantId) => {
        try {
            const url = await getUrlAdminConsultant();
            routeTo(url, consultantId);
        } catch (error) {
            console.error("Error opening consultant page:", error);
            // Gérer l'erreur ici (par exemple, afficher un message à l'utilisateur)
        }
    }, []);

    const handleMissionClick = useCallback(async (societyId, missionId) => {
        try {
            const url = await getUrlAdminClient();
            window.open(`${url}${societyId}/mission/${missionId}/modifier`, "_blank");
        } catch (error) {
            console.error("Error opening mission page:", error);
            // Gérer l'erreur ici
        }
    }, []);

    const handleSocietyClick = useCallback(async (societyId) => {
        try {
            const url = await getUrlAdminClient();
            routeTo(url, societyId);
        } catch (error) {
            console.error("Error opening society page:", error);
            // Gérer l'erreur ici
        }
    }, []);

    const parseRowValueToCell = (row, index) => {
        const classes = index % 2 === 0 ? `flex d-flex ${styles['row-odd']} ${styles['rows']}` : `flex d-flex ${styles['rows']}`;
        return (
            <div className={classes} key={index}>
                <div className={styles['href']} onClick={() => handleConsultantClick(row.consultantid)}>
                    {row.firstname} {row.lastname} {row.timesheetcomment && <span className={styles['timesheetComment']}>!</span>}
                </div>
                <div className={styles['href']} onClick={() => handleMissionClick(row.societyid, row.missionid)}>
                    {row.missionlabel}
                </div>
                <div className={styles['href']} onClick={() => handleSocietyClick(row.societyid)}>
                    {row.societylabel}
                </div>
                <div>{(!row.duration || row.timesheetstatus === 'REFUSED') ? 'N.C' : row.duration}</div>
                <div>{row.timesheetstatus && (row.timesheetstatus === 'PENDING_APPROVAL' || row.timesheetstatus === 'ADMIN_APPROVED') ? <FiCheck color="green" /> : <FiX color="red" />}</div>
                <div>{row.timesheetstatus && row.timesheetstatus === 'ADMIN_APPROVED' ? <FiCheck color="green" /> : <FiX color="red" />}</div>
                <div>{row.juridicstructure === 'WAGE_PORTING' ? <span>PORTAGE</span> : row.invoicestatus && (row.invoicestatus === 'PENDING' || row.invoicestatus === 'APPROVED') ? <FiCheck color="green" /> : <FiX color="red" />}</div>
                <div>{row.juridicstructure === 'WAGE_PORTING' ? <span>PORTAGE</span> : row.invoicestatus && row.invoicestatus === 'APPROVED' ? <FiCheck color="green" /> : <FiX color="red" />}</div>
            </div>
        );
    };

    let rows = <p className='text-center m-3'>Aucun résultat</p>

    let totalFilteredDataList = [];
    if (dataSource.length > 0) {
        const filteredDataSource = [...dataSource.filter((el) => filterByStrSearch(el, strSearch))];
        const filteredDataSourceByInvoiceType = [...filteredDataSource.filter(filterInvoiceClaims)];
        const filteredDataSourceWithAdditionnalFilters = [...filteredDataSourceByInvoiceType.filter(filterByReducerState)];
        if (filteredDataSourceWithAdditionnalFilters.length > 0) {
            rows = filteredDataSourceWithAdditionnalFilters.map((row, index) => parseRowValueToCell(row, index));
            totalFilteredDataList = filteredDataSourceWithAdditionnalFilters;
        }
    }

    const title = 'Récapitulatif de fin de mois';
    const header = ['CONSULTANT', 'MISSION', 'CLIENT', 'JOURS', 'C.R.A SOUMIS', 'C.R.A VALIDE', 'FACTURE SOUMISE', 'FACTURE VALIDE'];

    return (<div>
        {loading && (
            <div
                className="w-100 position-relative card my-3"
                style={{ height: "300px" }}
            >
                <Loader />
            </div>
        )}

        {!loading && dataSource && 
        <div className='d-flex flex-column gap-2'>
            <h2 className='header-2'>{title}</h2>
            <div className="d-flex flex-row gap-3">
                <div className='d-flex flex-column' style={{width:"100%"}}>
                    <label htmlFor='finder'>Finder :</label>
                    <input id='finder' className="default-input" placeholder='Critères : consultant, mission, société' onChange={onChangeFinderHandler} type="text"></input>
                </div>
                <div className='d-flex flex-column' style={{width:"100%"}}>
                    <label htmlFor='month'>Mois :</label>
                    <input id='month' className="default-input" type="month" value={selectedMonth} onChange={onChangeMonthHandler}></input>
                </div>
                <div className='d-flex flex-column' style={{width:"100%"}}>
                    <label>O.P Manager :</label>
                    <select className="default-input" onChange={onChangeManagerHandler} value={selectedManagerId}>
                        <option></option>
                        {managers.map((m, index) => <option value={m.id} key={index}>{m.fullName}</option>)}
                    </select>
                </div>
            </div>


            <CheckboxesConfigSummary classes={`flex d-flex`} />

            <div className={`flex d-flex`}>
                <span>Filtrer par :</span>
                <CustomCheckbox label='Note de frais' name='Note de frais' onChange={onChangeClaimHandler} value={shallFilterByClaim} readOnly={false}></CustomCheckbox>
            </div>

            <DataTable header={header} title={title}>{rows}</DataTable>
            <TotalSummary isFilteredByClaim={shallFilterByClaim} listOfData={totalFilteredDataList} classes={`flex d-flex ${styles['rows']} ${styles['green-background-light']}`} />
        </div>}
    </div>)
}


export default EndOfMonthDashboard;