import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import CustomCheckbox from "../../../Components/CustomCheckbox";
import Loader from "../../../Components/Loader";
import Skills from "../../../Components/Skills";
import toast from "../../../Helpers/toast";
import { userActions } from "../../../Redux/Admin/actions";
import { findManagerBySearch } from "../../../Services/Manager";
import { getAdminUsers } from "../../../Services/User";
import Switch from "../../../UI/Switch";
import {
  editConsultant,
  editConsultantSkillMarks,
  getConsultant,
} from "./../../../Services/Consultant";
import style from "./style.module.css";

const ModifierConsultant = () => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const history = useHistory();
  const match = useRouteMatch();

  const [manager, setManager] = useState([]);
  const [searchDataManagers, setSearchDataManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [consultant, setConsultant] = useState(null);
  const [allAdmins, setAllAdmins] = useState([]);

  const [isFeaturing, setIsFeaturing] = useState(
    !!currentConsultant?.featuringAgent
  );
  const [featuringAgentId, setFeaturingAgentId] = useState(
    currentConsultant?.featuringAgent?.id ?? ""
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      resaleDailyRateAverage: 0,
      affiliateManager: {
        wowOffice: "",
      },
      society: {
        paymentDelay: null,
      },
      onBoardingBoxSent: false,
      user: {
        email: "",
        phoneNumber: "",
        birthDate: "",
        linkedInUri: "",
        wowOffice: "",
        address: {
          street: "",
          postalCode: null,
          city: "",
        },
      },
      ...consultant,
    },
    onSubmit: async (values) => {
      delete values.user.email;
      const userValues = values.user;
      const payload = {
        //resaleDailyRateAverage: values.resaleDailyRateAverage,
        affiliateManager: manager[0]?.id
          ? {
              id: manager[0] && manager[0].id,
            }
          : null,
        consultantSkillMarks: consultant.consultantSkillMarks.map((csm) => ({
          skill: csm.skill,
        })),
        onBoardingBoxSent: values.onBoardingBoxSent,
        user: userValues,
      };
      if (consultantHasSociety) {
        payload.society = {
          id: currentConsultant.society?.id,
          paymentDelay: values.society?.paymentDelay,
        };
      }

      if (isFeaturing && featuringAgentId) {
        payload.featuringAgent = {
          id: featuringAgentId,
        };
      } else {
        payload.featuringAgent = null;
      }

      await Promise.all([
        editConsultant(match.params.id, {
          ...payload,
          consultantSkillMarks: undefined,
        }),
        editConsultantSkillMarks(match.params.id, {
          id: currentConsultant.id,
          consultantSkillMarks: payload.consultantSkillMarks,
        }),
      ])
        .then((res) => {
          history.push(`/consultant/${match.params.id}`);
          toast("success", "Les modifications ont été prises en compte");
        })
        .catch((e) =>
          toast(
            "error",
            "Il manque des informations sur la société du consultant"
          )
        );
    },
  });

  useEffect(() => {
    if (isFeaturing && allAdmins?.length === 0) {
      getAdminUsers().then((res) => setAllAdmins(res.data));
    }
  }, [isFeaturing, allAdmins]);

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      setConsultant(currentConsultant);
      setManager(
        currentConsultant.affiliateManager
          ? [currentConsultant.affiliateManager]
          : []
      );
      setLoading(false);
      setIsFeaturing(!!currentConsultant?.featuringAgent);
      setFeaturingAgentId(currentConsultant?.featuringAgent?.id);
    } else {
      getConsultant(match.params.id).then((res) => {
        dispatch({
          type: userActions.SET_CURRENT_CONSULTANT,
          payload: { consultant: res.data },
        });
      });
    }
  }, [currentConsultant]);

  const searchManager = (e) => {
    findManagerBySearch(e.target.value).then((res) => {
      setSearchDataManagers(res.data ?? []);
    });
  };
  const onCloseSearchManagers = () => {
    setSearchDataManagers([]);
  };

  const onAddManager = (m) => {
    onCloseSearchManagers();
    if (manager.find((man) => m.id === man.id)) {
      return;
    }
    setManager((oldState) => [...oldState, m]);
  };
  const onDeleteManager = (manager) => {
    setManager((oldState) =>
      oldState.filter(
        (m) => m.email + m.firstName !== manager.email + manager.firstName
      )
    );
  };

  const updateConsultantSkills = (skills) => {
    //TODO transformation skill to skillmarks
    const consultantClone = { ...consultant };
    consultantClone.consultantSkillMarks = skills.map((s) => ({ skill: s }));
    setConsultant(consultantClone);
  };

  const consultantHasSociety = consultant && !!consultant.society;
  const paymentStr = "Délai de paiement (en jours)";
  const cantPay =
    "Afin de modifier le délai de paiment le consultant doit avoir renseigné les informations de sa société.";
  const paymentDelayDiv = (
    <div>
      {paymentStr}
      {!consultantHasSociety && <p>{cantPay}</p>}
    </div>
  );

  const onChangeFeaturingHandler = (isChecked) => {
    setIsFeaturing(isChecked);
  };

  const onChangeFeaturingAgentHandler = (event) => {
    setFeaturingAgentId(event.target.value);
  };

  return (
    <form className="container py-4 px-5" onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className="row">
          <div
            className="col-12 card position-relative"
            style={{ height: "300px" }}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div className="row px-4 mb-4">
            <div className="col-12 d-flex flex-row justify-content-between">
              <div className="header-2 font-family-tt">
                Modifier un consultant
              </div>
              <div>
                <button type="submit" className="default-btn">
                  Sauvegarder les modifications
                </button>
              </div>
            </div>
          </div>
          <div className="row card px-4 py-4 gap-4">
            <div className="col-12 wow-subtitle bold">Informations WOW</div>
            {/*
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="resaleDailyRateAverage"
              >
                TJM revente (euro)
              </label>
              <input
                id="resaleDailyRateAverage"
                name="resaleDailyRateAverage"
                value={formik.values.resaleDailyRateAverage}
                onChange={formik.handleChange}
                className="default-input"
                type="number"
              />
            </div>
	  		*/}
            <div className="col-12 d-flex flex-row gap-4">
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="wowTeamMembers"
                >
                  Wow team member
                </label>
                <div className="w-100 position-relative">
                  <input
                    type="text"
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        searchManager(e);
                      } else {
                        onCloseSearchManagers();
                      }
                    }}
                    className="default-input"
                    placeholder="Rechercher un membre de l'équipe"
                  />
                  {searchDataManagers?.length > 0 && (
                    <div className={style["search-content"]}>
                      {searchDataManagers.map((d, i) => (
                        <div
                          className={style.hoverManager}
                          key={i}
                          onClick={(e) => onAddManager(d)}
                        >
                          {d.firstName} {d.lastName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="user.wowOffice"
                >
                  Wow bureau
                </label>
                <input
                  id="user.wowOffice"
                  name="user.wowOffice"
                  value={formik.values.user?.wowOffice}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row gap-3 flex-wrap">
              <div className="w-100 d-flex flex-row flex-wrap gap-3">
                {manager.map((s, i) => (
                  <span key={i} className={style["manager"]}>
                    {s.firstName} {s.lastName}
                    <FiX
                      className={style["delete-manager"]}
                      onClick={(event) => onDeleteManager(s)}
                    />
                  </span>
                ))}
              </div>
            </div>
            <div className="w-50">
              <div className="d-flex gap-5 align-items-center">
                <label>Featuring</label>
                <Switch
                  onChangeHandler={onChangeFeaturingHandler}
                  initState={isFeaturing}
                />
              </div>
              {isFeaturing ? (
                <div>
                  <label>Agent de talent :</label>
                  <select
                    className="default-input"
                    onChange={onChangeFeaturingAgentHandler}
                    value={featuringAgentId}
                  >
                    <option></option>
                    {allAdmins.map((m, index) => (
                      <option value={m.id} key={index}>
                        {m.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="society.paymentDelay"
              >
                {paymentDelayDiv}
              </label>
              <input
                disabled={!consultantHasSociety}
                id="society.paymentDelay"
                name="society.paymentDelay"
                value={formik.values.society?.paymentDelay}
                onChange={formik.handleChange}
                className="default-input"
                type="number"
              />
            </div>
            <CustomCheckbox
              name="onBoardingBoxSent"
              label="Box onboarding envoyée"
              value={formik.values.onBoardingBoxSent}
              onChange={formik.handleChange}
            />

            <Skills
              placeholder="Rechercher par mot-clef (ex: Node)"
              onChangeSkillsHandler={updateConsultantSkills}
              consultantSkills={
                consultant
                  ? consultant.consultantSkillMarks.map((csm) => csm.skill)
                  : []
              }
            ></Skills>
            <div className="col-12 wow-subtitle bold">
              Informations générales
            </div>
            <div className="col-12 d-flex flex-row gap-4">
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="user.email"
                >
                  Email
                </label>
                <input
                  id="user.email"
                  name="email"
                  value={formik.values.user.email}
                  onChange={formik.handleChange}
                  disabled
                  className="default-input"
                  type="text"
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="user.phoneNumber"
                >
                  Numero téléphone
                </label>
                <input
                  id="user.phoneNumber"
                  name="user.phoneNumber"
                  value={formik.values.user.phoneNumber}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="user.address"
              >
                Adresse personnelle
              </label>
              <div className="w-100 d-flex flex-column flex-md-row gap-2">
                <input
                  id="user.address.street"
                  name="user.address.street"
                  value={formik.values.user?.address?.street}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Adresse"
                />
                <input
                  id="user.address.postalCode"
                  name="user.address.postalCode"
                  value={formik.values.user?.address?.postalCode}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  placeholder="Code Postal"
                />
                <input
                  id="user.address.city"
                  name="user.address.city"
                  value={formik.values.user?.address?.city}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Ville"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="user.birthDate"
              >
                Date de naissance
              </label>
              <input
                id="user.birthDate"
                name="user.birthDate"
                value={formik.values.user.birthDate?.split("T")[0]}
                onChange={formik.handleChange}
                className="default-input"
                type="date"
              />
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="user.linkedInUri"
              >
                Url LinkedIn
              </label>
              <input
                id="user.linkedInUri"
                name="user.linkedInUri"
                value={formik.values.user.linkedInUri}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div>
                <button type="submit" className="default-btn">
                  Sauvegarder
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default ModifierConsultant;
