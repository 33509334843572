import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminTokenForControlPanel } from "../../../Services/Auth";
import { getBaseUrlConsultant } from "../../../config";

export const WatchMode = () => {
  const [magicLink, setMagicLink] = useState("");
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const watchMode = useCallback(async () => {
    try {
      const [adminToken, baseUrl] = await Promise.all([
        getAdminTokenForControlPanel(),
        getBaseUrlConsultant()
      ]);
      
      const bearer = adminToken.data;
      const url = `${baseUrl}/auth/sign-in?consultantId=${currentConsultant.id}&bearer=${bearer}`;
      setMagicLink(url);
    } catch (error) {
      console.error("Error in watchMode:", error);
      // Gérer l'erreur ici (par exemple, afficher un message à l'utilisateur)
    }
  }, [currentConsultant]);

  const goToMagicLink = () => {
    const w = window.open(magicLink);
    w.localStorage.clear();
    w.sessionStorage.clear();
  };

  const watch = (
    <div className="flex d-flex gap-3">
      <div>
        <button
          type="button"
          className="default-btn blue-btn"
          onClick={watchMode}
        >
          Watch mode
        </button>
      </div>
      <div className="flex d-flex w-100">
        <input
          className="default-input"
          value={magicLink}
          disabled={true}
          readOnly={true}
        />
        {magicLink && (
          <button
            type="button"
            className="default-btn"
            style={{ maxWidth: "6em", marginLeft: "1em" }}
            onClick={goToMagicLink}
          >
            <span>Go to!</span>
          </button>
        )}
      </div>
    </div>
  );

  const target = currentConsultant ? watch : <span>Loading ...</span>;
  return <div>{target}</div>;
};
