import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClientCard from "../../../Components/ClientCard";
import Loader from "./../../../Components/Loader/index";
import { getClients } from "./../../../Services/Comptes";
import SearchBar from "../../../Components/SearchBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Comptes = () => {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const [filteredClients, setFilteredClients] = useState();

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    setLoading(true);
    getClients()
      .then((res) => {
        setClients([...res.data]);
        setFilteredClients([...res.data]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredClients = clients.filter((clt) =>
      clt.label.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredClients(filteredClients);
  }, [search]);

  return (
    <div className="container py-4">
      <div className="row d-flex flex-columns gap-4 px-4 mb-4">
        <div className="header-2">Tous les clients</div>
        <div className="d-flex flex-row gap-2 justify-content-between">
          <SearchBar
            onChange={handleSearchChange}
            placeholder="Recherche par nom du client"
            searchValue={search}
          />
          <button
          style={{height:'54px', width:'auto'}}
          onClick={() => {
            history.push('/clients/ajouter-client');
          }} className="default-btn">Ajouter</button>
        </div>
      </div>
        {loading ? (
          <div
            className="w-100 position-relative card"
            style={{ height: "300px" }}
          >
            <Loader />
          </div>
        ) : clients.length ? (
          <div
            style={{ maxHeight: "60vh" }}
            className="d-flex flex-wrap gap-3 overflow-auto p-2"
          >
            {filteredClients.map((clt, i) => {
              return <ClientCard client={clt} key={i} />;
            })}
          </div>
        ) : (
          <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
            Aucun client
          </div>
        )}
    </div>
  );
};

export default Comptes;
