import { ProtectedApi } from "./../Helpers/api";
import { ADMIN } from "./User";

export const getAddManager = () => {
  return ProtectedApi.get(`${ADMIN}/manager/all/types`);
};

export const addManager = (payload, id) => {
  return ProtectedApi.post(`${ADMIN}/manager`, payload, { params: { id } });
};

export const editManager = (payload) => {
  return ProtectedApi.patch(`${ADMIN}/manager`, payload);
};

export const getManagers = ({id, search}) => {
  return ProtectedApi.get(`${ADMIN}/manager/society/${id}`,{
    params: {
      search
    }
  });
};

export const getManager = (id) => {
  return ProtectedApi.get(`${ADMIN}/manager/${id}`);
};

export const findManagerBySearch = (search) => {
  return ProtectedApi.get(`${ADMIN}/users/managers/search`, {
    params: { search },
  });
};
