import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "../../Helpers/toast";
import style from "./style.module.css";
import { getAllJobs, addJob, editJob, deleteJob } from "./../../Services/Jobs";
import { getAllJobDepartments } from "./../../Services/JobDepartments";
import { levenshteinDistance } from "../../Helpers/string"
import { FiX, FiEdit } from "react-icons/fi";
import Popup from "./../../Components/Popup";
import Loader from "./../../Components/Loader/index";
import { EBusinessUnit } from '../../Helpers/enums/business-unit.enum'
import { getColorCode } from '../../Helpers/styles/get-color-by-bu'
import SearchBar from "../../Components/SearchBar";

const Jobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobDepartments, setJobDepartments] = useState([]);
  const [closeJobs, setCloseJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedJob, setSelectedJob] = useState({ label: "" });
  const [selectedJobDepartment, setSelectedJobDepartment] = useState({ label: "", businessUnit: EBusinessUnit.BU_TECH });
  const [existingJob, setExistingJob] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [isCreatePopup, setIsCreatePopup] = useState(false);
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const [createInputValue, setCreateInputValue] = useState("");
  const [editInputValue, setEditInputValue] = useState("");

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    Promise.all([getAllJobs(), getAllJobDepartments()])
    .then((results) => {
      const [jobsRes, jobDepartmentsRes] = results;
      setJobs([...jobsRes.data]);
      setJobDepartments([...jobDepartmentsRes.data]);
    })
    .catch((error) => {
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  const openCreatePopup = () => {
    setCloseJobs([])
    setExistingJob(false);
    setCreateInputValue("");
    setSelectedJobDepartment(jobDepartments.find((jd) => jd.id === '238532a6-48e5-402d-9ea3-1754d5246dc2'));
    setIsCreatePopup(true);
  };

  const onChangeJobDepartment = (e) => {
    setSelectedJobDepartment(jobDepartments.find((jd) => jd.id === e.target.value));
}

  const openEditPopup = (job) => {
    setCloseJobs([])
    setExistingJob(false);
    setEditInputValue(job.label);
    setSelectedJob(job);
    setSelectedJobDepartment(jobDepartments.find((jd) => jd.id === job.jobDepartment?.id));
    setIsEditPopup(true);
  };

  const openRemovePopup = (job) => {
    setSelectedJob(job);
    setIsRemovePopup(true);
  };

  const isExistingJob = (jobName) => {
    return jobs.find((job) => {
      if (job.label.trim().toLowerCase() === jobName.trim().toLowerCase()) {
        return true;
      }
      return false;
    });
  }

  const getCloseJobs = (jobName) => {
    return jobs.filter((s) => {
      const distance = levenshteinDistance(jobName.trim().toLowerCase(), s.label.trim().toLowerCase());
      if (distance) return distance < 3;
      return false
    })
  }

  const filterJobsByLabel = (jobs) => {
    return jobs.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }
  
  const submitAddJob = (e) => {
    e.preventDefault();
    const existing = isExistingJob(e.target.createLabel.value);
    setExistingJob(existing);
    if (!existing) {
      setIsCreatePopup(false);
      addJob(e.target.createLabel.value.trim(), selectedJobDepartment?.id).then(() => {
        toast("success", "Le métier a été créé !");
        reload();
      },
      (error) => {
        toast("error", error.response.data.reason?.message[0] ?? error.response.data.reason);
      })
      .finally(() => {
        setCreateInputValue("");
      })
    }
  }

  const submitEditJob = (e) => {
    e.preventDefault();
    setIsEditPopup(false);
    editJob(selectedJob.id, e.target.editLabel.value.trim(), selectedJobDepartment?.id).then((res) => {
      toast("success", "Le métier a été modifié !");
      reload();
    },
    (error) => {
      toast("error", error.response.data.reason?.message[0] ?? error.response.data.reason);
    }).finally(() => {
      setEditInputValue("");
      setSelectedJobDepartment(jobDepartments.find((jd) => jd.id === '238532a6-48e5-402d-9ea3-1754d5246dc2'));
    });
  };

  const submitDeleteJob = () => {
    deleteJob(selectedJob?.id).then(() => {
      toast("success", "Le métier a été supprimé !");
      reload();
    },
    (error) => {
      toast("error", error.response.data.reason);
    });
    setIsRemovePopup(false)
  };

  return (
    <div className={`${style['container']} py-4 px-5`}>
    <Popup show={isEditPopup} size="sm" onClose={(e) => setIsEditPopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitEditJob}
      >
        <div className="col-12 wow-subtitle bold">
          Modifier le métier "{selectedJob?.label}"
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom :
          </label>
          <input
            id="editLabel"
            name="editLabel"
            className="default-input"
            type="text"
            value={editInputValue}
            onChange={(e) => {  setEditInputValue(e.target.value );
                                const closeJobs = getCloseJobs(e.target.value);
                                setCloseJobs(closeJobs); }}
          />
          {existingJob ? (
            <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
              Métier déjà existant.
            </div>
          ) : null}
          {!!closeJobs.length && (
            <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Métier(s) semblable(s) :
            <div className={style["job-container"]}>
                {closeJobs.map((j, i) => {
                  return  <span key={i} className={style["job"]} style={{paddingRight: "24px", background: getColorCode(j.jobDepartment.businessUnit[0])}} >
                            {j.label}
                          </span>
                })}
            </div>
          </div>
          )}
              <label className="small-text mt-1">Catégorie métier :</label>
              <select className="default-input" style={{background: getColorCode(selectedJobDepartment?.businessUnit && selectedJobDepartment?.businessUnit[0])}}  onChange={onChangeJobDepartment} value={selectedJobDepartment?.id}>
                  {jobDepartments.map((jd, index) => <option style={{background: getColorCode(jd?.businessUnit[0])}} value={jd.id} key={index}>{jd.label}</option>)}
              </select>
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setIsEditPopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isCreatePopup} size="sm" onClose={(e) => setIsCreatePopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitAddJob}
      >
        <div className="col-12 wow-subtitle bold">
          Ajouter un nouveau métier
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom
          </label>
          <input
            id="createLabel"
            name="createLabel"
            className="default-input"
            type="text"
            value={createInputValue}
            onChange={(e) => {  setCreateInputValue(e.target.value );
                                const closeJobs = getCloseJobs(e.target.value);
                                setCloseJobs(closeJobs); }}
          />
          {existingJob ? (
            <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
              Métier déjà existant.
            </div>
          ) : null}
          {!!closeJobs.length && (
            <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Métier(s) semblable(s) :
            <div className={style["job-container"]}>
                {closeJobs.map((j, i) => {
                  return  <span key={i} className={style["job"]}  style={{paddingRight: "24px", background: getColorCode(j.jobDepartment.businessUnit[0])}} >
                            {j.label}
                          </span>
                })}
              </div>
          </div>
          )}
          <label className="small-text mt-1">Type de contrat :</label>
              <select className="default-input"  style={{background: getColorCode(selectedJobDepartment?.businessUnit && selectedJobDepartment?.businessUnit[0])}}  onChange={onChangeJobDepartment} value={selectedJobDepartment?.id ?? '238532a6-48e5-402d-9ea3-1754d5246dc2'}>
                  {jobDepartments.map((jd, index) => <option style={{background: getColorCode(jd?.businessUnit[0])}} value={jd.id} key={index}>{jd.label}</option>)}
              </select>
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setIsCreatePopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isRemovePopup} size="sm" onClose={(e) => setIsRemovePopup(false)}>
      <div className="row justify-content-between p-3 py-4 gap-2">
        <div className="wow-subtitle bold">
          Supprimer le métier "{selectedJob?.label}"
        </div>
        <div>
          Confirmer pour supprimer cette métier.
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button className="default-btn danger-btn p-2" onClick={(e) => setIsRemovePopup(false)}>
            Annuler
          </button>
          <button type="button" className="default-btn p-2" onClick={submitDeleteJob}>
            Confirmer
          </button>
        </div>
      </div>
    </Popup>
        <div className="mb-4">
          <div className="header-2">Tous les métiers</div>
          <div className="d-flex flex-row gap-2">
            <SearchBar onSubmit={setSearch} placeholder='.NET, Scrum,...'/>
            <button style={{width:'120px'}} className="default-btn p-2" onClick={openCreatePopup}>
              Ajouter
            </button>
          </div>
        </div>
        <div>
          <div className="col-12 flex-column gap-3 align-items-center">
            {loading ? (
              <div
                className="w-100 position-relative card"
                style={{ height: "300px" }}
              >
                <Loader />
              </div>
            ) : jobs.length ? (
              <div className={style["job-container"]}>
                {filterJobsByLabel(jobs.filter(job => {
                  return (job.label.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                })).map((j, i) => {
                  return <span key={i} style={{background: getColorCode(j.jobDepartment?.businessUnit[0])}} className={style["job"]}>
                    {j.label}
                    <FiEdit
                      className={`${style["edit-job"]} ${style["rotate-icon"]}`}
                      onClick={(e) => openEditPopup(j)}
                    />
                    <FiX 
                      className={`${style["delete-job"]} ${style["rotate-icon"]}`}
                      onClick={(e) => openRemovePopup(j)}
                    />
                  </span>
                })}
              </div>
            ) : (
              <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
                Aucun métier
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default Jobs;
