import { useEffect, useState } from "react";

export const useSuggestions = ({ callFunction }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search && displaySuggestions && callFunction) {
      callFunction(search)?.then((res) => res && setSuggestions(res));
    }
  }, [search, displaySuggestions]);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value) {
      setDisplaySuggestions(true);
    } else {
      setDisplaySuggestions(false);
    }
  };

  const onClickSuggestion = () => {
    setDisplaySuggestions(false);
    setSearch("");
    setSuggestions([]);
  };

  return {
    suggestions,
    displaySuggestions,
    search,
    setSuggestions,
    setDisplaySuggestions,
    onChangeSearch,
    onClickSuggestion,
  };
};
