export const CheckboxesEnum = {
    TIMESHEET_SENDED: 'TIMESHEET_SENDED',
    TIMESHEET_APPROVED: 'TIMESHEET_APPROVED',
    INVOICE_SENDED: 'INVOICE_SENDED',
    INVOICE_APPROVED: 'INVOICE_APPROVED'
}

export const initialCheckboxStates = { timesheetSended: false, timesheetApproved: false, invoiceSended: false, invoiceApproved: false };

const checkboxReducers = (state = initialCheckboxStates, payload) => {
    switch (payload.type) {
        case CheckboxesEnum.TIMESHEET_SENDED:
            return { ...state, timesheetSended: !state.timesheetSended };
        case CheckboxesEnum.TIMESHEET_APPROVED:
            return { ...state, timesheetApproved: !state.timesheetApproved };
        case CheckboxesEnum.INVOICE_SENDED:
            return { ...state, invoiceSended: !state.invoiceSended };
        case CheckboxesEnum.INVOICE_APPROVED:
            return { ...state, invoiceApproved: !state.invoiceApproved };
        default: return initialCheckboxStates;
    }
}
export default checkboxReducers;