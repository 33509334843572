import React from "react";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { Actions } from "../../Actions";
import style from "../style.module.css";

const ListItemContractCadre = ({ contract, onDelete, openPopup }) => {
  const actions = [
    {
      label: "Supprimer le contrat",
      color: "red",
      action: () => onDelete(contract, "admin_contract", "CADRE"),
    },
    {
      label: "Modifier le contrat",
      color: "black",
      action: () => openPopup(0, contract),
    },
  ];
  return (
    <>
      <div className={`${style["table-item"]}`}>
        {/* NOM DU DOCUMENT */}
        <div className="small-text">{contract?.file?.filename}</div>
        {/* DOCUMENT */}
        <div className="small-text">
          <a
            className="justify-content-between text-decoration-underline cursor-pointer"
            href={
              contract?.file &&
              b64ToObjectUrl(
                contract.file.base64Document,
                contract.file.filetype
              )
            }
            target="_blank"
            rel="noreferrer"
          >
            {contract?.file?.filename}
          </a>
        </div>
        <div className="small-text">
          {contract?.signatureDate &&
            new Date(contract.signatureDate).toLocaleDateString()}
        </div>
        {/* DATE DE DEBUT */}
        <div className="small-text">
          {contract?.startDate &&
            new Date(contract.startDate).toLocaleDateString()}
        </div>
        {/* DATE DE FIN */}
        <div className="small-text">
          {contract?.expirationDate &&
            new Date(contract.expirationDate).toLocaleDateString()}
        </div>
        <div>
          <Actions myActions={actions} />
        </div>
      </div>
    </>
  );
};

export default ListItemContractCadre;
