import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {  addMission, getAddMission } from "../../../Services/Mission"
import Loader from "../../../Components/Loader";
import toast from "../../../Helpers/toast";
import MissionPopup from "../../../Components/Mission/Popup";

const NouvelleMission = () => {

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [missionStates, setMissionStates] = useState({
    durations:[],
    workRythmns:[],
    workLocations:[]
  });

  const handleAddMission = (formValues) => {
    addMission(formValues).then((res) => {
      toast("success", "Mission ajoutée");
      history.push('/missions/editer/' + res.data.id);
    });
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAddMission().then((res) => {
        setMissionStates({
          durations:res.data.duration,
          workRythmns:res.data.workRythmn,
          workLocations:res.data.workLocation,
        })
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, []);

  const switchToEditMissionPopup = (mission) => {
    history.push('/missions/mes-missions/');
  }

  return  loading ? (
    <div className='container' 
      style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center'}}>
      <div
        className="w-100 position-relative card"
        style={{ height: "300px" }}
      >
        <Loader />
      </div>
    </div>
    ) :  ( 
      <MissionPopup  
        switchCallback={() => (switchToEditMissionPopup())}
        addMissionCallback={handleAddMission} 
        missionStates={missionStates}
        isShortened={false}
      />
    );
}

export default NouvelleMission;