import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "../../../../../Components/CustomCheckbox";
import { CheckboxesEnum } from '../../../../../Redux/EndOfMonthSummary/reducers'

const CheckboxesConfigSummary = ({ classes }) => {
    const reducerState = useSelector(state => state.checkboxReducers);
    const dispatch = useDispatch();

    const checkboxConfig = [
        { value: reducerState.timesheetSended, name: 'timsheetSubmitted', onChange: () => { dispatch({ type: CheckboxesEnum.TIMESHEET_SENDED }) }, label: 'C.R.A SOUMIS', readOnly: false },
        { value: reducerState.timesheetApproved, name: 'timesheetApproved', onChange: () => { dispatch({ type: CheckboxesEnum.TIMESHEET_APPROVED }) }, label: 'C.R.A VALIDE', readOnly: false },
        { value: reducerState.invoiceSended, name: 'invoiceSubmitted', onChange: () => { dispatch({ type: CheckboxesEnum.INVOICE_SENDED }) }, label: 'FACTURE SOUMISE', readOnly: false },
        { value: reducerState.invoiceApproved, name: 'invoiceApproved', onChange: () => { dispatch({ type: CheckboxesEnum.INVOICE_APPROVED }) }, label: 'FACTURE VALIDE', readOnly: false },
    ];
    const customCheckboxes = checkboxConfig.map(conf => <CustomCheckbox key={conf.name} {...conf} />);

    return <div className={classes}>
        <span>Filtres additionnels :</span>
        {customCheckboxes}
    </div>
}
export default CheckboxesConfigSummary;