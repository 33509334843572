import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getMission, editMission, getAddMission, getCandidaturesMission } from "../../../Services/Mission"
import Loader from "../../../Components/Loader";
import toast from "../../../Helpers/toast";
import MissionPopup from "../../../Components/Mission/Popup";
import style from "./style.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { rejectApplication, approveApplication } from "../../../Services/Consultant";

const FicheMission = ({ match }) => {

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [mission, setMission] = useState(null);
  const [applications, setApplications] = useState([]);

  const [missionStates, setMissionStates] = useState({
    durations:[],
    workRythmns:[],
    workLocations:[]
  });

  const handleEditMission = (formValues) => {
    editMission(match.params.id, formValues).then(() => {
      toast("success", "Mission modifiée");
    });
  }

  const handleEditApplicationList = (application) => {
    setApplications((oldList) => {
      const existingApplicationIndex = oldList.findIndex((a) => {
        return a.id === application.id
      })

      const mergedApplication = {...oldList[existingApplicationIndex], ...application}
      oldList.splice(existingApplicationIndex, 1)
      return [mergedApplication, ...oldList]
    })
  }

  const handleRejectApplication = async (application) => {
    return rejectApplication(application).then((result) => {
      handleEditApplicationList(result.data);
    })
  }

  const handleApproveApplication = async (application) => {
    return approveApplication(application).then((result) => {
      handleEditApplicationList(result.data);
    })
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAddMission().then((res) => {
        setMissionStates({
          durations:res.data.duration,
          workRythmns:res.data.workRythmn,
          workLocations:res.data.workLocation,
        })
      }),
      getCandidaturesMission(match.params.id).then((res) => {
        setApplications(res.data.applications);
      }),
      getMission(match.params.id).then((res) => {
        setMission(res.data);
        if(!res.data){
          toast("error", 'Aucune mission trouvée');
        }
      }).catch((error) => {
        toast("error", error.response.statusText);
      })
    ]).then(() => {
      setLoading(false);
    })
  }, []);

  const switchToEditMissionPopup = (mission) => {
    history.push('/missions/mes-missions/', { mission });
  }


  return  loading ? (
    <div className='container' 
      style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center'}}>
      <div
        className="w-100 position-relative card"
        style={{ height: "300px" }}
      >
        <Loader />
      </div>
    </div>
    ) : mission ? ( 
      <MissionPopup 
        switchCallback={() => (switchToEditMissionPopup(mission))}
        mission={mission} 
        editMissionCallback={handleEditMission} 
        approveApplicationCallback={handleApproveApplication}
        rejectApplicationCallback={handleRejectApplication}
        missionStates={missionStates}
        isShortened={false}
        applications={applications}
      />
    ) : ( 
      <div className={`${style['container']} py-4 px-5 gap-3 overflow-hidden`} style={{height: '-webkit-fill-available'}}>
          Aucune mission trouvée
      </div>
      );
}

export default withRouter(FicheMission);