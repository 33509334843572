import { ProtectedApi } from "./../Helpers/api";

export const getJobDepartments = async (search) => {
  return ProtectedApi.get(`/job-departments/search`, {
    params: {
      q: search,
    },
  });
};

export const getAllJobDepartments = async () => {
  return ProtectedApi(`/job-departments`);
};
export const addJobDepartment = async (label, businessUnit) => {
  return ProtectedApi.post(`/job-departments`,{label, businessUnit: [businessUnit]});
};
export const editJobDepartment = async (id, label, businessUnit) => {
  return ProtectedApi.patch(`/job-departments/`+id,{label, businessUnit: [businessUnit]});
};
export const deleteJobDepartment = async (id) => {
  return ProtectedApi.delete(`/job-departments/`+id);
};