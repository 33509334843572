import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import BDCTable from "../../../Components/BDCTable";
import ContratApplicationTable from "../../../Components/ContractApplicationTables";
import ContratCadreTable from "../../../Components/ContratCadreTables";
import Popup from "../../../Components/Popup";
import toast from "../../../Helpers/toast";
import { deleteDocument } from "../../../Services/Consultant";
import { getConsultantsBySocietyId } from "../../../Services/Consultants";
import { getContractsBySocietyId } from "../../../Services/ContratAdmin";
import { getMissionsBySocietyId } from "../../../Services/Mission";
import Loader from "./../../../Components/Loader/index";
import { PopupBdc } from "./PopupBdc";
import { PopupContratApplication } from "./PopupContratApplication";
import { PopupContratCadre } from "./PopupContratCadre";
import SearchBar from "../../../Components/SearchBar";

const ContratEtBonDeCommande = () => {
  const [loading, setLoading] = useState(false);
  const [popupIndex, setPopupIndex] = useState(null);

  const [open, setOpen] = useState(false);
  const [missionsBySocietyId, setMissionsBySocietyId] = useState([]);
  const [consultantsBySocietyId, setConsultantsBySocietyId] = useState([]);

  const [bdcs, setBdcs] = useState([]);
  const [cadres, setCadres] = useState([]);
  const [applications, setApplications] = useState([]);

  const contractType = ['BDC','APPLICATION','CADRE'];
  const [selectedContractType, setSelectedContractType] = useState('');

  const onChangeContractTypeHandler = (e) => {
    setSelectedContractType(e.target.value);
}

  const [currentContract, setCurrentContract] = useState();
  const match = useRouteMatch();

  const [search, setSearch] = useState("");
  const onDeleteHandler = (contract, type, specie) => {
    deleteDocument(contract, type).then(() => {
      switch (specie) {
        case "BDC":
          setBdcs((oldBdcs) => [
            ...oldBdcs.filter((bdc) => bdc.id !== contract.id),
          ]);
          break;
        case "CADRE":
          setCadres((oldCadres) => [
            ...oldCadres.filter((cadre) => cadre.id !== contract.id),
          ]);
          break;
        case "APPLICATION":
          setApplications((oldApplications) => [
            ...oldApplications.filter(
              (application) => application.id !== contract.id
            ),
          ]);
          break;
        default:
          return;
      }
      toast("success", "Contrat supprimé");
    });
  };

  const selectPopupToShow = (index) => {
    let child = null;
    if (index === 0) {
      child = (
        <PopupContratCadre
          setOpen={setOpen}
          societyId={match.params.id}
          setCadresHandler={setCadres}
          currentContratToUpdate={currentContract}
        />
      );
    } else if (index === 1) {
      child = (
        <PopupBdc
          setOpen={setOpen}
          consultants={consultantsBySocietyId}
          societyId={match.params.id}
          setBdcsHandler={setBdcs}
          currentContratToUpdate={currentContract}
        />
      );
    } else if (index === 2) {
      child = (
        <PopupContratApplication
          setOpen={setOpen}
          consultants={consultantsBySocietyId}
          societyId={match.params.id}
          setApplicationsHandler={setApplications}
          currentContratToUpdate={currentContract}
        />
      );
    }

    return (
      <Popup show={open} onClose={() => setOpen(false)}>
        {child}
      </Popup>
    );
  };

  const popupHandler = (index, contract) => {
    setOpen(true);
    setPopupIndex(index);
    if (contract) {
      setCurrentContract(contract);
    }
  };

  useEffect(() => {
    getMissionsBySocietyId(match.params.id).then((res) => {
      setMissionsBySocietyId(res.data);
    });
    getConsultantsBySocietyId(match.params.id).then((res) => {
      setConsultantsBySocietyId(res.data);
    });

    getContractsBySocietyId(match.params.id, selectedContractType).then((res) => {
      if (res.data) {
        setCadres(res.data.cadre);
        setApplications(res.data.application);
        setBdcs(res.data.bdc);
      }
    });
  }, [selectedContractType, match.params.id]);

  return (
    <div className="row card p-5 gap-5">
      {loading ? (
        <div className="col-12 position-relative" style={{ height: "300px" }}>
          <Loader />
        </div>
      ) : (
        <>
          <div>{open && selectPopupToShow(popupIndex)}</div>
          <div className="col-12 d-flex flex-column gap-4">
          <div className="d-flex flex-column mb-4 gap-2">
            <SearchBar onSubmit={setSearch} placeholder='Rechercher par consultant, intitulé de mission,...'/>
            <div>
              <label>Type de contrat :</label>
              <select className="default-input" onChange={onChangeContractTypeHandler} value={selectedContractType}>
                  <option>ALL</option>
                  {contractType.map((value, index) => <option value={value} key={index}>{value}</option>)}
              </select>
            </div>
          </div>
          
            <div className="w-100 d-flex flex-row justify-content-between align-items-end">
              <div className="wow-subtitle green-text">Contrat cadre</div>
              <div>
                <button
                  className="default-btn"
                  onClick={() => {
                    setCurrentContract(null);
                    popupHandler(0);
                  }}
                >
                  Ajouter un contrat cadre
                </button>
              </div>
            </div>
            <ContratCadreTable
              contracts={cadres}
              onDelete={onDeleteHandler}
              openPopup={popupHandler}
            />
          </div>
          <div className="col-12 d-flex flex-column gap-4">
            <div className="w-100 d-flex flex-row justify-content-between align-items-end">
              <div className="wow-subtitle green-text">Bons de commande</div>
              <div>
                <button
                  className="default-btn"
                  onClick={() => {
                    setCurrentContract(null);
                    popupHandler(1);
                  }}
                >
                  Ajouter un BDC
                </button>
              </div>
            </div>
            <BDCTable
              missions={missionsBySocietyId}
              contracts={bdcs.filter(contract=>{
                return (contract.consultant.user.fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1)||  (contract.mission.label.toLowerCase().indexOf(search.toLowerCase()) !==-1)
              })}
              onDelete={onDeleteHandler}
              openPopup={popupHandler}
            />
          </div>
          <div className="col-12 d-flex flex-column gap-4">
            <div className="w-100 d-flex flex-row justify-content-between align-items-end">
              <div className="wow-subtitle green-text">
                Contrat d'application
              </div>
              <div>
                <button
                  className="default-btn"
                  onClick={() => {
                    setCurrentContract(null);
                    popupHandler(2);
                  }}
                >
                  Ajouter un contrat d'application
                </button>
              </div>
            </div>
            <ContratApplicationTable
              contracts={applications.filter(contract=>{
                return (contract.consultant.user.fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1)||  (contract.mission.label.toLowerCase().indexOf(search.toLowerCase()) !==-1)
              })}
              onDelete={onDeleteHandler}
              openPopup={popupHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContratEtBonDeCommande;
