import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import {
  FiCheckCircle,
  FiMoreVertical,
  FiPaperclip,
  FiUpload,
  FiXSquare,
} from "react-icons/fi";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import Popup from "../../Popup";
import style from "../style.module.css";
import { legalFilesStatuses } from "./../../../Helpers/data";
import { getDocumentByIdAndType } from "../../../Services/Consultant";

const ListItem = ({
  file,
  name,
  type,
  title,
  onUploadFile = (f) => f,
  onApprove = (f) => f,
  onReject = (f) => f,
}) => {
  const inputRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);

  const formik = useFormik({
    initialValues: {
      date: "",
    },
    onSubmit: (values) => {
      onApprove(file._id, file.type, values.date).then(() =>
        setOpenApprove(false)
      );
    },
  });
  const getExpirationMessage = (type) => {
    switch (type) {
      case "urssaf":
        return "Saisir la date d'édition du document + 6 mois";
      case "kbis":
        return "Saisir la date d'édition du document + 6 mois";
      case "liability_insurance":
        return "Saisir la date d'échéance mentionnée sur le contrat";
      case "front":
        return "Saisir la date de validité inscrite sur l'ID. Pour les titres de séjour et visa de travail renseigner la date d'expiration - 15jours";
      case "back":
        return "Saisir la date de validité inscrite sur l'ID. Pour les titres de séjour et visa de travail renseigner la date d'expiration - 15jours";
      case "tax_certificate":
        return "Saisir la date d'édition du document + 12 mois";
      case "insee":
        return "Saisir la date d'édition du document + 6 mois";
      default:
        break;
    }
    return "";
  };

  const getDocumentHandler = (document) => {
    if (!document._id) {
      return;
    }
    getDocumentByIdAndType(document._id, type).then((res) => {
      const file = res.data;
      if (!file) {
        return;
      }
      const url = b64ToObjectUrl(file.base64Document, file.filetype);
      window.open(url, "blank");
    });
  };

  return (
    <>
      {openApprove && (
        <Popup show={openApprove} onClose={(e) => setOpenApprove(false)}>
          <form
            className="row justify-content-between p-3 py-4 gap-4"
            onSubmit={formik.handleSubmit}
          >
            <div className="col-12 wow-subtitle bold">
              Choisir la date d'expiration du document
            </div>
            <div className="col-12 d-flex flex-column gap-3">
              <label className="small-text bold cursor-pointer" htmlFor="date">
                {getExpirationMessage(file.type)}
              </label>
              <input
                id="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                className="default-input"
                type="date"
                required
              />
            </div>
            <div className="col-12 d-flex flex-row justify-content-center gap-4">
              <div>
                <button
                  className="default-btn danger-btn p-2"
                  type="button"
                  onClick={(e) => setOpenApprove(false)}
                >
                  Annuler
                </button>
              </div>
              <div>
                <button className="default-btn p-2" type="submit">
                  Confirmer
                </button>
              </div>
            </div>
          </form>
        </Popup>
      )}
      <div className={`${style["documents-table-item"]}`}>
        <div className="small-text bold">{title}</div>
        <div className="small-text d-none d-md-flex">
          {file?.createdAt && new Date(file?.createdAt).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {file?.expirationDate &&
            new Date(file?.expirationDate).toLocaleDateString()}
        </div>
        <div className="wow-body d-none d-md-flex justify-content-between cursor-pointer">
          <FiPaperclip
            style={{ transform: "rotate(-45deg)" }}
            onClick={() => getDocumentHandler(file)}
          />
        </div>
        {file?.expirationDate && new Date(file?.expirationDate) < new Date() ? (
          <div
            className={`${style["document-status"]} small-text`}
            status="REFUSED"
          >
            Périmé
          </div>
        ) : (
          <div
            className={`${style["document-status"]} small-text`}
            status={file?.status ?? ""}
          >
            {legalFilesStatuses[file?.status] ?? "Manquant"}
          </div>
        )}
        <div className="wow-body text-center cursor-pointer">
          <FiMoreVertical
            onClick={(e) => {
              setShowMore(true);
            }}
          />
        </div>
        {showMore && (
          <>
            <div
              className={`${style["show-more"]} d-flex flex-column align-items-center justify-content-end py-3 gap-3`}
            >
              <div
                className="small-text green-text cursor-pointer"
                onClick={async (e) => {
                  if (file) {
                    if (file.filelabel !== "RIB") {
                      setOpenApprove(true);
                    } else {
                      await onApprove(file._id, file.type);
                    }
                    setShowMore(false);
                  }
                }}
              >
                <FiCheckCircle className="mx-1" /> Valider le document
              </div>
              <div
                className="small-text red-text cursor-pointer"
                onClick={(e) => {
                  onReject(file._id, file.type).then((res) =>
                    setShowMore(false)
                  );
                }}
              >
                <FiXSquare className="mx-1" />
                Rejeter le document
              </div>
              <div
                className="small-text cursor-pointer"
                onClick={(e) => inputRef.current.click()}
              >
                <FiUpload className="mx-1" />
                Transmettre un document
              </div>
            </div>
            <div
              className={`${style["close-show-more"]}`}
              onClick={(e) => setShowMore(false)}
            ></div>
          </>
        )}
        <input
          type="file"
          name={name}
          id={name}
          title={title}
          accept="image/*, application/pdf"
          hidden
          ref={inputRef}
          onChange={onUploadFile}
        />
      </div>
    </>
  );
};

export default ListItem;
