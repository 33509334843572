import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  ApproveInvoice,
  PayInvoice,
  RejectInvoice,
} from "../../Services/Consultant";
import ListItem from "./ListItem";
import style from "./style.module.css";

const FacturesTable = ({ factures = [] }) => {
  const [facturesList, setFacturesList] = useState([]);

  useEffect(() => {
    setFacturesList(factures);
  }, [factures]);

  const onApprove = async (id) => {
    await ApproveInvoice(id).then((res) => {
      setFacturesList((oldFacturesList) => {
        const index = oldFacturesList.findIndex(
          (invoice) => invoice.id === res.data.id
        );
        oldFacturesList[index].status = res.data.status;
        oldFacturesList[index].paymentDate = res.data.paymentDate;
        return [...oldFacturesList];
      });
    });
  };
  const onPay = async (id) => {
    await PayInvoice(id).then((res) => {
      setFacturesList([...res.data]);
    });
  };
  const onReject = async (id) => {
    await RejectInvoice(id).then((res) => {
      setFacturesList([...res.data]);
    });
  };
  return (
    <div className={style["factures-table"]}>
      <div
        className={`${style["factures-table-item"]} ${style["factures-table-header"]}`}
      >
        <div className="small-text">Mission</div>
        <div className="small-text d-none d-md-flex">Fichier</div>
        <div className="small-text d-none d-md-flex">Date création</div>
        <div className="small-text d-none d-md-flex">Date Paiement</div>
        <div className="small-text">Statut</div>
        <div className="small-text text-center">Action</div>
      </div>
      {facturesList.length > 0 ? (
        facturesList.map((f, i) => {
          return (
            <ListItem
              key={i}
              facture={f}
              onApprove={onApprove}
              onReject={onReject}
              onPay={onPay}
            />
          );
        })
      ) : (
        <div className="w-100 d-flex justify-content-center small-text p-5">
          Aucune facture
        </div>
      )}
    </div>
  );
};

export default FacturesTable;
