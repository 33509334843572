import { MissionIdentite } from "../MissionIdentite";
import { SkillMarks } from "../SkillMarks";

export const Skills = ({ currentConsultant }) => {
  return (
    <div className="d-flex flex-row gap-2 my-2">
      <MissionIdentite currentConsultant={currentConsultant} />
      {currentConsultant?.consultantSkillMarks?.length > 0 && (
        <div className="card p-4 w-100">
          <div className="wow-subtitle">Compétences</div>
          <SkillMarks
            category={"Compétences(s)"}
            skillMarks={currentConsultant?.consultantSkillMarks}
          />
        </div>
      )}
      {currentConsultant?.spokenLanguages?.length > 0 && (
        <div className="card p-4 w-100">
          <div className="wow-subtitle">Langues</div>
          <SkillMarks
            category={"Langue(s)"}
            skillMarks={currentConsultant?.spokenLanguages}
          />
        </div>
      )}
    </div>
  );
};
