import React, { useState } from "react";
import { useEffect } from "react";
import FacturesTable from "../../../Components/FacturesTable";
import TimesheetsTable from "../../../Components/TimesheetsTable";
import { useSelector } from "react-redux";
import { GetInvoicesByMissionAndConsultant } from "../../../Services/Consultant";

const FinDuMoisConsultant = () => {
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [missions, setMissions] = useState([]);

  const [timesheets, setTimesheets] = useState([]);
  const [factures, setFactures] = useState([]);

  useEffect(() => {
    if (currentConsultant) {
      setMissions(currentConsultant.missions);
    }
  }, [currentConsultant]);

  const onMissionChange = (e) => {
    if (e.target.value !== "Choisir une mission") {
      const selectedMission = missions.find(
        (mission) => mission.id === e.target.value
      );
      if (selectedMission) {
        GetInvoicesByMissionAndConsultant(
          currentConsultant.id,
          selectedMission.id
        ).then((res) => {
          setFactures(res.data);
        });
      }
      setTimesheets(
        currentConsultant.timesheets
          .filter((t) => t.mission.id === selectedMission.id)
          .sort(
            (t1, t2) =>
              new Date(t2.createdAt).getTime() -
              new Date(t1.createdAt).getTime()
          ) ?? []
      );
    }
  };
  return (
    <div className="row card p-5 gap-5">
      <div className="col-12 d-flex flex-column gap-3">
        <div className="wow-subtitle bold">Choisir une mission</div>
        <select className="default-input" onChange={onMissionChange}>
          <option>Choisir une mission</option>
          {missions.map((m, i) => {
            return (
              <option key={i} value={m.id}>
                {m.label}
              </option>
            );
          })}
        </select>
      </div>
      <>
        <div className="col-12 wow-subtitle green-text">
          Compte rendu d'activité
        </div>
        <div className="col-12">
          <TimesheetsTable
            timesheets={timesheets}
            consultantId={currentConsultant && currentConsultant.id}
          />
        </div>
        <div className="col-12 wow-subtitle green-text">Facture</div>
        <div className="col-12">
          <FacturesTable factures={factures} />
        </div>
      </>
    </div>
  );
};

export default FinDuMoisConsultant;
