import { b64ToObjectUrl } from "../../Helpers/base64-decode";

import style from './style.module.css';

const Image = ({ logo, classes, width, height, shallRenderRandomAvatar }) => {
    let classNames = `${style["img-container"]}`;
    if (classes) {
        classNames = `${classNames} ${classes}`;
    }

    if (shallRenderRandomAvatar) {
        const randomNumberBetweenOneToFive = Math.abs(Math.floor(Math.random() * 10) - 5);
        
        const randomAvatar =
            <img width={width} height={height} src={`./avatars/${randomNumberBetweenOneToFive}.png`} />

        return <div className={classNames}>
            {randomAvatar}
        </div>
    }

    return (
        <div className={classNames}>
            {logo ? (
                <img
                    src={b64ToObjectUrl(
                        logo.base64Document,
                        logo.filetype
                    )}
                    width={width}
                    height={height}
                    alt="logo"
                />
            ) : (
                <img src={"/wow-circle-white.svg"} alt="wheel of work logo"
                    width={width}
                    height={height} />
            )}
        </div>
    )
}


export default Image;