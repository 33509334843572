import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiMinus, FiPlus, FiSearch, FiX } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router";
import CustomCheckbox from "../../../Components/CustomCheckbox/index";
import Loader from "../../../Components/Loader";
import toast from "../../../Helpers/toast";
import { getManagers } from "../../../Services/Manager";
import {
  editMission,
  getAddMission,
  getMission
} from "../../../Services/Mission";
import { getSkills } from "../../../Services/Skills";
import style from "./style.module.css";

const ModifierMission = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [mission, setMission] = useState(null);


  const [durations, setDurations] = useState([]);
  const [workRythms, setRythms] = useState([]);
  const [missionTypes, setTypes] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [managers, setManagers] = useState([]);
  const [selectedSocietyId, setSelectedSocietyId] = useState("");
  const [selectedManagerId, setSelectedManagerId] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: "",
      dailyAverage: "",
      ref: "",
      isConfidential: false,
      isHidden: false,
      society: selectedSocietyId ?? "",
      description: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
      },
      type: missionTypes[0] ?? "",
      duration: durations[0] ?? "",
      monthsDuration: 1,
      startDate: "",
      workRythmn: workRythms[0] ?? "",
      task: "",
      ...mission,
    },
    onSubmit: (values) => {
      delete values.logo;
      let formValues = {
        ...values,
        skills: skills.map((skill) => ({
          id: skill.id,
        })),
        tasks,
        society: { id: selectedSocietyId },
        manager: { id: selectedManagerId ?? managers[0].id }
      };

      editMission(match.params.id, formValues).then(() => {
        toast("success", "Mission modifiée");
        history.push(`/client/${mission.society.id}`);
      });
    },
  });
  const onSearch = (search) => {
    getSkills(search).then((res) => setSearchData(res.data));
  };
  const onCloseSearch = () => {
    setSearchData([]);
  };

  const onAddSkill = (skill) => {
    const exist = skills.reduce((prev, cur) => {
      return prev || cur.label === skill.label;
    }, false);
    if (!exist) {
      setSkills([...skills, { ...skill }]);
    }
    setSearchData([]);
  };
  const onDeleteSkill = (skill) => {
    setSkills(skills.filter((s) => s.label !== skill.label));
  };

  const addTask = (e) => {
    if (formik.values.task !== "") {
      setTasks([...tasks, formik.values.task]);
      formik.setFieldValue("task", "");
    }
  };
  const deleteTask = (i) => {
    setTasks(tasks.filter((t, index) => index !== i));
  };

  const onChangeManagerHandler = (event) => {
    setSelectedManagerId(event.target.value);
  }

  useEffect(() => {
    setLoading(true);
    getAddMission().then((res) => {
      setDurations(res.data.duration);
      setRythms(res.data.workRythmn);
      setTypes(res.data.missionType);
      getMission(match.params.id).then((res) => {
        const ms = { ...res.data };
        setSkills(ms.skills);
        setSelectedSocietyId(ms.society.id);
        setSelectedManagerId(ms.manager?.id ?? null);
        setTasks(ms.tasks);
        setMission(ms);
        formik.setFieldValue("startDate", ms.startDate.split("T")[0]);
        setLoading(false);
      });
    });
  }, [match]);


  useEffect(() => {
    if (selectedSocietyId) {
      getManagers({id: selectedSocietyId}).then(res => setManagers(res.data));
    }
  }, [selectedSocietyId])

  return (
    <form className="container py-4 px-5" onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className="row">
          <div
            className="col-12 card position-relative"
            style={{ height: "300px" }}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div className="row card px-4 py-4 gap-4 border-light-grey">
            <div className="text-center">
              <div className="header-4 primary-color-text">Modifier la mission</div>
            </div>

            <div className="col-12 d-flex flex-column gap-2">
              <label className="small-text bold cursor-pointer" htmlFor="label">
                Titre
              </label>
              <input
                id="label"
                name="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                required
              />
            </div>
            <div className="col-12 d-flex flex-row gap-4 align-items-end">
              <div className="w-100 mb-3">
                <CustomCheckbox
                  value={formik.values.isConfidential}
                  name="isConfidential"
                  label="Entreprise confidentielle"
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            {managers?.length > 0 && <div className="w-100 d-flex flex-column gap-2">
              <label className="small-text bold cursor-pointer">
                Managers
              </label>
              <select
                id="manager"
                name="manager"
                className="default-input"
                type="text"
                required
                value={selectedManagerId}
                onChange={onChangeManagerHandler}
              >
                {managers.map((m, i) => {
                  return (
                    <option key={i} value={m.id}>
                      {m.fullName}
                    </option>
                  );
                })}
              </select>
            </div>}
            <div className="col-12 d-flex flex-row gap-3">
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="dailyAverage"
                >
                  TJM achat
                </label>
                <input
                  id="dailyAverage"
                  name="dailyAverage"
                  value={formik.values.dailyAverage}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="dailyAverageResale"
                >
                  TJM revente
                </label>
                <input
                  id="dailyAverageResale"
                  name="dailyAverageResale"
                  value={formik.values.dailyAverageResale}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label className="small-text bold cursor-pointer" htmlFor="ref">
                  Ref inter
                </label>
                <input
                  id="ref"
                  name="ref"
                  value={formik.values.ref}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-3">
              <div className="w-100 d-flex flex-row justify-content-between">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="location.address"
                >
                  Adresse
                </label>
                <div className="w-50 d-flex flex-column justify-content-end flex-md-row gap-4 gap-md-5">
                  {missionTypes.map((mt, i) => {
                    return (
                      <div
                        key={i}
                        className="d-flex flex-row align-items-center"
                      >
                        <input
                          type="radio"
                          id={mt[0]}
                          name="type"
                          value={mt[0]}
                          checked={formik.values.type === mt[0]}
                          onChange={formik.handleChange}
                          hidden
                        />
                        <label className="custom-radio" htmlFor={mt[0]} />
                        <label
                          htmlFor={mt[0]}
                          className="small-text mx-2 cursor-pointer"
                        >
                          {mt[1]}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-100 d-flex flex-row gap-3">
                <input
                  id="address.street"
                  name="address.street"
                  value={formik.values.address.street}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Adresse"
                />
                <input
                  id="address.postalCode"
                  name="address.postalCode"
                  value={formik.values.address.postalCode}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  placeholder="Code postal"
                />
                <input
                  id="address.city"
                  name="address.city"
                  value={formik.values.address.city}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Ville ou région"
                />
                <input
                  id="address.country"
                  name="address.country"
                  value={formik.values.address.country}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Pays"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row gap-3">
              <div className="w-100 d-flex flex-column gap-3">
                <label className="small-text bold cursor-pointer">Durée</label>
                <select
                  id="duration"
                  name="duration"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                >
                  {durations.map((duration, i) => {
                    return (
                      <option key={i} value={duration[0]}>
                        {duration[1]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-100 d-flex flex-column gap-3">
                <label className="small-text bold cursor-pointer">
                  Durée (en mois)
                </label>
                <input
                  id="monthsDuration"
                  name="monthsDuration"
                  value={formik.values.monthsDuration}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  min={1}
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-3">
                <label className="small-text bold cursor-pointer">
                  Type de contrat
                </label>
                <select
                  id="workRythm"
                  name="workRythm"
                  value={formik.values.workRythm}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                >
                  {workRythms.map((wr, i) => {
                    return (
                      <option key={i} value={wr[0]}>
                        {wr[1]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-100 d-flex flex-column gap-3">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="startDate"
                >
                  Date début
                </label>
                <input
                  id="startDate"
                  name="startDate"
                  value={formik.values.startDate?.split("T")[0]}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              {searchData?.length > 0 && (
                <div
                  className="close-search-bg"
                  onClick={onCloseSearch}
                ></div>
              )}
              <div className="small-text bold">Ajoutez des compétences</div>
              <div className="w-100 position-relative">
                <FiSearch className={style["search-icon"]} />
                <input
                  type="text"
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      onSearch(e.target.value);
                    } else {
                      onCloseSearch();
                    }
                  }}
                  className={style["search-input"] + " default-input"}
                  placeholder="Rechercher ici des compétences"
                />
                <div className="search-content">
                  {searchData?.map((d, i) => (
                    <div key={i} onClick={(e) => onAddSkill(d)}>
                      {d.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 d-flex flex-row flex-wrap gap-3">
                {skills.map((s, i) => (
                  <span key={i} className={style["skill"]}>
                    {s.label}
                    <FiX
                      className={style["delete-skill"]}
                      onClick={(e) => onDeleteSkill(s)}
                    />
                  </span>
                ))}
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-3">
              <div className="small-text bold">Les points clés</div>
              {tasks.map((t, i) => {
                return (
                  <div
                    key={i}
                    className="w-100 d-flex flex-row align-items-center gap-3"
                  >
                    <div className="default-input">{t}</div>
                    <div>
                      <button
                        type="button"
                        className="default-btn danger-btn"
                        onClick={(e) => deleteTask(i)}
                      >
                        <FiMinus />
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="w-100 d-flex flex-row align-items-center gap-3">
                <input
                  name="task"
                  id="task"
                  value={formik.values.task}
                  onChange={formik.handleChange}
                  type="text"
                  className="default-input"
                />
                <div>
                  <button
                    type="button"
                    className="default-btn"
                    onClick={addTask}
                  >
                    <FiPlus />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <div className="small-text bold" htmlFor="description">
                Description
              </div>
              <textarea
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className="text-area default-input"
                type="text"
                required
              />
            </div>
            <div className="w-100 mb-3">
              <CustomCheckbox
                value={formik.values.isHidden}
                name="isHidden"
                label="Masquer la mission (ne pas afficher en front consultant)"
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-12 d-flex flex-row justify-content-center">
              <div>
                <button type="submit" className="default-btn">
                  Sauvegarder les modifications
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default ModifierMission;
