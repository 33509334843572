import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { TextField } from "../../TextField";

export const MissionIdentite = ({ currentConsultant }) => {
  const resume = currentConsultant?.documents?.find(
    (doc) => doc.type === "resume"
  )?.documents;

  return (
    <div className="card d-flex flex-column gap-3 small-text p-4 w-100">
      <div className="bold">
        CV{" "}
        {resume && (
          <a
            href={
              resume.base64Document &&
              b64ToObjectUrl(resume.base64Document, resume.filetype)
            }
            target="_blank"
            rel="noreferrer"
            className="small-text blue-text"
          >
            Voir le cv
          </a>
        )}
        {!resume && <span>N/A</span>}
      </div>

      {currentConsultant?.job && (
        <div>
          <span className="bold">Métier</span> {currentConsultant.job.label}{" "}
          {currentConsultant.jobLevel}
        </div>
      )}

      <TextField
        label={"TJM"}
        value={
          currentConsultant?.missionPreferences?.dailyRateAverage &&
          `${currentConsultant?.missionPreferences?.dailyRateAverage}€`
        }
      />

      {/* <TextField
        label={"Ville de résidence"}
        value={currentConsultant?.missionPreferences?.city}
      /> */}

      <TextField
        label={"Date de disponibilité"}
        value={
          currentConsultant?.unavailabilityTerm &&
          new Date(currentConsultant?.unavailabilityTerm).toLocaleDateString()
        }
      />

      <TextField
        label={"Mobilité"}
        value={currentConsultant?.missionPreferences?.workSites.reduce(
          (prev, cur, i) => (i === 0 ? cur : prev + ", " + cur),
          ""
        )}
      />

      {/* <TextField
        label={"Durée"}
        value={durations[currentConsultant?.missionPreferences?.duration]}
      />

      <TextField
        label={"Secteurs"}
        value={currentConsultant?.missionPreferences?.companyTypes?.reduce(
          (prev, cur, i) => {
            return i === 0 ? cur.label : prev + ", " + cur.label;
          },
          ""
        )}
      />

      <TextField
        label={"Taille d'entreprise"}
        value={currentConsultant?.missionPreferences?.companySizes?.reduce(
          (prev, cur, i) => {
            return i === 0 ? cur : prev + ", " + cur;
          },
          ""
        )}
      /> */}

      <TextField
        label={"Rythme de travail souhaité"}
        value={currentConsultant?.missionPreferences?.workPreference}
      />
    </div>
  );
};
