export const userActions = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SET_USER: "SET_USER",
  SET_CURRENT_CONSULTANT: "SET_CURRENT_CONSULTANT",
  SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS:
    "SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS",
  SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS:
    "SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS",
};
