import { ProtectedApi } from "./../Helpers/api";

export const getJobs = async (search) => {
  return ProtectedApi.get(`/jobs/search`, {
    params: {
      q: search,
    },
  });
};

export const getAllJobs = async () => {
  return ProtectedApi(`/jobs`);
};
export const addJob = async (label, jobDepartmentId) => {
  return ProtectedApi.post(`/jobs`,{label:label, jobDepartment: {id: jobDepartmentId}});
};
export const editJob= async (id, label, jobDepartmentId) => {
  return ProtectedApi.patch(`/jobs/`+id,{label:label, jobDepartment: {id: jobDepartmentId}});
};
export const deleteJob = async (id) => {
  return ProtectedApi.delete(`/jobs/`+id);
};