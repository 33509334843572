import { useFormik } from "formik";
import React, { useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import style from "./style.module.css";
import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { getSkills } from "./../../../Services/Skills";
import {
  getAddManager,
  getManager,
  editManager,
  findManagerBySearch,
} from "./../../../Services/Manager";
import Loader from "../../../Components/Loader";
import CustomCheckbox from "../../../Components/CustomCheckbox";
import toast from "../../../Helpers/toast";

const ModifierManager = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [loading, setLoading] = useState(false);
  const [manager, setManager] = useState(null);

  const [managerType, setManagerType] = useState({});

  const [searchDataSkills, setSearchDataSkills] = useState([]);
  const [searchDataManagers, setSearchDataManagers] = useState([]);

  const [skills, setSkills] = useState([]);

  const [managers, setManagers] = useState([]);
  // const [members, setMembers] = useState(["Audric"]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      linkedInUri: "",
      job: "",
      department: "",
      status: Object.keys(managerType)[0] || "",
      site: "",
      notes: "",
      comments: "",
      member: "",
      shouldReceiveTimesheet: false,
      ...manager,
    },
    onSubmit: (values) => {
      const formToSend = {
        ...values,
        affiliateWowCommercials: [...managers],
      };

      editManager(formToSend).then((res) => {
        history.push(`/client/${match.params.id}/carnet-adresses`);
        toast("success", "Modifications prises en compte");
      });
    },
  });

  // const addMember = (e) => {
  //   if (formik.values.member !== "") {
  //     setMembers([...members, formik.values.member]);
  //     formik.setFieldValue("member", "");
  //   }
  // };

  const onAddManager = (manager) => {
    onCloseSearchManagers();
    if (managers.includes(manager)) {
      return;
    }
    setManagers((oldState) => [...oldState, manager]);
  };

  const onDeleteManager = (manager) => {
    setManagers((oldState) =>
      oldState.filter(
        (m) => m.email + m.firstName !== manager.email + manager.firstName
      )
    );
  };

  const searchManager = (e) => {
    findManagerBySearch(e.target.value).then((res) => {
      setSearchDataManagers(res.data);
    });
  };

  const onCloseSearchManagers = () => {
    setSearchDataManagers([]);
  };

  useEffect(() => {
    getAddManager().then((res) => {
      setManagerType(res.data);
      setLoading(true);
      getManager(match.params.idManager)
        .then((resManager) => {
          const manager = resManager.data;
          setSkills(manager.skills);
          setManager(manager);
          setManagers(manager.affiliateWowCommercials);
          setLoading(false);
        })
        .catch((e) => history.push("/clients/comptes"));
    });
  }, [match, history]);

  return (
    <form className="container py-4 px-5" onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className="row">
          <div
            className="col-12 card position-relative"
            style={{ height: "300px" }}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div className="row card px-4 py-4 gap-4 border-light-grey">
            <div className="header-4 green-text">Modifier un manager</div>
            <div className="col-12 d-flex flex-row gap-4">
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="lastName"
                >
                  Nom
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="firstName"
                >
                  Prénom
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row gap-4">
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="phoneNumber"
                >
                  Numéro téléphone
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="linkedInUri"
              >
                Url LinkedIn
              </label>
              <input
                id="linkedInUri"
                name="linkedInUri"
                value={formik.values.linkedInUri}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                required
              />
            </div>
            <div className="col-12 d-flex flex-row gap-4">
              <div className="w-100 d-flex flex-column gap-2">
                <label className="small-text bold cursor-pointer" htmlFor="job">
                  Fonction
                </label>
                <input
                  id="job"
                  name="job"
                  value={formik.values.job}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <label
                  className="small-text bold cursor-pointer"
                  htmlFor="department"
                >
                  Département
                </label>
                <input
                  id="department"
                  name="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label className="small-text bold cursor-pointer" htmlFor="site">
                Site
              </label>
              <input
                id="site"
                name="site"
                value={formik.values.site}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                required
              />
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <label
                className="small-text bold cursor-pointer"
                htmlFor="status"
              >
                Statut
              </label>
              <select
                id="status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                className="default-input"
                required
              >
                {Object.keys(managerType).map((m, i) => {
                  return (
                    <option key={i} value={m}>
                      {managerType[m]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 d-flex flex-row gap-3 align-items-end">
              <div className="w-100 d-flex flex-column gap-3">
                {searchDataManagers.length > 0 && (
                  <div
                    className="close-search-bg"
                    onClick={onCloseSearchManagers}
                  ></div>
                )}
                <label className="small-text bold" htmlFor="member">
                  WOW team member(s)
                </label>
                <div className="w-100 d-flex flex-row align-items-center gap-2">
                  {/* KEYWORD */}
                  <div className="w-100 position-relative">
                    <FiSearch className={style["search-icon"]} />
                    <input
                      type="text"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          searchManager(e);
                        } else {
                          onCloseSearchManagers();
                        }
                      }}
                      className={style["search-input"] + " default-input"}
                      placeholder="Rechercher un membre de l'équipe"
                    />
                    <div className="search-content">
                      {searchDataManagers.length > 0 &&
                        searchDataManagers.map((d, i) => (
                          <div
                            key={i}
                            className={style.hoverManager}
                            onClick={(e) => onAddManager(d)}
                          >
                            {d.firstName} {d.lastName}
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <div>
                <button
                  type="button"
                  className="default-btn"
                  onClick={addMember}
                >
                  <FiPlus />
                </button>
              </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-row gap-3 flex-wrap">
              <div className="w-100 d-flex flex-row flex-wrap gap-3">
                {managers.map((s, i) => (
                  <span key={i} className={style["skill"]}>
                    {s.firstName} {s.lastName}
                    <FiX
                      className={style["delete-skill"]}
                      onClick={(event) => onDeleteManager(s)}
                    />
                  </span>
                ))}
              </div>
            </div>
            <div className="col-12 d-flex flex-row gap-3 flex-wrap">
              <CustomCheckbox
                onChange={formik.handleChange}
                value={formik.values.shouldReceiveTimesheet}
                name="shouldReceiveTimesheet"
                label="Envoi du CRA"
              />
              {formik.values.shouldReceiveTimesheet && (
                <span className="bold">
                  Attention, en cochant cette case, le manager recevra le CRA
                  par mail.
                </span>
              )}
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <div className="small-text bold" htmlFor="comments">
                Informations sur le contact
              </div>
              <textarea
                id="comments"
                name="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                className="text-area default-input"
                type="text"
                required
              />
            </div>
            <div className="col-12 d-flex flex-column gap-2">
              <div className="small-text bold" htmlFor="notes">
                Notes
              </div>
              <textarea
                id="notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                className="text-area default-input"
                type="text"
                required
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div>
                <button type="submit" className="default-btn">
                  Sauvegarder les modifications
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default ModifierManager;
