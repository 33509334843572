import React from "react";
import style from "./style.module.css";
import { FiCalendar, FiTag, FiMapPin, FiPhone, FiMail } from "react-icons/fi";
import { getUrlAdminConsultant } from "../../../config";

const ConsultantRowCard = ({ consultant }) => {

  const openConsultantIdentite = () => {
    if(consultant?.id) {
      const url = getUrlAdminConsultant() + consultant?.id
      window.open(url, "_blank");
    }
  };

  return (
    <div className={style['block-a']} style={{borderColor:consultant.rank === 1 && 'var(--primary-blue)', cursor: !consultant.isFromRcrm ? 'pointer' : 'default'}} onClick={!consultant.isFromRcrm ? openConsultantIdentite : null}>
      <div className={`d-flex flex-column gap-3`}>
        <div className={'d-flex flex-row'}  style={{justifyContent:'space-between'}}>
          <span className={style['title']}>{`${consultant.firstName} ${consultant.lastName}`}</span>
          <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}><span className={`${style['rank']} text-center`} style={{backgroundColor:consultant.rank === 1 && 'var(--primary-blue)'}}>{consultant.rank}</span></div>
        </div>
        <div className={`gap-2 ${style['grid-container']}`}>
              <div className={style['a']}><FiMail/> {consultant.email}</div> 
              <div className={style['b']}><span style={{width:'16px',height:'16px'}}>€</span> {consultant.salaryExpectation}</div>
              <div className={style['c']}><FiMapPin/> {consultant.city}</div>
              <div className={style['d']}><FiPhone/> {consultant.phoneNumber}</div>
              <div className={style['e']}><FiCalendar/> {consultant.unavailabilityTerm && new Date(consultant.unavailabilityTerm).toLocaleDateString()}</div>
              <div className={style['f']}><FiTag/> {consultant.isFromRcrm ? 'RCRM' : 'WOW'}</div>
        </div>
        <div className="d-flex flex-wrap gap-2">
                {consultant?.consultantSkillMarks?.map((csm, i) => {
                  return (
                    <div style={{backgroundColor:'var(--primary-blue)',color:'white', borderRadius:'5px', padding:'6px', fontWeight:500}} key={i}>
                      {csm.skill.label}
                    </div>
                  );
                })}
              </div>
      </div>
    </div>
  );
};

export default ConsultantRowCard;
