import { FiUser } from "react-icons/fi";
import { b64ToObjectUrl } from "../../Helpers/base64-decode";

const ProfileLogo = ({ profilePicture }) => {
  const fiUser = <FiUser className="header-1" />;
  return profilePicture.base64Document ? (
    <img
      style={{ height: "100%", width: "100%", borderRadius: "50%" }}
      alt="profile logo"
      src={b64ToObjectUrl(
        profilePicture.base64Document,
        profilePicture.filetype
      )}
    />
  ) : (
    fiUser
  );
};

export default ProfileLogo;
