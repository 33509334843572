import { useState } from "react";
import CustomCheckbox from "../../../../Components/CustomCheckbox";
import { UploadFileInput } from "../../../../Components/UploadFileInput";
import { b64ToObjectUrl } from "../../../../Helpers/base64-decode";
import toast from "../../../../Helpers/toast";
import { updateFile } from "../../../../Services/Consultant";
import {
  createAnAdminContract,
  updateAnAdminContract,
  UploadAdminContractFile,
} from "../../../../Services/ContratAdmin";

export const PopupContratCadre = ({
  setOpen,
  societyId,
  setCadresHandler,
  currentContratToUpdate,
}) => {
  const checkboxTitle =
    "WheelOfWork et cette entreprise n’ont pas de contrat cadre";
  const checkboxName = "isFirst";

  const [isFirst, setIsFirst] = useState(
    currentContratToUpdate ? currentContratToUpdate.isFirst : true
  );
  const onChangeCheckboxValue = () => {
    setIsFirst((oldValue) => !oldValue);
  };

  const [contractFile, setContractFile] = useState();

  const [isMissingFileValidation, setIsMissingFileValidation] = useState(
    currentContratToUpdate ? false : true
  );
  const onChangeFileHandler = (e) => {
    if (e.target.files[0]) {
      setContractFile(e.target.files[0]);
      setIsMissingFileValidation(false);
    } else {
      setIsMissingFileValidation(true);
    }
  };

  const [contractReference, setContractReference] = useState(
    currentContratToUpdate ? currentContratToUpdate.reference : ""
  );

  const [signatureDate, setSignatureDate] = useState(
    currentContratToUpdate && currentContratToUpdate.signatureDate
      ? currentContratToUpdate.signatureDate.split("T")[0]
      : ""
  );

  const [startDate, setStartDate] = useState(
    currentContratToUpdate
      ? currentContratToUpdate.startDate.split("T")[0]
      : null
  );
  const [endDate, setEndDate] = useState(
    currentContratToUpdate
      ? currentContratToUpdate.expirationDate.split("T")[0]
      : null
  );

  const onChangeInputHandler = (index, e) => {
    if (index === 0) {
      setContractReference(e.target.value);
    } else if (index === 1) {
      setStartDate(e.target.value);
    } else if (index === 2) {
      setEndDate(e.target.value);
    } else if (index === 3) {
      setSignatureDate(e.target.value);
    }
  };

  const submitFormAddContract = (e) => {
    e.preventDefault();
    if (!contractFile) {
      setIsMissingFileValidation(true);
      return;
    }
    const contractToSend = {
      startDate,
      signatureDate,
      expirationDate: endDate,
      type: "CADRE",
      society: { id: societyId },
      reference: contractReference,
      isFirst,
    };

    createAnAdminContract(contractToSend).then((savedContract) => {
      const contractId = savedContract.data.id;

      const formdata = new FormData();
      formdata.append("file", contractFile);
      UploadAdminContractFile(formdata, "CADRE", {
        contractId: contractId,
        societyId,
      }).then((savedDocument) => {
        setOpen(false);
        toast("success", "Fichier uploadé");
        setCadresHandler((oldState) => {
          const contractToPush = {
            ...savedContract.data,
            file: savedDocument.data,
          };
          return [...oldState, contractToPush];
        });
      });
    });
  };

  const submitFormUpdateContract = (e) => {
    e.preventDefault();

    const contractToSend = {
      startDate,
      signatureDate,
      expirationDate: endDate,
      type: "CADRE",
      society: { id: societyId },
      reference: contractReference,
      isFirst,
      status: currentContratToUpdate.status,
      id: currentContratToUpdate.id,
    };

    updateAnAdminContract(contractToSend).then(() => {
      setOpen(false);
      toast("success", "Contrat modifié");
      setCadresHandler((oldState) => {
        const index = oldState.findIndex(
          (c) => c.id === currentContratToUpdate.id
        );
        contractToSend.file = currentContratToUpdate.file;
        oldState[index] = contractToSend;
        return [...oldState];
      });
    });

    if (contractFile) {
      const formdata = new FormData();
      formdata.append("file", contractFile);
      updateFile(
        "admin_contract",
        currentContratToUpdate.file._id,
        formdata
      ).then((res) => {
        setCadresHandler((oldState) => {
          const index = oldState.findIndex(
            (c) => c.id === currentContratToUpdate.id
          );
          contractToSend.file = res.data;
          oldState[index] = contractToSend;
          return [...oldState];
        });
      });
    }
  };
  return (
    <form
      className="row justify-content-between p-3 py-4 gap-4"
      onSubmit={
        currentContratToUpdate
          ? submitFormUpdateContract
          : submitFormAddContract
      }
    >
      <div className="col-12 wow-subtitle bold">
        {currentContratToUpdate ? "Modifier" : "Ajouter"} un contrat cadre
      </div>
      <CustomCheckbox
        label={checkboxTitle}
        value={isFirst}
        name={checkboxName}
        onChange={onChangeCheckboxValue}
        readOnly={false}
      ></CustomCheckbox>
      {/* {currentContratToUpdate && (
        <a
          className="small-text blue-text d-none d-md-flex cursor-pointer"
          href={
            currentContratToUpdate?.file &&
            b64ToObjectUrl(
              currentContratToUpdate.file.base64Document,
              currentContratToUpdate.file.filetype
            )
          }
          target="_blank"
          rel="noreferrer"
        >
          Visualiser le contrat à remplacer
        </a>
      )} */}
      {!currentContratToUpdate && (
        <UploadFileInput
          file={contractFile}
          unknownFile={currentContratToUpdate ? "" : "Aucun contrat cadre"}
          onChangeFile={onChangeFileHandler}
          buttonTitle={
            currentContratToUpdate
              ? "Remplacer le contrat cadre"
              : "Télécharger un contrat cadre"
          }
          uploadTitle={
            currentContratToUpdate
              ? "Remplacer le contrat cadre"
              : "Uploader un contrat"
          }
        />
      )}
      {isMissingFileValidation && (
        <span className={"small-text bold"} style={{ color: "red" }}>
          Veuillez ajouter un contrat cadre *
        </span>
      )}
      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="label">
          Reference du contrat
        </label>
        <input
          id="reference"
          name="reference"
          className="default-input"
          type="text"
          value={contractReference}
          onChange={(e) => onChangeInputHandler(0, e)}
          required
        />
      </div>
      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de signature
        </label>
        <input
          id="signatureDate"
          name="signatureDate"
          className="default-input"
          type="date"
          value={signatureDate}
          onChange={(e) => onChangeInputHandler(3, e)}
          required
        />
      </div>
      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de début du contrat
        </label>
        <input
          id="startDate"
          name="startDate"
          className="default-input"
          type="date"
          value={startDate || ""}
          onChange={(e) => onChangeInputHandler(1, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de fin du contrat
        </label>
        <input
          id="endDate"
          name="endDate"
          className="default-input"
          type="date"
          value={endDate || ""}
          onChange={(e) => onChangeInputHandler(2, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-row justify-content-center gap-4">
        <div>
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setOpen(false)}
          >
            Annuler
          </button>
        </div>
        <div>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </div>
    </form>
  );
};
