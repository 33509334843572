import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminCard from "../../Components/AdminCard";
import Loader from "../../Components/Loader/index";
import Popup from "../../Components/Popup";
import SearchBar from "../../Components/SearchBar/index";
import { getRoles } from "../../Services/Dashboard";
import { deleteUser, getAdminUsers, updateUser } from "../../Services/User";

const CreateAdmin = () => {
  const [loading, setLoading] = useState(false);

  const [admins, setAdmins] = useState([]);
  const [activateModal, setActivateModal] = useState(false);
  const [toBeActivatedAdmin, setToBeActivatedAdmin] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [toBeDeletedAdmin, setToBeDeletedAdmin] = useState(null);
  const [deletedAdmins, setDeletedAdmins] = useState([]);
  const [search, setSearch] = useState("");

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAdminUsers()
      .then((res) => {
        const allAdmins = res.data;
        let tempAdmins = [];
        let tempDeletedAdmins = [];

        allAdmins.forEach((admin) => {
          if (admin.active) tempAdmins.push(admin);
          else tempDeletedAdmins.push(admin);
        });
        setAdmins(tempAdmins);
        setDeletedAdmins(tempDeletedAdmins);
      })
      .catch((e) => setLoading(false))
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getRoles().then((res) => setRoles(res.data));
  }, []);

  const startDeleting = (admin) => {
    setDeleteModal(true);
    setToBeDeletedAdmin(admin);
  };

  const spliceAdminArray = (currentAdmins, id) => {
    currentAdmins.forEach((adm, i) => {
      if (adm.id === id) currentAdmins.splice(i, 1);
    });
  };

  const deleteAdmin = (admin) => {
    deleteUser(admin.id)
      .then((res) => {
        setDeleteModal(false);
        let currentAdmins = [...admins];

        spliceAdminArray(currentAdmins, admin.id);
        setAdmins(currentAdmins);

        let tempAdmins = [...deletedAdmins];
        tempAdmins.push(admin);
        setDeletedAdmins(tempAdmins);
      })
      .catch((e) => setLoading(false))
      .then(() => {
        setLoading(false);
      });
  };

  const startActivating = (admin) => {
    setActivateModal(true);
    setToBeActivatedAdmin(admin);
  };

  const activateAdmin = (admin) => {
    let adminToSend = { ...admin };
    adminToSend.active = true;
    delete adminToSend.fullName;

    updateUser(adminToSend.id, adminToSend)
      .then((res) => {
        setActivateModal(false);
        let currentAdmins = [...deletedAdmins];
        spliceAdminArray(currentAdmins, admin.id);
        setDeletedAdmins(currentAdmins);

        let tempAdmins = [...admins];
        tempAdmins.push(admin);
        setAdmins(tempAdmins);
      })
      .catch((e) => setLoading(false))
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container py-4 px-5">
      <Popup
        show={deleteModal}
        size="sm"
        onClose={(e) => setDeleteModal(false)}
      >
        <div className="row justify-content-between p-3 py-4 gap-4">
          <div className="col-12 wow-subtitle bold">
            Êtes-vous sûr de vouloir supprimer cet admin ?
          </div>
          <div className="col-5">
            <button
              className="default-btn danger-btn p-2"
              onClick={(e) => setDeleteModal(false)}
            >
              Annuler
            </button>
          </div>
          <div className="col-5">
            <button
              type="button"
              className="default-btn p-2"
              onClick={() => deleteAdmin(toBeDeletedAdmin)}
            >
              Confirmer
            </button>
          </div>
        </div>
      </Popup>

      <Popup
        show={activateModal}
        size="sm"
        onClose={(e) => setDeleteModal(false)}
      >
        <div className="row justify-content-between p-3 py-4 gap-4">
          <div className="col-12 wow-subtitle bold">
            Êtes-vous sûr de vouloir réactiver cet admin ?
          </div>
          <div className="col-5">
            <button
              className="default-btn danger-btn p-2"
              onClick={(e) => setActivateModal(false)}
            >
              Annuler
            </button>
          </div>
          <div className="col-5">
            <button
              type="button"
              className="default-btn p-2"
              onClick={() => activateAdmin(toBeActivatedAdmin)}
            >
              Confirmer
            </button>
          </div>
        </div>
      </Popup>

      <div className="row d-flex flex-column gap-5 px-4 mb-4">
        {/* TODO */}
        <SearchBar onSubmit={setSearch} />
        <div className="col-12 d-flex flex-row justify-content-between">
          <div className="header-4">Tous les admins</div>
          <Link to="/create-admin/add">
            <button className="default-btn">Ajouter un admin</button>
          </Link>
        </div>
      </div>
      <div className="row px-4">
        <div className="col-12 d-flex flex-column gap-3 align-items-center">
          {loading ? (
            <div
              className="w-100 position-relative card"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : admins.length ? (
            <>
              {roles?.length > 0 &&
                admins
                  .filter((admin) => {
                    return (
                      admin.firstName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      (admin.firstName + " " + admin.lastName)
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      admin.lastName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      admin.wowOffice
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                    );
                  })
                  .map((adm, i) => {
                    return (
                      <AdminCard
                        admin={adm}
                        key={i}
                        deleteAdmin={() => startDeleting(adm)}
                        roles={roles}
                      />
                    );
                  })}
            </>
          ) : (
            <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
              Aucuns admins
            </div>
          )}
        </div>
      </div>
      <div className="row px-4" style={{ marginTop: "20px" }}>
        <div className="col-12 d-flex flex-column gap-3 align-items-center">
          {loading ? (
            <div
              className="w-100 position-relative card"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : admins.length ? (
            <>
              {roles?.length > 0 &&
                deletedAdmins
                  .filter((admin) => {
                    return (
                      admin.firstName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      (admin.firstName + " " + admin.lastName)
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      admin.lastName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                      admin.wowOffice
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                    );
                  })
                  .map((adm, i) => {
                    return (
                      <AdminCard
                        admin={adm}
                        key={i}
                        activateAdmin={() => startActivating(adm)}
                        deactivated
                        roles={roles}
                      />
                    );
                  })}
            </>
          ) : (
            <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
              Aucuns admin désactivé
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
