import StarterFinisherCard from "../../../../../UI/StarterFinisherCard";
import { Card } from "../../../../../UI/Card";
import style from "./style.module.css";

const StarterFinisherDashboard = ({ title, list, children }) => {
    const noResult = 'Pas de résultat pour cette section';

    return (<Card classes={'my-4 mx-auto'}>
        
        <h5 className="green-text">{title}</h5>
        {children}
        <div className={style['list']}  style={{paddingTop:"0.5rem"}}>
            {list && list.map((l, index) => <StarterFinisherCard key={index} consultantId={l.consultant.id} consultantFullName={l.consultant.user.fullName} missionTitle={l.mission} societyTitle={l.society} />)}
            {(!list || list.length === 0) && <p className="m-auto">{noResult}</p>}
        </div>

    </Card>);
}

export default StarterFinisherDashboard;