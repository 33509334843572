import styles from './style.module.css';

export const Card = ({ classes, children, onClick }) => {

    const onClickHandler = (event) => {
        if (onClick) {
            onClick(event);
        }
    }

    let classNames = `${styles['container']}`;
    if (classes) {
        classNames = `${classNames} ${classes}`
    }
    return <div className={classNames} onClick={onClickHandler}>{children}</div>
}