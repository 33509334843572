import React, { useCallback } from "react";
import {
  FiBriefcase,
  FiChevronDown,
  FiFlag,
  FiHelpCircle,
  FiHome,
  FiSettings,
  FiStar,
  FiUsers,
} from "react-icons/fi";
import { GiSkills, GiBilledCap } from "react-icons/gi";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import style from "./style.module.css";

const Menu = () => {
  const pathname = useLocation().pathname;
  const open = useSelector((state) => state.menu.open);
  const dispatch = useDispatch();
  const helpLink =
    "https://www.notion.so/wheelofwork/Support-ADMIN-3d459eb365a440998c8f74f7f5e4fcc4";
  const toggleOpenMenu = useCallback(
    (e) => {
      dispatch({ type: "menu/TOGGLE_OPEN" });
    },
    [dispatch]
  );
  const ToggleAccordion = (e) => {
    const sibling = e.target.nextElementSibling;
    if (sibling) {
      if (sibling.style.maxHeight && sibling.style.maxHeight !== "0px") {
        sibling.style.maxHeight = null;
      } else {
        sibling.style.maxHeight = sibling.scrollHeight + "px";
      }
    }
  };

  const routeToHelp = (e) => {
    e.preventDefault();
    window.open(helpLink);
  };

  return (
    <div
      className={`${style["menu"]} d-flex flex-column ${
        open ? style["open"] : ""
      }`}
    >
      {open ? (
        <>
          <NavLink
            to="/"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <FiHome className="header-4" />
              <div className="small-text">Dashboard</div>
            </div>
          </NavLink>

          <div className="w-100 d-flex flex-column">
            <div
              className={`${style["menu-primary-item"]} cursor-pointer ${
                pathname.split("/").includes("missions") ? "green-text" : ""
              }`}
              onClick={ToggleAccordion}
            >
              <div className="d-flex gap-3 align-items-center">
                <FiFlag className="header-4" />
                <div className="small-text">Missions</div>
              </div>
              <FiChevronDown className="wow-body" />
            </div>
            <div className={style["menu-accordion"]}>
              <div className={style["menu-accordion-content"]}>
                <NavLink
                  to="/missions/mes-missions"
                  exact
                  className={`${style["menu-secondary-item"]}`}
                  activeClassName={style["active"]}
                >
                  <div className="small-text">Mes missions</div>
                </NavLink>
                <NavLink
                  to="/missions/toutes-les-missions"
                  exact
                  className={`${style["menu-secondary-item"]}`}
                  activeClassName={style["active"]}
                >
                  <div className="small-text">Toutes les missions</div>
                </NavLink>
              </div>
            </div>
          </div>
          <NavLink
            to="/clients/comptes"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <FiBriefcase className="header-4" />
              <div className="small-text">Clients</div>
            </div>
          </NavLink>
          <NavLink
            to="/consultants"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <FiUsers className="header-4" />
              <div className="small-text">Consultants</div>
            </div>
          </NavLink>
          <NavLink
            to="/matching"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <FiStar className="header-4" />
              <div className="small-text">Matching</div>
            </div>
          </NavLink>
          <NavLink
            to="/skills"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <GiSkills className="header-4" />
              <div className="small-text">Compétences</div>
            </div>
          </NavLink>
          <div className="w-100 d-flex flex-column">
            <div
              className={`${style["menu-primary-item"]} cursor-pointer ${
                pathname.split("/").includes("jobs") ||
                pathname.split("/").includes("job-departments")
                  ? "green-text"
                  : ""
              }`}
              onClick={ToggleAccordion}
            >
              <div className="d-flex gap-3 align-items-center">
                <GiBilledCap className="header-4" />
                <div className="small-text">Métiers</div>
              </div>
              <FiChevronDown className="wow-body" />
            </div>
            <div className={style["menu-accordion"]}>
              <div className={style["menu-accordion-content"]}>
                <NavLink
                  to="/jobs"
                  exact
                  className={`${style["menu-secondary-item"]}`}
                  activeClassName={style["active"]}
                >
                  <div className="small-text">Métiers</div>
                </NavLink>
                <NavLink
                  to="/job-departments"
                  exact
                  className={`${style["menu-secondary-item"]}`}
                  activeClassName={style["active"]}
                >
                  <div className="small-text">Catégories</div>
                </NavLink>
              </div>
            </div>
          </div>
          <NavLink
            to="/parametres"
            exact
            className={`${style["menu-primary-item"]}`}
            activeClassName="green-text"
          >
            <div className="d-flex gap-3 align-items-center">
              <FiSettings className="header-4" />
              <div className="small-text">Paramètres</div>
            </div>
          </NavLink>
          <div
            className={`${style["menu-primary-item"]} cursor-pointer`}
            onClick={routeToHelp}
          >
            <div className="d-flex gap-3 align-items-center">
              <FiHelpCircle className="header-4" />
              <div className="small-text">Aide</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NavLink
            to="/"
            exact
            className={`${style["closed-menu-item"]}`}
            activeClassName={`green-text ${style["active"]}`}
          >
            <FiHome className="header-4" />
          </NavLink>
          <div
            className={`${style["closed-menu-item"]} ${
              pathname.split("/").includes("consultants-en-mission")
                ? style["active"]
                : ""
            }`}
            onClick={toggleOpenMenu}
          >
            <FiUsers className="header-4" />
          </div>
          <div
            className={`${style["closed-menu-item"]} ${
              pathname.split("/").includes("starters") ? style["active"] : ""
            }`}
            onClick={toggleOpenMenu}
          >
            <FiHome className="header-4" />
          </div>
          <div
            className={`${style["closed-menu-item"]} ${
              pathname.split("/").includes("clients") ? style["active"] : ""
            }`}
            onClick={toggleOpenMenu}
          >
            <FiBriefcase className="header-4" />
          </div>
          <div
            className={`${style["closed-menu-item"]} ${
              pathname.split("/").includes("consultants") ? style["active"] : ""
            }`}
            onClick={toggleOpenMenu}
          >
            <FiUsers className="header-4" />
          </div>
          <NavLink
            to="/parametres"
            exact
            className={`${style["closed-menu-item"]}`}
            activeClassName={`green-text ${style["active"]}`}
          >
            <FiSettings className="header-4" />
          </NavLink>
          <div className={`${style["closed-menu-item"]}`}>
            <FiHelpCircle className="header-4" />
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
