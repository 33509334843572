import React, { useState, useRef } from "react";
import style from "./style.module.css";
import { FiCalendar, FiTag, FiMapPin, FiSettings, FiBriefcase, FiUsers, FiUser, FiEye, FiEyeOff } from "react-icons/fi";
import DropDownMenu from "../../Menu/DropDownMenu";

const MissionRowCard = ({ mission, missionClick, editMissionCallback }) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const fiSettingsRef = useRef();


  const restore = () => {
    editMissionCallback({...mission, isArchived: false})
    setIsDropDownVisible(false);
  }
  
  const archive = () => {
    editMissionCallback({...mission, isArchived: true})
    setIsDropDownVisible(false);
  }
  const showOrHideConsultant = () => {
    const newValue = {...mission, isHidden: !mission.isHidden};
    editMissionCallback(newValue)
    setIsDropDownVisible(false);
  }
  const showOrHideCustomer = () => {
    editMissionCallback({...mission, isConfidential: !mission.isConfidential})
    setIsDropDownVisible(false);
  }

  const handleSettingsClick = (e) => {
    e.stopPropagation(); 
    setIsDropDownVisible(!isDropDownVisible);
  };

  const menuOptions = [
    {label: !mission.isHidden ? 'Masquer (Consultant)' : 'Afficher (Consultant)', callback: showOrHideConsultant},
    {label: !mission.isConfidential ? 'Masquer (Client)' : 'Afficher (Client)', callback: showOrHideCustomer}
  ]

  if(mission.isArchived){
    menuOptions.push(
      {label:'Restaurer', callback: restore}
    )
  }
  else if(!(mission?.consultant)){
    menuOptions.push(
      {label:'Archiver', callback: archive}
    )
  }

  return (
    <div className={style['block-a']} onClick={() => (missionClick(mission))}>
      <div className={`d-flex flex-column gap-3`}>
        <div className={'d-flex flex-row align-items-start'}  style={{justifyContent:'space-between', overflow: 'hidden', height: 'auto'}}>
          <div className="d-flex flex-column">
            <div><span className={style['title']}>{mission.label}</span><span className={style['secondary-title']}>{mission?.society?.label && ` - ${mission?.society?.label}`}</span></div>
            <div className={style['subtitle']}>{mission.createdAt && new Date(mission.createdAt).toLocaleDateString()}</div>
          </div>
          <div className={`d-flex flex-row gap-1 align-items-center`}>
            {mission.isHidden ? 
            <FiEyeOff/> : 
            <FiEye/>
            }
            <div style={{paddingLeft:'8px'}} ref={fiSettingsRef} onClick={(e) => {handleSettingsClick(e)}}>
              <FiSettings
                className={style["rotate-icon"]}/>
            </div>
          </div>
        </div>
        { isDropDownVisible && (
          <DropDownMenu 
            elementRef={fiSettingsRef}
            menuOptions={menuOptions}
            closeCallback={() => (setIsDropDownVisible(false))}
          />
        )}

        <div className={`gap-2 ${style['grid-container']}`}>
          {mission?.consultant ? 
              <div className={style['a']}>
                <span style={{paddingRight:4}}><FiUser/></span>
                <span>{mission?.consultant?.user?.fullName}</span>
              </div>
             : 
              <div className={style['a']}>
                <span style={{paddingRight:4}}><FiUsers/></span>
                <span>{mission?.applications?.length > 0 ? `${mission?.applications?.length} candidats` : `Aucun candidat`}</span>
              </div>
          }
          <div className={style['b']}><span style={{paddingRight:4}}><FiTag/></span><span>{mission.ref}</span></div>
          <div className={style['c']}><span style={{paddingRight:4, width:'16px',height:'16px'}}>€</span><span>{mission.dailyAverage}</span></div>
          <div className={style['d']}><span style={{paddingRight:4}}><FiCalendar/></span><span>Débute le {mission.startDate && new Date(mission.startDate).toLocaleDateString()}</span></div>
          <div className={style['e']}><span style={{paddingRight:4}}><FiMapPin/></span><span>{mission.address.city}</span></div>
          {mission.isConfidential &&
            <div className={style['f']}><span style={{paddingRight:4}}><FiBriefcase/></span><span>Confidentielle</span></div>
          }
        </div>
        <div className="d-flex flex-wrap gap-2" style={{overflow: "hidden", width: '100%', height:'36px'}}>
          {mission?.skills?.map((s, i) => {
            return (
              <div style={{whiteSpace: 'nowrap',backgroundColor:'var(--primary-blue)',color:'white', borderRadius:'5px', padding:'6px', fontWeight:500}} key={i}>
                {s.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MissionRowCard;
