import React, { useCallback, useEffect, useState } from "react";
import ListItem from "../ListItem";
import style from "../style.module.css";
import {
  RejectConsultantFile,
  UploadProfileFiles,
  ApproveConsultantFile,
} from "./../../../Services/Consultant";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../Redux/Admin/actions";
import toast from "../../../Helpers/toast";

const SocietyTable = () => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [files, setFiles] = useState([]);
  const updateConsultantDocuments = useCallback(
    (action, documents) => {
      dispatch({
        type: action,
        payload: documents,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentConsultant) {
      setFiles(currentConsultant.documents);
    }
  }, [currentConsultant]);

  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    const title = e.target.title;
    const isIdCard = name === "ID_FRONT" || name === "ID_BACK";
    const args = isIdCard
      ? {
          consultantId: currentConsultant.id,
          status: "PENDING",
          side: name === "ID_FRONT" ? "front" : "back",
        }
      : {
          societyId: currentConsultant.society.id,
          consultantId: currentConsultant.id,
          status: "PENDING",
          legalFileType: name,
          filelabel: title,
        };
    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      UploadProfileFiles(
        formdata,
        isIdCard ? "id_card" : "legal_files",
        args
      ).then((res) => {
        updateConsultantDocuments(
          isIdCard
            ? userActions.SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS
            : userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS,
          res.data
        );
        toast("success", "Fichier ajouté avec succès !");
      });
    }
  };

  const onReject = (id, type) => {
    const isIdCard = type === "front" || type === "back";

    return RejectConsultantFile(
      id,
      currentConsultant.id,
      isIdCard ? "id_card" : "legal_files"
    ).then((res) => {
      updateConsultantDocuments(
        isIdCard
          ? userActions.SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS
          : userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS,
        res.data
      );
      toast("success", "Le fichier a été rejeté !");
    });
  };

  const onApprove = (id, name, date) => {
    const isIdCard = name === "front" || name === "back";
    return ApproveConsultantFile(
      id,
      currentConsultant.id,
      isIdCard ? "id_card" : "legal_files",
      new Date(date)
    ).then((res) => {
      updateConsultantDocuments(
        isIdCard
          ? userActions.SET_CURRENT_CONSULTANT_IDENTITY_DOCUMENTS
          : userActions.SET_CURRENT_CONSULTANT_LEGAL_FILES_DOCUMENTS,
        res.data
      );
      toast("success", "Le fichier a été approuvée !");
    });
  };

  const legalFiles = files.find((doc) => doc.type === "legal_files")?.documents;
  const idCardFiles = files.find((doc) => doc.type === "id_card")?.documents;

  const ID_FRONT = idCardFiles?.find((doc) => doc.type === "front");
  const ID_BACK = idCardFiles?.find((doc) => doc.type === "back");
  const RIB = legalFiles?.find(
    (doc) => doc.type === "bank_account_informations"
  );
  const KBIS = legalFiles?.find((doc) => doc.type === "kbis");
  const URSSAFF = legalFiles?.find((doc) => doc.type === "urssaf");
  const ASSURANCE_RESPO_CIVILE = legalFiles?.find(
    (doc) => doc.type === "liability_insurance"
  );
  const FISCALE = legalFiles?.find((doc) => doc.type === "tax_certificate");
  return (
    <div className={style["documents-table"]}>
      <div
        className={`${style["documents-table-item"]} ${style["documents-table-header"]}`}
      >
        <div className="small-text">Type Fichier</div>
        <div className="small-text d-none d-md-flex">
          Date de téléchargement
        </div>
        <div className="small-text d-none d-md-flex">Date d'expiration</div>
        <div className="small-text d-none d-md-flex">Fichier</div>
        <div className="small-text">Statut</div>
        <div className="small-text text-center">Actions</div>
      </div>
      <ListItem
        title="Pièce d'identité recto"
        type="id_card"
        name="ID_FRONT"
        file={ID_FRONT}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="Pièce d'identité verso"
        type="id_card"
        name="ID_BACK"
        file={ID_BACK}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="RIB"
        type="legal_files"
        name="RIB"
        file={RIB}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="Extrait de Kbis"
        type="legal_files"
        name="kbis"
        file={KBIS}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="Attestation de vigilance URSSAF"
        type="legal_files"
        name="urssaf"
        file={URSSAFF}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="Attestation de souscription à une assurance de responsabilité civile professionnelle"
        type="legal_files"
        name="ASSURANCE_RESPO_CIVILE"
        file={ASSURANCE_RESPO_CIVILE}
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
      <ListItem
        title="Attestation de régularité fiscale"
        type="legal_files"
        file={FISCALE}
        name="tax_certificate"
        onUploadFile={onUploadFile}
        onReject={onReject}
        onApprove={onApprove}
      />
    </div>
  );
};

export default SocietyTable;
