const getBaseUrl = (envVar, windowVar) => {
    if (typeof process !== 'undefined' && process.env && process.env[envVar]) {
        return process.env[envVar];
    }
    return new Promise((resolve) => {
        if (window[windowVar]) {
            resolve(window[windowVar]);
        } else {
            window.addEventListener('load', () => {
                resolve(window[windowVar] || '');
            });
        }
    });
};

export const getBaseUrlConsultant = () => getBaseUrl('REACT_APP_BASE_URL_CONSULTANT', 'BASE_URL_CONSULTANT');
export const getBaseUrlAdmin = () => getBaseUrl('REACT_APP_BASE_URL_ADMIN', 'BASE_URL_ADMIN');

export const getUrlAdminConsultant = async () => {
    const baseUrl = await getBaseUrlAdmin();
    return `${baseUrl}/consultant/`;
};

export const getUrlAdminMission = async () => {
    const baseUrl = await getBaseUrlAdmin();
    return `${baseUrl}/mission/`;
};

export const getUrlAdminClient = async () => {
    const baseUrl = await getBaseUrlAdmin();
    return `${baseUrl}/client/`;
};

export const routeTo = (baseUrl, id) => {
    window.open(baseUrl + id);
}