import styles from "./style.module.css";

export const SkillMarks = ({ category, skillMarks }) => {
  return (
    <table className={styles["table"]}>
      <tr>
        <th>Compétence</th>
        <th>Niveau</th>
      </tr>
      {(skillMarks || []).map((skillMark, index) => (
        <tr className="small-text" key={index}>
          <td>{skillMark?.skill?.label ?? skillMark?.label}</td>
          <td>{skillMark.level ?? '-'}</td>
        </tr>
      ))}
    </table>
  );
};
