import { useState } from "react";
import { ProtectedApi } from "../../Helpers/api";

export const CronMenu = () => {
  const cronsArray = [
    {
      label: "1er rappel",
      description: "1er rappel d'envoi de mail de saisies de CRA",
      callBackUrl: "/cron/timesheet_first_scheduler",
      isTriggered: false,
    },
    {
      label: "2nd rappel",
      description: "2nd rappel d'envoi de mail de saisies de CRA",
      callBackUrl: "/cron/timesheet_second_scheduler",
      isTriggered: false,
    },
    {
      label: "Upload facturation",
      description: "Rappel d'envoi de la facture",
      callBackUrl: "/cron/invoice_scheduler",
      isTriggered: false,
    },
    {
      label: "Payment",
      description: "Paiement des factures validées",
      callBackUrl: "/cron/invoice_scheduler_payment",
      isTriggered: false,
    },
    {
      label: "BDC/Applications",
      description: "Mise à jour des status des contracts en fonction des dates",
      callBackUrl: "/cron/admin_contracts",
      isTriggered: false,
    },
    {
      label: "Contract / finisher",
      description:
        "Mise à jour du statut du contrat en fonction de la date et des finishers",
      callBackUrl: "/cron/contract_scheduler",
      isTriggered: false,
    },
    {
      label: "Contract / finisher",
      description:
        "Mise à joujr des documents légaux",
      callBackUrl: "/cron/legal_files_scheduler",
      isTriggered: false,
    },
    {
      label: "who is concerned end of month",
      description: "Récupérer tous les consultants concernés par la fin de mois",
      callBackUrl: "/cron/who-is-end-of-month",
      isTriggered: false,
    },
    {
      label: "Log details",
      description: "Log in prod the jobs details",
      callBackUrl: "/cron/cron-details",
      isTriggered: false,
    },
  ];

  const [crons, setCrons] = useState(cronsArray);
  const [retrievedData, setRetrievedData] = useState([]);
  const callCronApi = (url) => {
    return ProtectedApi.get(url);
  };

  const cronHandler = (index, callBackUrl) => {
    callCronApi(callBackUrl).then((res) => {
      if (res?.data) {
        setRetrievedData(res.data);
      }
      setCrons((oldCrons) => {
        const oldCronsHistory = [...oldCrons];
        oldCronsHistory[index].isTriggered = true;
        return [...oldCronsHistory];
      });
    });
  };

  return (
    <div
      style={{
        width: "50%",
        height: "100vh",
        margin: "auto",
        textAlign: "center",
      }}
    >
      {crons.map((cron, index) => (
        <div style={{ padding: "15px", margin: "20px" }} key={index}>
          <div style={{ marginBottom: "15px" }}>
            <label className="bold">{cron.description}</label>{" "}
            {cron.isTriggered && (
              <span style={{ color: "red", paddingLeft: "20px" }}>
                TRIGGERED !{" "}
              </span>
            )}
          </div>

          <div
            style={{
              width: "40%",
              margin: "auto",
            }}
          >
            <button
              className="default-btn"
              onClick={() => cronHandler(index, cron.callBackUrl)}
            >
              {cron.label}
            </button>
          </div>
        </div>
      ))}
      <div>
        <ul>
          {retrievedData.map(d => <li key={d}>{d}</li>)}
        </ul>
      </div>
    </div>
  );
};
