import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { FiPlus, FiUpload, FiX, FiSearch } from "react-icons/fi";
import style from "./style.module.css";
import CustomCheckbox from "./../../../Components/CustomCheckbox/index";
import { useEffect } from "react";
import { useHistory } from "react-router";
import {
  addClient,
  addLogoClient,
  getAddClient,
} from "./../../../Services/Client";
import { findManagerBySearch } from "../../../Services/Manager";
import toast from "../../../Helpers/toast";

const AjouterClient = () => {
  const history = useHistory();
  const [logoFile, setUrl] = useState(null);
  const logoFileRef = useRef(null);

  const [companyTypes, setCompanyTypes] = useState([]);
  const [societyTypes, setSocietyTypes] = useState({});

  const [filiales, setFiliales] = useState([]);
  const [sites, setSites] = useState([]);

  const [searchDataManagers, setSearchDataManagers] = useState([]);
  const [managers, setManagers] = useState([]);

  const [sector, setSector] = useState({});

  const formik = useFormik({
    initialValues: {
      label: "",
      email: "",
      phoneNumber: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
      },
      nbEmployees: "",
      revenue: "",
      sector: sector ? sector : "",
      websiteUri: "",
      corporateName: "",
      type: Object.keys(societyTypes)[0] || "",
      siren: "",
      tva: "",
      rcs: "",
      purchasingPlatform: "",
      purchasingManager: "",
      paymentDelay: "",
      isOrderFormMandatory: false,
      member: "",
      site: "",
      filiale: "",
    },
    onSubmit: (values) => {
      let society = {
        ...values,
        subsidiaries: [...filiales],
        sites: [...sites],
        affiliateWowCommercials: [...managers],
        sector: { ...sector },
        isClientSociety: true,
      };

      addClient(society).then((res) => {
        if (logoFile) {
          const logoMultiPartFile = new FormData();
          logoMultiPartFile.append("logoFile", logoFile, logoFile.name);
          return addLogoClient(logoMultiPartFile, res.data.id).then(() => {
            toast("success", "Client modifié");
            history.push("/clients/comptes");
          });
        } else {
          toast("success", "Client ajouté");
          history.push("/clients/comptes");
        }
      });
    },
  });

  const addFiliale = (e) => {
    if (formik.values.filiale !== "") {
      setFiliales([...filiales, formik.values.filiale]);
      formik.setFieldValue("filiale", "");
    }
  };
  const deleteFiliale = (i) => {
    setFiliales(filiales.filter((t, index) => index !== i));
  };

  const addSite = (e) => {
    if (formik.values.site !== "") {
      setSites([...sites, formik.values.site]);
      formik.setFieldValue("site", "");
    }
  };
  const deleteSite = (i) => {
    setSites(sites.filter((t, index) => index !== i));
  };

  const onChangeSectorHandler = (e) => {
    const index = e.target.value;
    const currentCompanyType = { ...companyTypes[index] };
    setSector(currentCompanyType);
  };

  const onChangeLogoHandler = (e) => {
    setUrl(e.target.files[0]);
  };

  const onAddManager = (manager) => {
    onCloseSearchManagers();
    if (managers.includes(manager)) {
      return;
    }
    setManagers((oldState) => [...oldState, manager]);
  };

  const onDeleteManager = (manager) => {
    setManagers((oldState) =>
      oldState.filter(
        (m) => m.email + m.firstName !== manager.email + manager.firstName
      )
    );
  };

  const searchManager = (e) => {
    findManagerBySearch(e.target.value).then((res) => {
      setSearchDataManagers(res.data);
    });
  };

  const onCloseSearchManagers = () => {
    setSearchDataManagers([]);
  };

  useEffect(() => {
    getAddClient().then((res) => {
      setCompanyTypes(res.data.companyTypes);
      setSocietyTypes(res.data.societyTypes);
      setSector(res.data.companyTypes[0]);
    });
  }, []);
  return (
    <form className="container py-4 px-5" onSubmit={formik.handleSubmit}>
      <div className="row px-4 mb-4">
        <div className="col-12 d-flex flex-row justify-content-between">
          <div className="header-4">Ajouter un client</div>
          <div>
            <button className="default-btn">Sauvegarder et partager</button>
          </div>
        </div>
      </div>
      <div className="row card px-4 py-4 gap-4">
        <input
          type="file"
          ref={logoFileRef}
          hidden
          onChange={onChangeLogoHandler}
        />
        <div className="col-12 d-flex flex-row align-items-start gap-4">
          <div className={style["client-img-container"]}>
            <img
              src={logoFile ? URL.createObjectURL(logoFile) : "/wow-circle-white.svg"}
              alt="wheel of work logo"
            />
          </div>
          <button
            className="default-btn blue-btn w-auto"
            type="button"
            onClick={(e) => logoFileRef.current.click()}
          >
            Télécharger une image
            <FiUpload className="mx-2" />
          </button>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="label">
            Nom de l'entreprise
          </label>
          <input
            id="label"
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="phoneNumber"
            >
              Numéro téléphone
            </label>
            <input
              id="phoneNumber"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="nbEmployees"
            >
              Nombre de salariés
            </label>
            <input
              id="nbEmployees"
              name="nbEmployees"
              value={formik.values.nbEmployees}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="revenue">
              Chiffre d'affaires (€)
            </label>
            <input
              id="revenue"
              name="revenue"
              value={formik.values.revenue}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="sector">
            Secteur
          </label>
          <select
            id="sector"
            name="sector"
            onChange={onChangeSectorHandler}
            className="default-input"
            required
          >
            {companyTypes.map((sector, index) => {
              return (
                <option key={sector.id} value={index}>
                  {sector.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-75 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="websiteUri"
            >
              Site web
            </label>
            <input
              id="websiteUri"
              name="websiteUri"
              value={formik.values.websiteUri}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-25 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="corporateName"
            >
              Dénomination sociale
            </label>
            <input
              id="corporateName"
              name="corporateName"
              value={formik.values.corporateName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-3">
          <div className="w-100 d-flex flex-row justify-content-between">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="address.street"
            >
              Adresse siege
            </label>
          </div>
          <div className="w-100 d-flex flex-row gap-3">
            <input
              id="address.street"
              name="address.street"
              value={formik.values.address.street}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              placeholder="Adresse"
            />
            <input
              id="address.postalCode"
              name="address.postalCode"
              value={formik.values.address.postalCode}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
              placeholder="Code postal"
            />
            <input
              id="address.city"
              name="address.city"
              value={formik.values.address.city}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              placeholder="Ville"
            />
            <input
              id="address.country"
              name="address.country"
              value={formik.values.address.country}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              placeholder="Pays"
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="type">
            Type société
          </label>
          <select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          >
            {Object.keys(societyTypes).map((s, i) => {
              return (
                <option key={i} value={s}>
                  {societyTypes[s]}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12 d-flex flex-row gap-3">
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer" htmlFor="siren">
              Siren
            </label>
            <input
              id="siren"
              name="siren"
              value={formik.values.siren}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer" htmlFor="tva">
              Numéro TVA
            </label>
            <input
              id="tva"
              name="tva"
              value={formik.values.tva}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold cursor-pointer" htmlFor="rcs">
              Numéro RCS
            </label>
            <input
              id="rcs"
              name="rcs"
              value={formik.values.rcs}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="purchasingPlatform"
          >
            Plateforme d'achat
          </label>
          <input
            id="purchasingPlatform"
            name="purchasingPlatform"
            value={formik.values.purchasingPlatform}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="purchasingManager"
          >
            Nom responsable d'achat
          </label>
          <input
            id="purchasingManager"
            name="purchasingManager"
            value={formik.values.purchasingManager}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex flex-row gap-3 align-items-end">
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold" htmlFor="member">
              WOW team member(s)
            </label>
            <div className="w-100 d-flex flex-row align-items-center gap-2">
              <div className="w-100 position-relative">
                <FiSearch className={style["search-icon"]} />
                <input
                  type="text"
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      searchManager(e);
                    } else {
                      onCloseSearchManagers();
                    }
                  }}
                  className={style["search-input"] + " default-input"}
                  placeholder="Rechercher un membre de l'équipe"
                />
                <div className="search-content">
                  {searchDataManagers.length > 0 &&
                    searchDataManagers.map((d, i) => (
                      <div
                        key={i}
                        className={style.hoverManager}
                        onClick={(e) => onAddManager(d)}
                      >
                        {d.firstName} {d.lastName}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold" htmlFor="paymentDelay">
              Délai de paiement en jours
            </label>
            <div className="w-100 d-flex flex-row align-items-center gap-3">
              <input
                id="paymentDelay"
                name="paymentDelay"
                value={formik.values.paymentDelay}
                onChange={formik.handleChange}
                className="default-input"
                type="number"
                required
              />
            </div>
          </div>
          <div className="w-75 mb-3">
            <CustomCheckbox
              onChange={formik.handleChange}
              value={formik.values.isOrderFormMandatory}
              name="isOrderFormMandatory"
              label="Bon de commande obligatoire"
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-3 flex-wrap">
          {managers.map((s, i) => {
            return (
              <div
                key={i}
                className={`${style["list-item"]} small-text d-flex flex-row gap-2 align-items-center`}
              >
                {s.firstName} {s.lastName}
                <FiX
                  className={`${style["delete"]} cursor-pointer`}
                  onClick={(e) => onDeleteManager(s)}
                />
              </div>
            );
          })}
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold" htmlFor="filiale">
              Filiales
            </label>
            <div className="w-100 d-flex flex-row align-items-center gap-2">
              <input
                name="filiale"
                id="filiale"
                value={formik.values.filiale}
                onChange={formik.handleChange}
                type="text"
                className="default-input"
              />
              <div>
                <button
                  type="button"
                  className="default-btn"
                  onClick={addFiliale}
                >
                  <FiPlus />
                </button>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column gap-3">
            <label className="small-text bold" htmlFor="site">
              Sites
            </label>
            <div className="w-100 d-flex flex-row align-items-center gap-2">
              <input
                id="site"
                name="site"
                value={formik.values.site}
                onChange={formik.handleChange}
                type="text"
                className="default-input"
              />
              <div>
                <button
                  type="button"
                  className="default-btn"
                  onClick={addSite}
                >
                  <FiPlus />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex gap-3 flex-wrap">
            {filiales.map((fl, i) => {
              return (
                <div
                  key={i}
                  className={`${style["list-item"]} small-text d-flex flex-row gap-2 align-items-center`}
                >
                  {fl}
                  <FiX
                    className={`${style["delete"]} cursor-pointer`}
                    onClick={(e) => deleteFiliale(i)}
                  />
                </div>
              );
            })}
          </div>
          <div className="w-100 d-flex gap-3 flex-wrap">
            {sites.map((st, i) => {
              return (
                <div
                  key={i}
                  className={`${style["list-item"]} small-text d-flex flex-row gap-2 align-items-center`}
                >
                  {st}
                  <FiX
                    className={`${style["delete"]} cursor-pointer`}
                    onClick={(e) => deleteSite(i)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div>
            <button type="submit" className="default-btn">
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AjouterClient;
