import style from "./style.module.css";
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi";
import React, { useState  } from "react";
import MissionList from "../../Lists/MissionList";

const MissionDashboard = ({ 
    openMissions, setOpenMissions, promotionalMissions, setPromotionalMissions, 
    associatedMissions, setAssociatedMissions, archivedMissions, setArchivedMissions, 
    handleMissionClick, handleEditMission 
}) => {

    const switchMissionsView = (toLeft) => {
        setIsMissionsLeft(toLeft)
    }
    const [isMissionsLeft, setIsMissionsLeft] = useState(true);

   return (
    <div className='d-flex flex-row overflow-hidden' style={{position:'relative', height:'100%'}}>
        <div className={style['missions-list']} style={{transform: isMissionsLeft ? "translateX(0)" : "translateX(-100%)"}}>
            <MissionList title='Missions à pourvoir' missions={openMissions} setMissions={setOpenMissions} handleEditMission={handleEditMission} handleMissionClick={handleMissionClick}/>
            <div style={{width: 2, background:'rgba(0, 0, 0, 0.3)',filter: 'blur(3px)', borderRadius:'30%'}} className="vertical-divider"></div>
            <MissionList title='Missions rattachées' missions={associatedMissions} setMissions={setAssociatedMissions} handleEditMission={handleEditMission} handleMissionClick={handleMissionClick}/>
            <div style={{width: 2, background:'rgba(0, 0, 0, 0.3)',filter: 'blur(3px)', borderRadius:'30%'}} className="vertical-divider"></div>
            <FiChevronsRight style={{cursor:'pointer', width:'32px'}} onClick={() => (switchMissionsView(false))}/>
        </div>
        <div style={{transform: !isMissionsLeft ? "translateX(-100%)" : "translateX(0)", left:'100%'}} className={style['missions-list']}>
            <FiChevronsLeft style={{cursor:'pointer', width:'32px'}} onClick={() => (switchMissionsView(true))}/>
            <div style={{width: 2, background:'rgba(0, 0, 0, 0.3)',filter: 'blur(3px)', borderRadius:'30%'}} className="vertical-divider"></div>
            <MissionList title='Missions vitrines' missions={promotionalMissions} setMissions={setPromotionalMissions} handleEditMission={handleEditMission} handleMissionClick={handleMissionClick}/>
            <div style={{width: 2, background:'rgba(0, 0, 0, 0.3)',filter: 'blur(3px)', borderRadius:'30%'}} className="vertical-divider"></div>
            <MissionList title='Missions archivées' missions={archivedMissions} setMissions={setArchivedMissions} handleEditMission={handleEditMission} handleMissionClick={handleMissionClick}/>
        </div>
    </div>
    );
};

export default MissionDashboard;