import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { useHistory } from "react-router";

import { createUser, getUser, updateUser } from "./../../../Services/User";
import toast from "../../../Helpers/toast";
import { useParams } from "react-router-dom";

const AddAdmin = () => {
  const history = useHistory();

  const id = useParams().id;

  const [userData, setUserData] = useState(null);
  const [show, setShow] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userData && userData.firstName ? userData.firstName : "",
      lastName: userData && userData.lastName ? userData.lastName : "",
      email: userData && userData.email ? userData.email : "",
      password: "",
      phoneNumber: userData && userData.phoneNumber ? userData.phoneNumber : "",
      linkedInUri: userData && userData.linkedInUri ? userData.linkedInUri : "",
      wowOffice: userData && userData.wowOffice ? userData.wowOffice : "",
      active: true,
    },
    onSubmit: (values) => {
      var state = { ...values };
      const formToSend = {
        ...state
      };

      if (userData) {
        delete formToSend.email;
        if (formToSend.password === "")
          delete formToSend.password;
        updateUser(id, formToSend)
          .then((res) => {
            history.push(`/create-admin`);
            toast("success", "L'admin a été modifié");
          })
      } else {
        createUser(formToSend)
          .then((res) => {
            history.push(`/create-admin`);
            toast("success", "L'admin a été ajouté");
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      getUser(id)
        .then((res) => {
          setUserData(res.data);
        })
    }
  }, [])

  return (
    <form className="container py-4 px-5" onSubmit={formik.handleSubmit}>
      <div className="row px-4 mb-4">
        <div className="col-12 d-flex flex-row justify-content-between">
          <div className="header-4">Ajouter un admin</div>
        </div>
      </div>
      <div className="row card px-4 py-4 gap-4">
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="lastName"
            >
              Nom
            </label>
            <input
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="firstName"
            >
              Prénom
            </label>
            <input
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
              disabled={userData}
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="password"
            >
              Mot de passe
              {userData &&
                <span style={{size: '10px', fontWeight: 'normal'}}> (Si le mot de passe n'est pas renseigné, il ne change pas)</span>
              }
            </label>
            <div className="w-100 position-relative">

              <input
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className="default-input"
                required={!userData}
                type={show ? "text" : "password"}
              />
              <FiEye className="show-pwd" onClick={() => setShow(!show)} />
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="phoneNumber"
          >
            Numéro de téléphone
          </label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="linkedInUri"
          >
            Url LinkedIn
          </label>
          <input
            id="linkedInUri"
            name="linkedInUri"
            value={formik.values.linkedInUri}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
          />
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="site">
            Bureau WOW
          </label>
          <input
            id="wowOffice"
            name="wowOffice"
            value={formik.values.wowOffice}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            required
          />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div>
            <button type="submit" className="default-btn">
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddAdmin;
