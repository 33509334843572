import { ProtectedApi } from "./../Helpers/api";
import { ADMIN } from "./User";

export const getClients = () => {
  return ProtectedApi(`${ADMIN}/society`);
};

export const getClientsUnconstrained = () => {
  return ProtectedApi(`${ADMIN}/society/unconstrained`);
};
