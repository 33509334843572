import React, { useCallback, useEffect, useState } from "react";
import style from "./style.module.css";
import { FiChevronDown, FiLogOut, FiSidebar, FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userActions } from "../../../Redux/Admin/actions";
import { ProtectedApi } from "../../../Helpers/api";
import { getUser } from "../../../Services/User";

const Navbar = () => {
  const currentUser = useSelector((state) => state.userReducer.user);
  const userId = useSelector((state) => state.userReducer.userId);

  const history = useHistory();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.menu.open);
  const toggleOpenMenu = useCallback(() => {
    dispatch({ type: "menu/TOGGLE_OPEN" });
  }, [dispatch]);

  const [showDropDown, setShowDropDown] = useState(false);
  const logout = () => {
    dispatch({
      type: userActions.LOGOUT_USER,
    });
    ProtectedApi.post("/auth/logout").then(() => {
      history.push("/auth/sign-in");
      sessionStorage.clear();
    });
  };

  useEffect(() => {
    if (!currentUser) {
      getUser(userId).then((res) => {
        dispatch({
          type: userActions.SET_USER,
          payload: { user: res.data },
        });
      });
    }
  }, [currentUser]);

  return (
    <div className={`container-fluid ${style["navbar"]}`}>
      <div className="d-flex row align-items-center justify-content-between h-100">
        <div
          className={`col-auto d-flex flex-row align-items-center align-items-center justify-content-between ${
            style["navbar-right"]
          } ${open ? style["open"] : ""}`}
        >
          <img
            className="wow-logo d-none d-xl-block"
            src={open ? "/wow-inline.svg" : "/wow-circle.svg"}
            alt="WheelOfWork logo"
          />
          <FiSidebar
            className="header-4 cursor-pointer"
            onClick={(e) => toggleOpenMenu()}
          />
        </div>
        <div
          className={`col-auto d-flex flex-row align-items-center justify-content-around position-relative  ${style["profile"]}`}
        >
          <div
            className={style["profile-picture-container"] + " cursor-pointer"}
          >
            <FiUser className="header-2" />
          </div>
          {currentUser && (
            <span
              className="wow-body bold d-none d-lg-flex cursor-pointer text-truncate"
              onClick={(e) => setShowDropDown(!showDropDown)}
            >
              {`${currentUser.firstName}`}
            </span>
          )}
          <FiChevronDown
            className="cursor-pointer"
            onClick={(e) => setShowDropDown(!showDropDown)}
          />
          {showDropDown && (
            <div
              className={style["close-dropdown"]}
              onClick={(e) => {
                if (showDropDown) setShowDropDown(false);
              }}
            ></div>
          )}
          <div
            className={`${showDropDown ? " d-flex" : "d-none"} ${
              style["nav-dropdown"]
            }`}
          >
            <div
              className="d-flex flex-row align-items-center cursor-pointer"
              onClick={(e) => logout()}
            >
              <FiLogOut className="mx-3" />
              <div className="small-text">Déconnexion</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
