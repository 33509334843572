import { useFormik } from "formik";
import React, { useState } from "react";
import { useSuggestions } from "../../../Hooks/useSuggestions";
import { SuggestiveResults } from "../../../Components/SuggestiveResults";
import { getSocietyBySearch } from "../../../Services/Society";
import { getConsultants } from "../../../Services/Consultants";
import toast from "../../../Helpers/toast";
import SearchBar from "../../SearchBar";
import style from "./style.module.css";
import CustomCheckbox from "../../../Components/CustomCheckbox/index";
import { getManagers } from "../../../Services/Manager";
import { FiX, FiSave, FiRepeat, FiChevronRight } from "react-icons/fi";
import Skills from "../../../Components/Skills";
import { EMissionStatus } from "../../../Helpers/enums/mission-status.enum";
import ConsultantCardV2 from "../../ConsultantCard/ConsultantCardV2";

const MissionPopup = ({
  mission,
  editMissionCallback,
  addMissionCallback,
  closePopup,
  missionStates,
  isShortened = true,
  switchCallback,
  applications,
  approveApplicationCallback,
  rejectApplicationCallback
}) => {
  const [skills, setSkills] = useState(mission?.skills ?? []);
  const [selectedSociety, setSelectedSociety] = useState(mission?.society);
  const [selectedManager, setSelectedManager] = useState(mission?.manager);
  const [selectedConsultant, setSelectedConsultant] = useState(
    mission?.consultant
  );

  const [applicationsHidden, setApplicationsHidden] = useState(true);

  const missionStatus = Object.values(EMissionStatus);

  const selectSuggestionConsultantHandler = (e) => {
    suggestionModuleConsultant.setDisplaySuggestions((oldState) => !oldState);
    setSelectedConsultant(e);
  };

  const suggestionModuleConsultant = useSuggestions({
    callFunction: (s) =>
      getConsultants(undefined, s).then((res) => res.data?.consultants),
  });

  const selectSuggestionSocietyHandler = (e) => {
    suggestionModuleSociety.setDisplaySuggestions((oldState) => !oldState);
    setSelectedSociety(e);
  };

  const suggestionModuleSociety = useSuggestions({
    callFunction: (s) => getSocietyBySearch(s).then((res) => res.data),
  });

  const extractFullNameFromManager = (m) => {
    if (!m) {
      return false;
    }
    return `${m.firstName} ${m.lastName}`;
  };

  const extractFullNameFromConsultant = (c) => {
    if (!c) {
      return false;
    }
    return `${c.user.firstName} ${c.user.lastName}`;
  };

  const selectSuggestionManagerHandler = (e) => {
    suggestionModuleManager.setDisplaySuggestions((oldState) => !oldState);
    setSelectedManager(e);
  };

  const suggestionModuleManager = useSuggestions({
    callFunction: (s) =>
      getManagers({ id: selectedSociety.id, search: s }).then(
        (res) => res.data
      ),
  });

  const isUuid = (value) => {
    const regexPatternUuid =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const myRegex = new RegExp(regexPatternUuid);

    return myRegex.test(value);
  };

  const approveApplication = (application) => {
    approveApplicationCallback(application.id).then(() => {
      toast("success", "Candidature acceptée");
      setSelectedConsultant(application.consultant);
    });
  };

  const rejectApplication = (application) => {
    rejectApplicationCallback(application.id).then(() => {
      toast("success", "Candidature refusée");
      if (selectedConsultant?.id === application.consultant.id) {
        setSelectedConsultant(null);
      }
    });
  };

  const getApplicationsMenu = (application) => {
    const result =
      application.status === "Approuvée"
        ? [{ label: "Refuser", callback: () => rejectApplication(application) }]
        : application.status === "En attente"
        ? [
            {
              label: "Approuver",
              callback: () => approveApplication(application),
            },
            {
              label: "Refuser",
              callback: () => rejectApplication(application),
            },
          ]
        : [
            {
              label: "Approuver",
              callback: () => approveApplication(application),
            },
          ];
    return result;
  };

  const formik = useFormik({
    initialValues: {
      label: "",
      dailyAverage: "",
      ref: "",
      isVisible: !mission?.isHidden,
      isConfidential: true,
      society: selectedSociety,
      manager: selectedManager,
      consultant: selectedConsultant?.id,
      description: "",
      address: {
        street: "",
        postalCode: "",
        city: "",
        country: "France",
      },
      workLocation: missionStates?.workLocations?.length
        ? missionStates?.workLocations[0][1]
        : "",
      duration: missionStates?.durations?.length
        ? missionStates?.durations[0][1]
        : "",
      monthsDuration: 1,
      startDate: "",
      workRythmn: missionStates?.workRythmns?.length
        ? missionStates?.workRythmns[0][1]
        : "",
      ...mission,
    },
    onSubmit: (values) => {
      delete values.logo;
      let formValues = {
        ...values,
        isHidden: !values.isVisible,
        skills: skills,
        society: selectedSociety ?? null,
        manager: selectedManager ?? null,
        consultant: selectedConsultant ?? null,
      };

      if (mission && editMissionCallback) {
        editMissionCallback(formValues);
      } else if (addMissionCallback) {
        addMissionCallback(formValues);
      }
    },
  });

  return (
    <form style={{ maxHeight: "100%" }} onSubmit={formik.handleSubmit}>
      <div
        className={`${style["container"]} py-4 px-5 gap-3`}
        style={{ height: "100%", maxHeight: "100%" }}
      >
        <div className="d-flex">
          {editMissionCallback && mission ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                fontSize: "20px",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontWeight: 600,
                }}
              >
                {mission?.label}
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontWeight: 400,
                  color: "var(--primary-light-gray)",
                  gap: "0.5rem",
                }}
              >
                {" "}
                -{" "}
                <span
                  className={style["defaut-label-switch"]}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={switchCallback}
                >
                  <span>
                    {!isShortened ? "Édition classique" : "Édition rapide"}
                  </span>
                  <FiRepeat
                    className={style["rotate-icon"]}
                    style={{ fontSize: "16px" }}
                  />
                </span>
              </span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                fontSize: "20px",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontWeight: 600,
                }}
              >
                Ajouter une mission
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontWeight: 400,
                  color: "var(--primary-light-gray)",
                  gap: "0.5rem",
                }}
              >
                {" "}
                -{" "}
                <span
                  className={style["defaut-label-switch"]}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={switchCallback}
                >
                  <span>
                    {!isShortened ? "Ajout classique" : "Ajout rapide"}
                  </span>
                  <FiRepeat
                    className={style["rotate-icon"]}
                    style={{ fontSize: "16px" }}
                  />
                </span>
              </span>
            </div>
          )}
          <div
            style={{
              textWrap: "nowrap",
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
            }}
          >
            <button type="submit" className="default-btn">
              <FiSave style={{ fontSize: "16px" }} />
            </button>
            {closePopup && (
              <button
                type="button"
                onClick={closePopup}
                className="default-btn cancel-btn"
                style={{ hoverbackgroundColor: "var(--secondary-light-gray)" }}
              >
                {/* Quitter */}
                <FiX style={{ fontSize: "16px" }} />
              </button>
            )}
          </div>
        </div>
        <div
          className="d-flex flex-column gap-4"
          style={{ overflowY: "scroll", height: "auto" }}
        >
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row gap-3">
              <div className={style["default-input-block"]}>
                <label htmlFor="label">Titre</label>
                <input
                  id="label"
                  name="label"
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  className="default-input"
                  placeholder="Data Engineer"
                  type="text"
                  required
                />
              </div>
              <div className={style["default-input-block"]}>
                <label htmlFor="ref">Référence</label>
                <input
                  id="ref"
                  name="ref"
                  value={formik.values.ref}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="REF112023"
                />
              </div>

              <div className={style["default-input-block"]}>
                <label htmlFor="consultant">Consultant</label>
                <SearchBar
                  searchValue={
                    extractFullNameFromConsultant(selectedConsultant)
                      ? extractFullNameFromConsultant(selectedConsultant)
                      : suggestionModuleConsultant.search
                  }
                  onChange={suggestionModuleConsultant.onChangeSearch}
                  onClick={() => {
                    suggestionModuleConsultant.onClickSuggestion();
                    setSelectedConsultant(null);
                  }}
                  placeholder="John Doe"
                />
                {suggestionModuleConsultant.displaySuggestions &&
                  suggestionModuleConsultant.suggestions?.length > 0 && (
                    <SuggestiveResults
                      results={suggestionModuleConsultant.suggestions}
                      onClick={selectSuggestionConsultantHandler}
                      displayTransformer={(c) =>
                        extractFullNameFromConsultant(c)
                      }
                    />
                  )}
              </div>

              <div className={style["default-input-block"]}>
                <label htmlFor="society">Société</label>
                <SearchBar
                  searchValue={
                    selectedSociety?.label
                      ? selectedSociety.label
                      : suggestionModuleSociety.search
                  }
                  onChange={suggestionModuleSociety.onChangeSearch}
                  onClick={() => {
                    suggestionModuleSociety.onClickSuggestion();
                    setSelectedSociety(null);
                    setSelectedManager(null);
                  }}
                  placeholder="Decathlon"
                />
                {suggestionModuleSociety.displaySuggestions &&
                  suggestionModuleSociety.suggestions?.length > 0 && (
                    <SuggestiveResults
                      results={suggestionModuleSociety.suggestions}
                      onClick={selectSuggestionSocietyHandler}
                    />
                  )}
              </div>
              {selectedSociety && (
                <div className={style["default-input-block"]}>
                  <label htmlFor="manager">Manager</label>
                  <SearchBar
                    searchValue={
                      extractFullNameFromManager(selectedManager)
                        ? extractFullNameFromManager(selectedManager)
                        : suggestionModuleManager.search
                    }
                    onChange={suggestionModuleManager.onChangeSearch}
                    onClick={() => {
                      suggestionModuleManager.onClickSuggestion();
                      setSelectedManager(null);
                    }}
                    placeholder="Joe Dohn"
                  />
                  {suggestionModuleManager.displaySuggestions &&
                    suggestionModuleManager.suggestions?.length > 0 && (
                      <SuggestiveResults
                        results={suggestionModuleManager.suggestions}
                        onClick={selectSuggestionManagerHandler}
                        displayTransformer={(m) =>
                          extractFullNameFromManager(m)
                        }
                      />
                    )}
                </div>
              )}
            </div>
            <div className="d-flex flex-row gap-3">
              <CustomCheckbox
                value={formik.values.isVisible}
                name="isVisible"
                label="Visible (afficher ou non sur le front consultant)"
                onChange={formik.handleChange}
              />
              <CustomCheckbox
                value={formik.values.isConfidential}
                name="isConfidential"
                label="Confidentielle"
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <div className={style["default-input-block"]}>
              <label htmlFor="dailyAverage">TJM achat</label>
              <input
                id="dailyAverage"
                name="dailyAverage"
                value={formik.values.dailyAverage}
                onChange={formik.handleChange}
                className="default-input"
                placeholder="400"
                type="number"
                required
              />
            </div>
            {(!isShortened || mission) && (
              <div className={style["default-input-block"]}>
                <label htmlFor="dailyAverageResale">TJM revente</label>
                <input
                  id="dailyAverageResale"
                  name="dailyAverageResale"
                  value={formik.values.dailyAverageResale}
                  onChange={formik.handleChange}
                  className="default-input"
                  placeholder="500"
                  type="number"
                />
              </div>
            )}
            {!mission?.consultant && (
              <div className={style["default-input-block"]}>
                <label>Statut</label>
                <select
                  id="status"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                >
                  {missionStatus.map((value, index) => {
                    return (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className={style["default-input-block"]}>
              <label>Rythme (Type de contrat)</label>
              <select
                id="workRythmn"
                name="workRythmn"
                value={formik.values.workRythmn}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                required
              >
                {missionStates?.workRythmns?.map((wr, i) => {
                  return (
                    <option key={i} value={wr[1]}>
                      {wr[1]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row gap-3">
              {(!isShortened || mission) && (
                <div className={style["default-input-block"]}>
                  <label htmlFor="location.address">Adresse</label>
                  <input
                    id="address.street"
                    name="address.street"
                    value={formik.values.address.street}
                    onChange={formik.handleChange}
                    className="default-input"
                    type="text"
                    placeholder="57 Rue Nationale"
                  />
                </div>
              )}

              {(!isShortened || mission) && (
                <div className={style["default-input-block"]}>
                  <label htmlFor="location.address">Code postal</label>
                  <input
                    id="address.postalCode"
                    name="address.postalCode"
                    value={formik.values.address.postalCode}
                    onChange={formik.handleChange}
                    className="default-input"
                    type="number"
                    placeholder="59800"
                  />
                </div>
              )}
              <div className={style["default-input-block"]}>
                <label htmlFor="location.address">Ville</label>
                <input
                  id="address.city"
                  name="address.city"
                  value={formik.values.address.city}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  placeholder="Lille"
                  required
                />
              </div>
              {(!isShortened || mission) && (
                <div className={style["default-input-block"]}>
                  <label htmlFor="location.address">Pays</label>
                  <input
                    id="address.country"
                    name="address.country"
                    value={formik.values.address.country}
                    onChange={formik.handleChange}
                    className="default-input"
                    type="text"
                    placeholder="France"
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-row gap-3">
              <div className={style["default-input-block"]}>
                <label htmlFor="startDate">Date début souhaitée</label>
                <input
                  id="startDate"
                  name="startDate"
                  value={formik.values.startDate?.split("T")[0]}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="date"
                  required
                />
              </div>
              <div className={style["default-input-block"]}>
                <label>Durée (en mois)</label>
                <input
                  id="monthsDuration"
                  name="monthsDuration"
                  value={formik.values.monthsDuration}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="number"
                  min={1}
                  required
                />
              </div>
              {(!isShortened || mission) && (
                <div className={style["default-input-block"]}>
                  <label>Durée</label>
                  <select
                    id="duration"
                    name="duration"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    className="default-input"
                    type="text"
                  >
                    {missionStates?.durations?.map((duration, i) => {
                      return (
                        <option key={i} value={duration[1]}>
                          {duration[1]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className={style["default-input-block"]}>
                <label>Mobilité</label>
                <select
                  id="workLocation"
                  name="workLocation"
                  value={formik.values.workLocation}
                  onChange={formik.handleChange}
                  className="default-input"
                  type="text"
                  required
                >
                  {missionStates?.workLocations?.map((mt, i) => {
                    return (
                      <option key={i} value={mt[1]}>
                        {mt[1]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className={style["default-input-block"]}>
            <label htmlFor="finder">Compétences</label>
            <Skills
              placeholder="Rechercher par compétences (ex: Active Directory)"
              titleHidden={true}
              onChangeSkillsHandler={setSkills}
              consultantSkills={skills}
            ></Skills>
          </div>
          <div className={style["default-input-block"]}>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              className={`text-area default-input`}
              type="text"
              required
            />
          </div>
          {mission && (
            <div className={style["default-input-block"]}>
              <button
                className="d-flex flex-row toggle"
                type="button"
                onClick={() => {
                  setApplicationsHidden(!applicationsHidden);
                }}
              >
                <FiChevronRight
                  style={!applicationsHidden && { transform: "rotate(90deg" }}
                />
                <label htmlFor="description">Candidats</label>
              </button>
              {!applicationsHidden &&
                (!applications?.length ? (
                  <div>Aucun candidats</div>
                ) : (
                  <div
                    style={{ overflowY: "scroll" }}
                    className="d-flex flex-column overflow-scroll-y gap-3"
                  >
                    {applications?.map((application, i) => (
                      <ConsultantCardV2
                        key={i}
                        consultant={{
                          ...application.consultant,
                          isFromRcrm: !isUuid(application.consultant.id),
                        }}
                        contentSlot={
                          <div className="d-flex" style={{ width: "100%" }}>
                            <div
                              style={{
                                borderRadius: "4px",
                                color: "white",
                                marginLeft: "auto",
                                marginTop: "auto",
                                backgroundColor:
                                  application.status === "En attente"
                                    ? "var(--primary-blue)"
                                    : application.status === "Approuvée"
                                    ? "var(--primary-green)"
                                    : "var(--red)",
                                width: "fit-content",
                                height: "fit-content",
                                padding: "4px",
                              }}
                            >
                              {application.status}
                            </div>
                          </div>
                        }
                        menuOptions={getApplicationsMenu(application)}
                      />
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default MissionPopup;
