import Api from "../Helpers/api";
import { ProtectedApi } from "./../Helpers/api";


export const load = async () => {
  return ProtectedApi.get("/auth/token/verify/admin");
};
export const RefreshAccessToken = () => {
  return Api.get("/auth/token/refresh");
};

export const getAdminTokenForControlPanel = () => {
  return Api.get('/auth/sign-into-admin-control-panel');
}