import React from "react";
import ListItem from "./Listtem";
import style from "./style.module.css";

const BDCTable = ({ contracts = [], onDelete, openPopup }) => {
  return (
    <div className={`${style["contracts-table"]}`}>
      <div
        className={`${style["contracts-table-item"]} ${style["contracts-table-header"]}`}
      >
        <div className="small-text">NOM CONSULTANT</div>
        <div className="small-text">INTITULE MISSION</div>
        <div className="small-text">DOCUMENTS</div>
        <div className="small-text">DATE DE SIGNATURE</div>
        <div className="small-text">DATE DEBUT</div>
        <div className="small-text">DATE FIN</div>
        <div className="small-text">TJM</div>
        <div className="small-text text-center">MANAGER</div>
        <div className="small-text text-center">REFERENCE SPECIFIQUE</div>
        <div className="small-text text-center">STATUT</div>
        <div className="small-text">ACTIONS</div>
      </div>
      {contracts.length > 0 ? (
        contracts.map((c, i) => {
          return (
            <ListItem
              key={i}
              contract={c}
              onDelete={onDelete}
              openPopup={openPopup}
            />
          );
        })
      ) : (
        <div className="w-100 p-5 small-text text-center">
          Aucun bon(s) de commande
        </div>
      )}
    </div>
  );
};

export default BDCTable;
