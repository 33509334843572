import React from "react";
import MissionRowCard from "../../MissionCard/MissionRowCard";
import SortButton from "../../Buttons/SortButton";

const MissionList = ({ 
    title, missions, setMissions,
    handleMissionClick, handleEditMission 
}) => {
    const sortMissionsByField = ({field, fieldType, isDesc, transformer}) => {
        let sortedMissions = [...missions];
        if (fieldType === 'date') {
          sortedMissions.sort((a, b) => {
            if(transformer){
              const aTransformed = transformer(a[field]);
              const bTransformed = transformer(b[field]);
              return isDesc ? new Date(bTransformed) - new Date(aTransformed) : new Date(aTransformed) - new Date(bTransformed)
            }
            return isDesc ? new Date(b[field]) - new Date(a[field]) : new Date(a[field]) - new Date(b[field])
          });
        }
        else if (fieldType === 'number') {
          sortedMissions.sort((a, b) => {
            if(transformer){
              const aTransformed = transformer(a[field]);
              const bTransformed = transformer(b[field]);
              return isDesc ? bTransformed - aTransformed : aTransformed - bTransformed
            }
            return isDesc ? b[field] - a[field] : a[field] - b[field]
          });
        }
        else if (fieldType === 'string') {
          sortedMissions.sort((a, b) =>{
            if(transformer){
              const aTransformed = transformer(a[field]);
              const bTransformed = transformer(b[field]);
              return isDesc ? bTransformed.localeCompare(aTransformed) : aTransformed.localeCompare(bTransformed)
            }
            return isDesc ? b[field].localeCompare(a[field]) : a[field].localeCompare(b[field])
          });
        }
      
        setMissions(sortedMissions);
      }; 

   return (
    <div className="d-flex flex-column" style={{width: "100%"}}>
        <div className="d-flex flex-row gap-3 mb-2 align-items-center">
            <div style={{fontWeight: 500,fontSize:'16px'}}>{ title }</div>
            {/* <SortButton label='Candidats' callback={(e) => { sortMissionsByField({field: 'applications', isDesc:e, fieldType: 'number', transformer: (f) => (f.length ?? 0)})}}/> */}
            <SortButton label='Date création' callback={(e) => { sortMissionsByField({field: 'createdAt', isDesc:e, fieldType: 'date'})}}/>
            <SortButton label='Date début' callback={(e) => { sortMissionsByField({field: 'startDate', isDesc:e, fieldType: 'date'})}}/>
            <SortButton label='Dernière candidature' callback={(e) => { sortMissionsByField({field: 'applications', isDesc:e, fieldType: 'date', transformer: (f) => {
                return f?.length > 0 ? f?.reduce((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateA.getTime() > dateB.getTime() ? a : b;
                }).createdAt : null
                }})}}/>
        </div>
        {!missions.length ? (
            <div>Aucune mission</div>
            ) : ( 
            <div style={{overflowY:'scroll'}} className="d-flex flex-column overflow-scroll-y gap-3">
            {
                missions?.map((mission, i) => (
                <MissionRowCard mission={mission}  key={i} missionClick={handleMissionClick} editMissionCallback={handleEditMission}/>
                    ))
            }
            </div>
        )}
    </div>
    );
};

export default MissionList;