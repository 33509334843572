import React from "react";
import { NavLink } from "react-router-dom";
import style from "./style.module.css";
import { withRouter } from "react-router-dom";

const ConsultantNavigation = ({ match }) => {
  return (
    <div className="row p-0 mb-4">
      <div className="col-12 card d-flex flex-row justify-content-around">
        <NavLink
          to={`/consultant/${match.params.id}/modifier`}
          exact
          className={`${style["nav-item"]} small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          Modifier
        </NavLink>
        <NavLink
          to={`/consultant/${match.params.id}`}
          exact
          className={`${style["nav-item"]} small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          Identité
        </NavLink>
        <NavLink
          to={`/consultant/${match.params.id}/candidatures`}
          exact
          className={`${style["nav-item"]} small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          Candidatures
        </NavLink>
        <NavLink
          to={`/consultant/${match.params.id}/documents`}
          exact
          className={`${style["nav-item"]} small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          Documents
        </NavLink>
        <NavLink
          to={`/consultant/${match.params.id}/fin-de-mois`}
          exact
          className={`${style["nav-item"]} small-text bold`}
          activeClassName={`${style["active"]} green-text`}
        >
          Fin de mois
        </NavLink>
      </div>
    </div>
  );
};

export default withRouter(ConsultantNavigation);
