import React, { useState } from "react";
import style from "./style.module.css";
import { FiEye } from "react-icons/fi";
import { useFormik } from "formik";
import { userActions } from "../../../Redux/Admin/actions";
import { useDispatch } from "react-redux";
import Api from "../../../Helpers/api";
import { useHistory } from "react-router-dom";
import toast from "../../../Helpers/toast";

const SignIn = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      Api.post("/auth/login/admin", { ...values })
        .then((res) => {
          if (res.status === 201 && res.data.id) {
            sessionStorage.setItem("USER_ID", res.data.id);
            dispatch({
              type: userActions.LOGIN_USER,
              payload: { user: res.data },
            });
            history.push("/");
          }
        })
        .catch((e) => {
          if (e.response.status === 403)
            toast("error", "Ce compte n'est pas activé.");
          else toast("error", "Email ou mot de passe incorrect.");
        });
    },
  });

  return (
    <div
      className="container d-flex justify-content-center p-5"
      style={{ height: "100%" }}
    >
      <form
        className={`w-100 mx-auto ${style["sign-in"]}`}
        onSubmit={formik.handleSubmit}
      >
        <img className="mb-5" src="/wow-inline.svg" alt="WheelOfWork logo" />
        <div className="w-100 d-flex flex-column">
          <label htmlFor="email" className="default-input-label">
            Email
          </label>
          <input
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            type="email"
            required={true}
            className="default-input"
            placeholder=""
            autoComplete="off"
          />
        </div>
        <div className="w-100 d-flex flex-column">
          <label htmlFor="password" className="default-input-label">
            Mot de passe
          </label>
          <div className="w-100 position-relative">
            <input
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className="default-input"
              type={show ? "text" : "password"}
              required={true}
              placeholder=""
              autoComplete="off"
            />
            <FiEye className="show-pwd" onClick={(e) => setShow(!show)} />
          </div>
        </div>
        <button type="submit" className="default-btn mt-4">
          Connexion
        </button>
      </form>
    </div>
  );
};

export default SignIn;
