import { useState } from "react";
import { legalStatuses } from "../../../Helpers/data";
import toast from "../../../Helpers/toast";
import { detachSociety } from "../../../Services/Consultant";
import Popup from "../../Popup";
import { TextField } from "../../TextField";

export const SocietyIdentity = ({ currentConsultant }) => {
  const [confirmDetach, setConfirmDetach] = useState(false);
  const handleConfirmDetach = () => {
    setConfirmDetach(true);
  };

  const handleDetachSociety = () => {
    detachSociety(currentConsultant.id).then(
      (res) => {
        toast("success", "La société a bien été détachée du consultant");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      },
      (reason) => {
        toast("error", reason.message);
      }
    );
    setConfirmDetach(false);
  };
  return (
    <div className="w-100 card d-flex flex-column gap-4 p-4">
      <div className="wow-subtitle bold">Statut légal</div>
      <TextField
        label={"Type de société"}
        value={
          (currentConsultant.society?.juridicStructure &&
            legalStatuses[currentConsultant.society?.juridicStructure]) ||
          "Aucune"
        }
      />
      <TextField
        label={"Nom de la société"}
        value={currentConsultant.society?.label || "Aucune"}
      />
      <div className="small-text">
        {currentConsultant.society ? (
          <button
            className="default-btn danger-btn"
            onClick={handleConfirmDetach}
          >
            Supprimer le statut légal
          </button>
        ) : (
          // <button className="default-btn" onClick={(e) => {
          // 	setOpenSocietyPopup(true);
          // }}>
          // 	Attacher un statut légal
          // </button>
          <span></span>
        )}
      </div>
      {confirmDetach && (
        <Popup
          size="sm"
          show={confirmDetach}
          onClose={(e) => setConfirmDetach(false)}
        >
          <div className="row p-4 gap-4 justify-content-center">
            <div className="col-12 small-text">
              Etes vous sûr(e) de vouloir détacher le consultant de sa société ?
            </div>
            <div className="col-5">
              <button
                className="default-btn danger-btn"
                onClick={(e) => setConfirmDetach(false)}
              >
                Annuler
              </button>
            </div>
            <div className="col-5">
              <button
                className="default-btn"
                onClick={(e) => {
                  handleDetachSociety();
                }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};
