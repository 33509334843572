import { useState } from "react";
import { UploadFileInput } from "../../../../Components/UploadFileInput";
import toast from "../../../../Helpers/toast";
import { updateFile } from "../../../../Services/Consultant";
import {
  createAnAdminContract,
  updateAnAdminContract,
  UploadAdminContractFile,
} from "../../../../Services/ContratAdmin";

export const PopupContratApplication = ({
  setOpen,
  consultants,
  societyId,
  setApplicationsHandler,
  currentContratToUpdate,
}) => {
  const [contractFile, setContractFile] = useState();
  const [isMissingFileValidation, setIsMissingFileValidation] = useState(
    currentContratToUpdate ? false : true
  );
  const onChangeFileHandler = (e) => {
    if (e.target.files[0]) {
      setContractFile(e.target.files[0]);
      setIsMissingFileValidation(false);
    } else {
      setIsMissingFileValidation(true);
    }
  };

  const [contractReference, setContractReference] = useState(
    currentContratToUpdate ? currentContratToUpdate.reference : ""
  );

  const [dailyRateAverage, setDailyRateAverage] = useState(
    currentContratToUpdate?.dailyRateAverage
  );

  const [signatureDate, setSignatureDate] = useState(
    currentContratToUpdate && currentContratToUpdate.signatureDate
      ? currentContratToUpdate.signatureDate.split("T")[0]
      : ""
  );
  const [startDate, setStartDate] = useState(
    currentContratToUpdate ? currentContratToUpdate.startDate.split("T")[0] : ""
  );
  const [endDate, setEndDate] = useState(
    currentContratToUpdate
      ? currentContratToUpdate.expirationDate.split("T")[0]
      : ""
  );
  const [selectedConsultantId, setSelectedConsultantId] = useState(
    currentContratToUpdate
      ? currentContratToUpdate.consultant.id
      : consultants.length > 0
      ? consultants[0].id
      : ""
  );
  const [selectedMissionId, setSelectedMissionId] = useState(
    currentContratToUpdate
      ? currentContratToUpdate.mission.id
      : consultants.length > 0
      ? consultants[0].missions[0].id
      : ""
  );

  const findCurrentConsultantMissions = (consultants, selectedConsultant) => {
    return consultants.find((c) => c.id === selectedConsultant).missions;
  };

  const onChangeInputHandler = (index, e) => {
    if (index === 0) {
      const cId = e.target.value;
      setSelectedConsultantId(cId);
      // select the first mission of the changed consultant
      setSelectedMissionId(
        findCurrentConsultantMissions(consultants, cId)[0].id
      );
    } else if (index === 1) {
      setSelectedMissionId(e.target.value);
    } else if (index === 2) {
      setContractReference(e.target.value);
    } else if (index === 3) {
      setStartDate(e.target.value);
    } else if (index === 4) {
      setEndDate(e.target.value);
    } else if (index === 5) {
      setDailyRateAverage(e.target.value);
    } else if (index === 6) {
      setSignatureDate(e.target.value);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!contractFile) {
      setIsMissingFileValidation(true);
      return;
    }

    const contractToSend = {
      startDate,
      dailyRateAverage,
      expirationDate: endDate,
      signatureDate,
      type: "APPLICATION",
      consultant: { id: selectedConsultantId },
      mission: { id: selectedMissionId },
      society: { id: societyId },
      reference: contractReference,
    };

    createAnAdminContract(contractToSend).then((savedContract) => {
      const contractId = savedContract.data.id;
      const formData = new FormData();
      formData.append("file", contractFile);
      UploadAdminContractFile(formData, "APPLICATION", {
        consultantId: selectedConsultantId,
        missionId: selectedMissionId,
        contractId: contractId,
        societyId,
      }).then((savedDocument) => {
        setOpen(false);
        toast("success", "Fichier uploadé");
        setApplicationsHandler((oldState) => {
          const contractToPush = {
            ...savedContract.data,
            file: savedDocument.data,
          };
          return [...oldState, contractToPush];
        });
      });
    });
  };

  const submitFormUpdateContract = (e) => {
    e.preventDefault();

    const contractToSend = {
      startDate,
      dailyRateAverage,
      signatureDate,
      expirationDate: endDate,
      type: "APPLICATION",
      society: { id: societyId },
      reference: contractReference,
      status: currentContratToUpdate.status,
      mission: { id: selectedMissionId },
      consultant: { id: selectedConsultantId },
      id: currentContratToUpdate.id,
    };

    updateAnAdminContract(contractToSend).then(() => {
      setOpen(false);
      toast("success", "Contrat modifié");
      setApplicationsHandler((oldState) => {
        const index = oldState.findIndex(
          (c) => c.id === currentContratToUpdate.id
        );
        contractToSend.file = currentContratToUpdate.file;
        contractToSend.consultant = currentContratToUpdate.consultant;
        contractToSend.mission = currentContratToUpdate.mission;
        contractToSend.society = currentContratToUpdate.society;
        oldState[index] = contractToSend;
        return [...oldState];
      });
    });

    if (contractFile) {
      const formdata = new FormData();
      formdata.append("file", contractFile);
      updateFile(
        "admin_contract",
        currentContratToUpdate.file._id,
        formdata
      ).then((res) => {
        setApplicationsHandler((oldState) => {
          const index = oldState.findIndex(
            (c) => c.id === currentContratToUpdate.id
          );
          contractToSend.file = res.data;
          oldState[index] = contractToSend;
          return [...oldState];
        });
      });
    }
  };
  return (
    <form
      className="row justify-content-between p-3 py-4 gap-4"
      onSubmit={currentContratToUpdate ? submitFormUpdateContract : submitForm}
    >
      <div className="col-12 wow-subtitle bold">
        {currentContratToUpdate ? "Modifier" : "Ajouter"} un contrat
        d'application
      </div>

      {!currentContratToUpdate && (
        <div className="col-12 d-flex flex-column gap-3">
          <div className="small-text bold">
            Choisir/rechercher un consultant
          </div>
          <select
            name="consultantId"
            id="consultantId"
            className="default-input"
            required
            value={selectedConsultantId}
            onChange={(e) => onChangeInputHandler(0, e)}
          >
            <option value="consultantId" disabled>
              Séléctionner un consultant
            </option>

            {consultants.map((c, i) => {
              return (
                <option key={i} value={c.id}>
                  {c.user.fullName}
                </option>
              );
            })}
          </select>
        </div>
      )}

      {!currentContratToUpdate && (
        <div className="col-12 d-flex flex-column gap-3">
          <div className="small-text bold">Choisir une mission</div>
          <select
            name="missionId"
            id="missionId"
            className="default-input"
            required
            value={selectedMissionId}
            onChange={(e) => onChangeInputHandler(1, e)}
          >
            <option value="Choisir une mission" disabled>
              Choisir une mission
            </option>

            {findCurrentConsultantMissions(
              consultants,
              selectedConsultantId
            ).map((m, i) => {
              return (
                <option key={i} value={m.id}>
                  {m.label}
                </option>
              );
            })}
          </select>
        </div>
      )}
      {/* {currentContratToUpdate && (
        <a
          className="small-text blue-text d-none d-md-flex cursor-pointer"
          href={
            currentContratToUpdate?.file &&
            b64ToObjectUrl(
              currentContratToUpdate.file.base64Document,
              currentContratToUpdate.file.filetype
            )
          }
          target="_blank"
          rel="noreferrer"
        >
          Visualiser le contrat à remplacer
        </a>
      )} */}
      {!currentContratToUpdate && (
        <UploadFileInput
          file={contractFile}
          unknownFile={currentContratToUpdate ? "" : "Aucun d'application"}
          onChangeFile={onChangeFileHandler}
          buttonTitle={
            currentContratToUpdate
              ? "Remplacer le contrat d'application"
              : "Télécharger un contrat d'application"
          }
          uploadTitle={
            currentContratToUpdate
              ? "Remplacer le contrat d'application"
              : "Uploader un contrat d'application"
          }
        />
      )}
      {isMissingFileValidation && (
        <span className={"small-text bold"} style={{ color: "red" }}>
          Veuillez ajouter un contrat d'application *
        </span>
      )}

      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de signature
        </label>
        <input
          id="signatureDate"
          name="signatureDate"
          className="default-input"
          type="date"
          value={signatureDate}
          onChange={(e) => onChangeInputHandler(6, e)}
          required
        />
      </div>
      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="label">
          Reference du contrat
        </label>
        <input
          id="reference"
          name="reference"
          className="default-input"
          type="text"
          value={contractReference}
          onChange={(e) => onChangeInputHandler(2, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de début du contrat d'application
        </label>
        <input
          id="startDate"
          name="startDate"
          className="default-input"
          type="date"
          value={startDate}
          onChange={(e) => onChangeInputHandler(3, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="date">
          Date de fin du contrat d'application
        </label>
        <input
          id="endDate"
          name="endDate"
          className="default-input"
          type="date"
          value={endDate}
          onChange={(e) => onChangeInputHandler(4, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-column gap-3">
        <label className="small-text bold cursor-pointer" htmlFor="label">
          TJM
        </label>
        <input
          id="dailyRateAverage"
          name="dailyRateAverage"
          className="default-input"
          type="number"
          value={dailyRateAverage}
          onChange={(e) => onChangeInputHandler(5, e)}
          required
        />
      </div>

      <div className="col-12 d-flex flex-row justify-content-center gap-4">
        <div>
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setOpen(false)}
          >
            Annuler
          </button>
        </div>
        <div>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </div>
    </form>
  );
};
