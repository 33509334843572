import React from "react";
import style from './style.module.css'


const InfoCardSmall = ({ label, value, iconComponent }) => {
  return (
    <div className={style['info-card']}>
      <div className={style['icon']} style={value ? {} : {backgroundColor:'var(--red)'}}>
      {iconComponent}
      </div>
      <div className="d-flex flex-column">
        <div className={style['label']}>{label}</div>
        <div>{value ?? '-'}</div>
      </div>
    </div>
  );
};

export default InfoCardSmall;
