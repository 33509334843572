import React, { useEffect, useState  } from "react";
import { useSuggestions } from "../../../Hooks/useSuggestions";
import { SuggestiveResults } from "../../../Components/SuggestiveResults";
import { getSocietyBySearch } from "../../../Services/Society"
import { getMissions, attachConsultantToMission, dissociateConsultantFromMission, getAddMission, editMission, addMission, getCandidaturesMission } from "../../../Services/Mission"
import SearchBar from "../../../Components/SearchBar";
import Loader from "../../../Components/Loader";
import style from "./style.module.css";
import { EMissionStatus } from "../../../Helpers/enums/mission-status.enum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MissionPopup from "../../../Components/Mission/Popup";
import toast from "../../../Helpers/toast";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MissionDashboard from "../../../Components/Dashboard/MissionDashboard";
import { approveApplication, rejectApplication } from "../../../Services/Consultant";

const MesMissions = () => {
  const history = useHistory();
  const location = useLocation();
  const mission = location?.state?.mission;

  const [selectedSociety, setSelectedSociety] = useState(null);
  const [selectedMission, setSelectedMission] = useState(mission ?? null);
  const [applications, setApplications] = useState([]);
  const [isMissionPopup, setIsMissionPopup] = useState(mission ? true : false);
  const [openMissions, setOpenMissions] = useState([]);
  const [associatedMissions, setAssociatedMissions] = useState([]);
  const [promotionalMissions, setPromotionalMissions] = useState([]);
  const [archivedMissions, setArchivedMissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [missionSearch, setMissionSearch] = useState('');

  window.history.replaceState({}, null)

  const [missionStates, setMissionStates] = useState({
    durations:[],
    workRythmns:[],
    workLocations:[]
  });

  const topbarHeight = 90;

  // Style CSS pour la hauteur maximale de la MissionPopup
  const missionPopupStyle = {
    maxHeight: `calc(100vh - ${topbarHeight}px)`,
  };

  const handleMissionSearchSubmit = (search) => {
    setMissionSearch(search)
  }

  const handleEditMission = async (formValues) => {
    return editMission(formValues.id, formValues).then((res) => {
      toast("success", "Mission modifiée");
      return res;
    }).then((res) => {
      if(!formValues.consultant && selectedMission.consultant){
        dissociateConsultantFromMission(res.data.id, formValues).then((resB) => {
          toast("success", "Consultant dissocié");
          updateMissionListAfterEditOrAdd(resB.data);
        })
      } else if (formValues.consultant && !selectedMission.consultant) {
        attachConsultantToMission(res.data.id, formValues).then((resB) => {
          toast("success", "Consultant attaché");
          updateMissionListAfterEditOrAdd(resB.data);
        })
      } else {
        updateMissionListAfterEditOrAdd(res.data);
      }
      setIsMissionPopup(false);
      setSelectedMission(null);
    });
  }

  const handleAddMission = async (formValues) => {
    return addMission(formValues).then((res) => {
      toast("success", "Mission ajoutée");
      return res
    }).then((res) => {
      if(formValues.consultant.id){
        attachConsultantToMission(res.data.id, {...formValues, id: res.data.id}).then((resB) => {
          toast("success", "Consultant attaché");
          updateMissionListAfterEditOrAdd(resB.data);
        })
      } else {
        updateMissionListAfterEditOrAdd(res.data);
      }
      setIsMissionPopup(false);
      setSelectedMission(null);
    })
  }

  const handleEditApplicationList = (application) => {
    setApplications((oldList) => {
      const existingApplicationIndex = oldList.findIndex((a) => {
        return a.id === application.id
      })

      const mergedApplication = {...oldList[existingApplicationIndex], ...application}
      oldList.splice(existingApplicationIndex, 1)
      return [mergedApplication, ...oldList]
    })
  }

  const handleRejectApplication = async (application) => {
    return rejectApplication(application).then((result) => {
      return handleEditApplicationList(result.data);
    })
  }

  const handleApproveApplication = async (application) => {
    return approveApplication(application).then((result) => {
      return handleEditApplicationList(result.data);
    })
  }

  const getMissionListSetter = (mission) => {
    if(mission.isArchived){
      return {callback: setArchivedMissions, key: 'archived'}
    }
    else if(mission.status === EMissionStatus.PROMOTIONAL){
      return {callback: setPromotionalMissions, key: 'associated'}
    }
    else if(mission.consultant){
      return {callback: setAssociatedMissions, key: 'promotional'}
    }
    else {
      return {callback: setOpenMissions, key: 'open'}
    }
  }

  const updateMissionListAfterEditOrAdd = (mission) => {
    const existingMissions = {
      open: openMissions,
      associated: associatedMissions,
      promotional: promotionalMissions,
      archived: archivedMissions,
    };

    const {listKey, existingMissionIndex} = (() => {
      for (const key of Object.keys(existingMissions)) {
        const i = existingMissions[key].findIndex((m) => m.id === mission.id);
        if (i !== -1) {
          return { listKey: key, existingMissionIndex: i };
        }
      };
      return { listKey: undefined, existingMissionIndex: -1 }
    })()

    const newMissionListSetter = getMissionListSetter(mission)

    if(existingMissionIndex !== -1){
      if(newMissionListSetter.key !== listKey){
        const oldMissionListSetter = getMissionListSetter(existingMissions[listKey][existingMissionIndex])
        oldMissionListSetter.callback((oldList) => {
          oldList.splice(existingMissionIndex, 1)
          return [...oldList]
        })
        newMissionListSetter.callback((oldList) => {
          return [mission, ...oldList]
        })
      } else {
        newMissionListSetter.callback((oldList) => {
          oldList.splice(existingMissionIndex, 1, mission)
          return [...oldList]
        })
      }
    } else {
      newMissionListSetter.callback((oldList) => {
        return [mission, ...oldList]
      })
    }
  };


  const handleMissionClick = (mission) => {
    getCandidaturesMission(mission.id).then((res) => {
      setApplications(res.data.applications);
    })
    setSelectedMission(mission)
    setIsMissionPopup(true);
  }

  const switchToEditMissionPopup = (missionId) => {
    if (missionId) {
      history.push(`/missions/editer/${missionId}`);
    } else {
      history.push('/missions/ajouter/');
    }
  }

  const handleAddMissionClick = () => {
    setIsMissionPopup(true);
  }

  const selectSuggestionSocietyHandler = (e) => {
    suggestionModuleSociety.setDisplaySuggestions((oldState) => !oldState);
    setSelectedSociety(e);
  };

  const suggestionModuleSociety = useSuggestions({ callFunction: (s) => getSocietyBySearch(s).then((res) => res.data) });

  useEffect(() => {
    setLoading(true);
    const baseProps = {
      //page: page - 1,
      society: selectedSociety?.id,
      undefined,
      search: missionSearch,
      filterByAuthor: true,
    }
    Promise.all([
    getAddMission().then((res) => {
      setMissionStates({
        durations:res.data.duration,
        workRythmns:res.data.workRythmn,
        workLocations:res.data.workLocation,
      })
    }),
    getMissions({
      ...baseProps,
      status:[EMissionStatus.CLASSIC]
    }).then((res) => {
      const missionsOpened = res?.data?.missions.filter((m) => !m.consultant)
      const missionsAssociated = res?.data?.missions.filter((m) => m.consultant)
      setOpenMissions(missionsOpened);
      setAssociatedMissions(missionsAssociated);
      return
    }),
    getMissions({
      ...baseProps,
      status:[EMissionStatus.PROMOTIONAL]
    }).then((res) => {
      setPromotionalMissions(res.data.missions);
      return
    }),
    getMissions({
      ...baseProps,
      isArchived: true,
    }).then((res) => {
      setArchivedMissions(res.data.missions);
      return
    })]).then((numberOfPages) => {
      setLoading(false);
    })
  }, [
    missionSearch,
    //page,
    selectedSociety,
  ]);

  return (
    <div name='globalDiv' className={`${style['container']} py-4 px-5 gap-3 overflow-hidden `} style={{height: '-webkit-fill-available'}}>
      {isMissionPopup && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.25)', zIndex: 1 }}>
              <div onMouseDown={() => {
                setIsMissionPopup(false);
                setSelectedMission(null);
              }} name='goodDiv' style={{ width:'100%', display: 'flex', justifyContent:'center', alignItems:'center', height:'100%', paddingTop:'10px', paddingBottom:'10px', position: 'fixed', left:'0px', top:'90px', zIndex: 2,...missionPopupStyle  }}>
                {/* This MissionPopup doesn't fit its parents, make it work! */}
                <div onMouseDown={(e) => {
                e.stopPropagation()
              }} style={{overflow: 'hidden', display:'flex', height:'auto', maxHeight:'100%', borderRadius:4, backgroundColor: 'var(--background-color)', zIndex: 3}}>
                  <MissionPopup 
                    closePopup={() => {
                      setIsMissionPopup(false);
                      setSelectedMission(null);
                    }}
                    switchCallback={() => (switchToEditMissionPopup(selectedMission?.id))}
                    mission={selectedMission} 
                    editMissionCallback={handleEditMission} 
                    addMissionCallback={handleAddMission}
                    approveApplicationCallback={handleApproveApplication}
                    rejectApplicationCallback={handleRejectApplication}
                    missionStates={missionStates}
                    applications={applications}
                  />
                </div>
              </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div style={{fontSize: '20px'}}><span className="header-2">Missions</span><span className="header-3"> - Mes missions</span></div>
        <div className="d-flex flex-row gap-3 justify-content-between align-items-end">
          <div style={{width: "50%"}}>
            <label htmlFor="society">Société</label>
            <SearchBar searchValue={selectedSociety?.label ? selectedSociety.label : suggestionModuleSociety.search} onChange={suggestionModuleSociety.onChangeSearch} onClick={() => {
              suggestionModuleSociety.onClickSuggestion();
              setSelectedSociety(null);
            }} placeholder='Rechercher par nom de société (ex: ADEO)'/>
            {suggestionModuleSociety.displaySuggestions && suggestionModuleSociety.suggestions?.length > 0 && (
              <SuggestiveResults
                results={suggestionModuleSociety.suggestions}
                onClick={selectSuggestionSocietyHandler}
              />
            )}
          </div>
          <div style={{width: "50%"}}>
            <label htmlFor='finder'>Mission</label>
            <SearchBar onSubmit={handleMissionSearchSubmit} placeholder='Rechercher par nom de mission (ex: Data Engineer)'/>
          </div>
          <button onClick={handleAddMissionClick} className='default-btn' style={{height:'54px', width:'auto'}} label='Ajouter'>Ajouter</button>
        </div>
      </div>
      {loading ? (
        <div
          className="w-100 position-relative card"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      ) : (
        <MissionDashboard openMissions={openMissions} setOpenMissions={setOpenMissions} promotionalMissions={promotionalMissions} setPromotionalMissions={setPromotionalMissions} 
        associatedMissions={associatedMissions} setAssociatedMissions={setAssociatedMissions} archivedMissions={archivedMissions} setArchivedMissions={setArchivedMissions}
        handleEditMission={handleEditMission} handleMissionClick={handleMissionClick}/>
      )}
    </div>

    )};

export default MesMissions;