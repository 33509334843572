import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "../../Helpers/toast";
import style from "./style.module.css";
import { getAllSkills, addSkill, editSkill, deleteSkill } from "./../../Services/Skills";
import { levenshteinDistance } from "../../Helpers/string"
import { FiX, FiEdit } from "react-icons/fi";
import Popup from "./../../Components/Popup";
import Loader from "./../../Components/Loader/index";
import SearchBar from "../../Components/SearchBar";

const Skills = () => {
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [closeSkills, setCloseSkills] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedSkill, setSelectedSkill] = useState({ label: "" });
  const [existingSkill, setExistingSkill] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [isCreatePopup, setIsCreatePopup] = useState(false);
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const [createInputValue, setCreateInputValue] = useState("");
  const [editInputValue, setEditInputValue] = useState("");

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    getAllSkills()
      .then((res) => {
        setSkills([...res.data]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }
  const openCreatePopup = () => {
    setCloseSkills([])
    setExistingSkill(false);
    setCreateInputValue("");
    setIsCreatePopup(true);
  };

  const openEditPopup = (skill) => {
    setCloseSkills([])
    setExistingSkill(false);
    setEditInputValue(skill.label);
    setSelectedSkill(skill);
    setIsEditPopup(true);
  };

  const openRemovePopup = (skill) => {
    setSelectedSkill(skill);
    setIsRemovePopup(true);
  };

  const isExistingSkill = (skillName) => {
    return skills.find((skill) => {
      if (skill.label.trim().toLowerCase() === skillName.trim().toLowerCase()) {
        return true;
      }
      return false;
    });
  }

  const getCloseSkills = (skillName) => {
    return skills.filter((s) => {
      const distance = levenshteinDistance(skillName.trim().toLowerCase(), s.label.trim().toLowerCase());
      if (distance) return distance < 3;
      return false
    })
  }
  
  const submitAddSkill = (e) => {
    e.preventDefault();
    const existing = isExistingSkill(e.target.createLabel.value);
    setExistingSkill(existing);
    if (!existing) {
      setIsCreatePopup(false);
      addSkill(e.target.createLabel.value.trim()).then(() => {
        toast("success", "La compétence a été créée !");
        reload();
      },
      (error) => {
        toast("error", error.response.data.reason);
      })
      setCreateInputValue("");
    }
  }

  const submitEditSkill = (e) => {
    e.preventDefault();
    const existing = isExistingSkill(e.target.editLabel.value);
    setExistingSkill(existing);
    if (!existing) {
      setIsEditPopup(false);
      editSkill(selectedSkill.id, e.target.editLabel.value.trim()).then(() => {
        toast("success", "La compétence a été modifiée !");
        reload();
      },
      (error) => {
        toast("error", error.response.data.reason);
      });
      setEditInputValue("");
    }
  };

  const submitDeleteSkill = () => {
    deleteSkill(selectedSkill?.id).then(() => {
      toast("success", "La compétence a été supprimé !");
      reload();
    },
    (error) => {
      toast("error", error.response.data.reason);
    });
    setIsRemovePopup(false)
  };

  return (
    
    <div className={`${style['container']} py-4 px-5`}>
    <Popup show={isEditPopup} size="sm" onClose={(e) => setIsEditPopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitEditSkill}
      >
        <div className="col-12 wow-subtitle bold">
          Modifier la compétence "{selectedSkill?.label}"
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom
          </label>
          <input
            id="editLabel"
            name="editLabel"
            className="default-input"
            type="text"
            value={editInputValue}
            onChange={(e) => {  setEditInputValue(e.target.value );
                                const closeSkills = getCloseSkills(e.target.value);
                                setCloseSkills(closeSkills); }}
          />
          {existingSkill ? (
            <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
              Compétence déjà existante.
            </div>
          ) : null}
          {!!closeSkills.length && (
            <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Compétence(s) semblable(s) :
            <div className={style["skill-container"]}>
                {closeSkills.map((sk, i) => {
                  return  <span key={i} className={style["skill"]} style={{paddingRight: "24px"}} >
                            {sk.label}
                          </span>
                })}
              </div>
          </div>
          )}
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setIsEditPopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isCreatePopup} size="sm" onClose={(e) => setIsCreatePopup(false)}>
      <form
        className="row justify-content-between p-3 py-4 gap-2"
        onSubmit={submitAddSkill}
      >
        <div className="col-12 wow-subtitle bold">
          Ajouter une nouvelle compétence
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text" htmlFor="date">
            Nom
          </label>
          <input
            id="createLabel"
            name="createLabel"
            className="default-input"
            type="text"
            value={createInputValue}
            onChange={(e) => {  setCreateInputValue(e.target.value );
                                const closeSkills = getCloseSkills(e.target.value);
                                setCloseSkills(closeSkills); }}
          />
          {existingSkill ? (
            <div className={`small-text red-text mt-1 ${style['error-bar-popup']}`}>
              Compétence déjà existante.
            </div>
          ) : null}
          {!!closeSkills.length && (
            <div className={`small-text mt-1 ${style['error-bar-popup']}`}>
            Compétence(s) semblable(s) :
            <div className={style["skill-container"]}>
                {closeSkills.map((sk, i) => {
                  return  <span key={i} className={style["skill"]} style={{paddingRight: "24px"}} >
                            {sk.label}
                          </span>
                })}
              </div>
          </div>
          )}
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button
            className="default-btn danger-btn p-2"
            type="button"
            onClick={(e) => setIsCreatePopup(false)}
          >
            Annuler
          </button>
          <button className="default-btn p-2" type="submit">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
    <Popup show={isRemovePopup} size="sm" onClose={(e) => setIsRemovePopup(false)}>
      <div className="row justify-content-between p-3 py-4 gap-2">
        <div className="wow-subtitle bold">
          Supprimer la compétence "{selectedSkill?.label}"
        </div>
        <div>
          Confirmer pour supprimer cette compétence.
        </div>
        <div className="d-flex flex-row gap-5 pt-4">
          <button className="default-btn danger-btn p-2" onClick={(e) => setIsRemovePopup(false)}>
            Annuler
          </button>
          <button type="button" className="default-btn p-2" onClick={submitDeleteSkill}>
            Confirmer
          </button>
        </div>
      </div>
    </Popup>
        <div className="mb-4">
          <div className="header-2">Toutes les compétences</div>
          <div className="d-flex flex-row gap-2">
            <SearchBar onSubmit={setSearch} placeholder='.NET, Scrum,...'/>
            <button style={{width:'120px'}} className="default-btn p-2" onClick={openCreatePopup}>
              Ajouter
            </button>
          </div>
        </div>
        <div>
          <div className="col-12 flex-column gap-3 align-items-center">
            {loading ? (
              <div
                className="w-100 position-relative card"
                style={{ height: "300px" }}
              >
                <Loader />
              </div>
            ) : skills.length ? (
              <div className={style["skill-container"]}>
                {skills.filter(skill => {
                  return (skill.label.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                }).map((sk, i) => {
                  return <span key={i} className={style["skill"]}>
                    {sk.label}
                    <FiEdit
                      className={`${style["edit-skill"]} ${style["rotate-icon"]}`}
                      onClick={(e) => openEditPopup(sk)}
                    />
                    <FiX 
                      className={`${style["delete-skill"]} ${style["rotate-icon"]}`}
                      onClick={(e) => openRemovePopup(sk)}
                    />
                  </span>
                })}
              </div>
            ) : (
              <div className="w-100 card p-5 d-flex flex-row justify-content-center small-text">
                Aucune compétence
              </div>
            )}
          </div>
        </div>
    </div>
  );
};

export default Skills;
