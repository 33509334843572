import React from "react";
import { CandidaturesTables } from "../../../Components/CandidaturesTable";

export const Candidatures = () => {
  return (
    <div className="row card p-5 gap-5">
      <div className="col-12 wow-subtitle green-text">Ses candidatures</div>
      <div className="col-12">
        <CandidaturesTables />
      </div>
    </div>
  );
};
