import { ProtectedApi } from "./../Helpers/api";
import { ADMIN } from "./User";

export const getMissionsNames = (id) => {
  return ProtectedApi.get(`${ADMIN}/mission/names?id=${id}`);
};
export const getAddMission = () => {
  return ProtectedApi.get(`${ADMIN}/mission/add/filters`);
};
export const addMission = (formData) => {
  return ProtectedApi.post(`${ADMIN}/mission`, formData);
};
export const editMission = (id, formData) => {
  return ProtectedApi.patch(`${ADMIN}/mission/${id}`, formData);
};
export const getFiltersMissions = ({ page }) => {
  return ProtectedApi.get(`${ADMIN}/mission/dashboard/filters?p=${page}`);
};
export const getMissions = ({ page, society, status, search, orderBy, filterByAuthor, isArchived }) => {
  const url =
    `${ADMIN}/mission/dashboard/admin` +
    (page !== undefined ? `?p=${page}` : "");
  return ProtectedApi.get(url, {
    params: {
      society,
      status,
      search,
      orderBy,
      filterByAuthor,
      isArchived
    },
  });
};

export const getMissionsBySocietyId = (societyId, orderBy) => {
  const url =
    `${ADMIN}/mission/society/active?societyId=${societyId}` +
    (orderBy !== undefined ? `&orderBy=${orderBy}` : "");
  return ProtectedApi.get(url);
};

export const getMission = (id) => {
  return ProtectedApi.get(`${ADMIN}/mission/${id}`);
};
export const deleteMission = (id) => {
  return ProtectedApi.put(`${ADMIN}/mission/${id}`);
};

export const getCandidaturesMission = (id) => {
  return ProtectedApi.get(`${ADMIN}/application/mission/${id}`);
};

export const attachConsultantToMission = (id, payload) => {
  return ProtectedApi.patch(`${ADMIN}/mission/${id}/attach`, payload);
};

export const dissociateConsultantFromMission = (id, payload) => {
  return ProtectedApi.patch(`${ADMIN}/mission/${id}/dissociate`, payload);
};
