import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router";
import toast from "../../../Helpers/toast";
import CustomCheckbox from "./../../../Components/CustomCheckbox/index";
import {
  addManager,
  findManagerBySearch, getAddManager
} from "./../../../Services/Manager";
import { getSkills } from "./../../../Services/Skills";
import style from "./style.module.css";

const AjouterManager = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [managerType, setManagerType] = useState({});

  const [searchDataSkills, setSearchDataSkills] = useState([]);
  const [searchDataManagers, setSearchDataManagers] = useState([]);
  const [skills, setSkills] = useState([]);

  // const [members, setMembers] = useState(["Audric"]);
  const [managers, setManagers] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      linkedInUri: "",
      job: "",
      department: "",
      status: Object.keys(managerType)[0] || "",
      site: "",
      notes: "",
      comments: "",
      shouldReceiveTimesheet: false,
    },
    onSubmit: (values) => {
      var state = { ...values };
      const formToSend = {
        ...state,
        skills: [...skills],
        affiliateWowCommercials: [...managers],
      };

      addManager(formToSend, match.params.id).then((res) => {
        history.push(`/client/${match.params.id}/carnet-adresses`);
        toast("success", "Le manager a été ajouté");
      });
    },
  });

  const onAddSkill = (skill) => {
    const exist = skills.reduce((prev, cur) => {
      return prev || cur.label === skill.label;
    }, false);
    if (!exist) {
      setSkills([...skills, { ...skill }]);
    }
    setSearchDataSkills([]);
  };

  const onDeleteSkill = (skill) => {
    setSkills(skills.filter((s) => s.label !== skill.label));
  };

  const onAddManager = (manager) => {
    onCloseSearchManagers();
    if (managers.includes(manager)) {
      return;
    }
    setManagers((oldState) => [...oldState, manager]);
  };

  const onDeleteManager = (manager) => {
    setManagers((oldState) =>
      oldState.filter(
        (m) => m.email + m.firstName !== manager.email + manager.firstName
      )
    );
  };

  const onSearch = (search) => {
    getSkills(search).then((res) => setSearchDataSkills(res.data));
  };
  const onCloseSearch = () => {
    setSearchDataSkills([]);
  };

  const searchManager = (e) => {
    findManagerBySearch(e.target.value).then((res) => {
      setSearchDataManagers(res.data);
    });
  };

  const onCloseSearchManagers = () => {
    setSearchDataManagers([]);
  };

  // const addMember = (e) => {
  //   if (formik.values.member !== "") {
  //     setMembers([...members, formik.values.member]);
  //     formik.setFieldValue("member", "");
  //   }
  // };

  // const deleteMember = (i) => {
  //   setMembers(members.filter((t, index) => index !== i));
  // };

  useEffect(() => {
    getAddManager().then((res) => {
      setManagerType(res.data);
    });
  }, []);

  return (
    <form className="container py-4 px-5 border-light-grey" onSubmit={formik.handleSubmit}>
      <div className="row card px-4 py-4 gap-4">
        <div className="header-4 green-text">Ajouter un contact</div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="lastName"
            >
              Nom
            </label>
            <input
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="firstName"
            >
              Prénom
            </label>
            <input
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
              required
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="phoneNumber"
            >
              Numéro téléphone
            </label>
            <input
              id="phoneNumber"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              className="default-input"
              type="number"
              required
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label
            className="small-text bold cursor-pointer"
            htmlFor="linkedInUri"
          >
            Url LinkedIn
          </label>
          <input
            id="linkedInUri"
            name="linkedInUri"
            value={formik.values.linkedInUri}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
          />
        </div>
        <div className="col-12 d-flex flex-row gap-4">
          <div className="w-100 d-flex flex-column gap-2">
            <label className="small-text bold cursor-pointer" htmlFor="job">
              Fonction
            </label>
            <input
              id="job"
              name="job"
              value={formik.values.job}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
            />
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            <label
              className="small-text bold cursor-pointer"
              htmlFor="department"
            >
              Département
            </label>
            <input
              id="department"
              name="department"
              value={formik.values.department}
              onChange={formik.handleChange}
              className="default-input"
              type="text"
            />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          {searchDataSkills.length > 0 && (
            <div
              className="close-search-bg"
              onClick={onCloseSearch}
            ></div>
          )}
          <div className="small-text bold">Ajoutez des compétences</div>
          <div className="w-100 position-relative">
            <FiSearch className={style["search-icon"]} />
            <input
              type="text"
              onChange={(e) => {
                if (e.target.value !== "") {
                  onSearch(e.target.value);
                } else {
                  onCloseSearch();
                }
              }}
              className={style["search-input"] + " default-input"}
              placeholder="Rechercher ici des compétences"
            />
            <div className="search-content">
              {searchDataSkills.map((d, i) => (
                <div key={i} onClick={(e) => onAddSkill(d)}>
                  {d.label}
                </div>
              ))}
            </div>
          </div>
          <div className="w-100 d-flex flex-row flex-wrap gap-3">
            {skills.map((s, i) => (
              <span key={i} className={style["skill"]}>
                {s.label}
                <FiX
                  className={style["delete-skill"]}
                  onClick={(e) => onDeleteSkill(s)}
                />
              </span>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="site">
            Site
          </label>
          <input
            id="site"
            name="site"
            value={formik.values.site}
            onChange={formik.handleChange}
            className="default-input"
            type="text"
            
          />
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <label className="small-text bold cursor-pointer" htmlFor="status">
            Statut
          </label>
          <select
            id="status"
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            className="default-input"
          >
            {Object.keys(managerType).map((m, i) => {
              return (
                <option key={i} value={m}>
                  {managerType[m]}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-12 d-flex flex-row gap-3 align-items-end">
          <div className="w-100 d-flex flex-column gap-3">
            {searchDataManagers.length > 0 && (
              <div
                className="close-search-bg"
                onClick={onCloseSearchManagers}
              ></div>
            )}
            <label className="small-text bold" htmlFor="member">
              WOW team member(s)
            </label>
            <div className="w-100 d-flex flex-row align-items-center gap-2">
              {/* KEYWORD */}
              <div className="w-100 position-relative">
                <FiSearch className={style["search-icon"]} />
                <input
                  type="text"
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      searchManager(e);
                    } else {
                      onCloseSearchManagers();
                    }
                  }}
                  className={style["search-input"] + " default-input"}
                  placeholder="Rechercher un membre de l'équipe"
                />
                <div className="search-content">
                  {searchDataManagers.length > 0 &&
                    searchDataManagers.map((d, i) => (
                      <div
                        className={style.hoverManager}
                        key={i}
                        onClick={(e) => onAddManager(d)}
                      >
                        {d.firstName} {d.lastName}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-3 flex-wrap">
          <div className="w-100 d-flex flex-row flex-wrap gap-3">
            {managers.map((s, i) => (
              <span key={i} className={style["skill"]}>
                {s.firstName} {s.lastName}
                <FiX
                  className={style["delete-skill"]}
                  onClick={(event) => onDeleteManager(s)}
                />
              </span>
            ))}
          </div>
        </div>
        <div className="col-12 d-flex flex-row gap-3 flex-wrap">
          <CustomCheckbox
            onChange={formik.handleChange}
            value={formik.values.shouldReceiveTimesheet}
            name="shouldReceiveTimesheet"
            label="Envoi du CRA"
          />
          {formik.values.shouldReceiveTimesheet && (
            <span className="bold">
              Attention, en cochant cette case, le manager recevra le CRA par
              mail.
            </span>
          )}
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <div className="small-text bold" htmlFor="comments">
            Informations sur le contact
          </div>
          <textarea
            id="comments"
            name="comments"
            value={formik.values.comments}
            onChange={formik.handleChange}
            className="text-area default-input"
            type="text"
          />
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <div className="small-text bold" htmlFor="notes">
            Notes
          </div>
          <textarea
            id="notes"
            name="notes"
            value={formik.values.notes}
            onChange={formik.handleChange}
            className="text-area default-input"
            type="text"
          />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div>
            <button type="submit" className="default-btn">
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AjouterManager;
