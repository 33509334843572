import { getSkills } from "../../Services/Skills";
import style from './style.module.css';
import { FiX } from "react-icons/fi";
import SearchBar from "../SearchBar";
import { SuggestiveResults } from "../SuggestiveResults";
import { useSuggestions } from "../../Hooks/useSuggestions";

const Skills = ({onChangeSkillsHandler, consultantSkills, placeholder, titleHidden = false, inputBackgroundColor = "white", backgroundColor}) => {

    const suggestionModuleSkill = useSuggestions({ callFunction: (s) => {
        return getSkills(s).then((res) => res.data);
    }});

    const addSkill = (skillToAdd) => {
        const skills = [...consultantSkills, skillToAdd];
        onChangeSkillsHandler(skills);
        suggestionModuleSkill.setDisplaySuggestions(false);
    }

    const onDeleteSkill = (skill) => {
        const skills= consultantSkills.filter((s) => s.label !== skill.label);
        onChangeSkillsHandler(skills);
    };

    return <div className="col-12 d-flex flex-column gap-2 w-100 position-relative">
        {!titleHidden && (<label className="small-text bold cursor-pointer" htmlFor="skills">Compétences</label>)}
        <div className="w-100 position-relative" style={{inputBackgroundColor}}>
        <SearchBar searchValue={suggestionModuleSkill.search} onChange={suggestionModuleSkill.onChangeSearch} onClick={() => {
            suggestionModuleSkill.onClickSuggestion();
          }} placeholder='Rechercher par nom de compétences (ex: Node.JS)'/>
          {suggestionModuleSkill.displaySuggestions && suggestionModuleSkill.suggestions?.length > 0 && (
            <div
            style={{ zIndex:10}}>
                <SuggestiveResults
                results={suggestionModuleSkill.suggestions}
                onClick={addSkill}
                />
            </div>
          )}
        </div>
        {consultantSkills.length > 0 && (<div className="w-100 d-flex flex-row flex-wrap gap-3">
            {consultantSkills.map((s, i) => (
                <div 
                onClick={(e) => onDeleteSkill(s)}
                key={i} style={{cursor: 'pointer', backgroundColor}} className={style["skill"]}>
                    <span>{s.label}</span>
                    <FiX
                        className={style["delete-skill"]}
                    />
                </div>
            ))}
        </div>)}
    </div>
}


export default Skills;