import { useEffect, useState } from "react";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { getClientsUnconstrained } from "../../../Services/Comptes";
import { getRoles, patchSocietyVisibility } from "../../../Services/Dashboard";
import { MultiSelect } from "../../../UI/MultiSelect";
import Loader from "../../Loader";
import styles from "./style.module.css";
export const SettingsClient = () => {
  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getClientsUnconstrained()
      .then((res) => {
        setClients([...res.data]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });

    getRoles().then((res) => setRoles(res.data));
  }, []);

  const roleMultiSelect = (roles || []).map((role) => ({
    optKey: role.id,
    optValue: role.authority,
  }));

  const onSelectOptionHandler = (id, options) => {
    const selectedRoles = roles.filter((role) =>
      options.find((opt) => opt.optKey === role.id)
    );
    patchSocietyVisibility({ clientId: id, roles: selectedRoles });
  };

  const getSelectedOptionInit = (client) => {
    return (client.roles || []).map((role) => ({
      optKey: role.id,
      optValue: role.authority,
    }));
  };

  return (
    <div className="w-100 m-auto">
      {loading && (
        <div
          className="w-100 position-relative card"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      )}
      {!loading && (
        <div>
          <h2 className="pb-4">Confidentialité des clients</h2>
          {clients.map((client, indexI) => (
            <div key={indexI} className="w-75 m-auto">
              <div className="flex d-flex w-100 justify-content-between align-items-center my-2">
                <div>
                  {client.logo ? (
                    <img
                      className={`${styles["img"]}`}
                      src={b64ToObjectUrl(
                        client.logo.base64Document,
                        client.logo.filetype
                      )}
                      alt="client logo"
                    />
                  ) : (
                    <img
                      className={`${styles["img"]}`}
                      src={"/wow-circle-white.svg"}
                      alt="wheel of work logo"
                    />
                  )}
                </div>
                <div className="text-center">{client.label}</div>
                <MultiSelect
                  placeholder={"Qui peut accéder"}
                  options={roleMultiSelect}
                  selectedOptionsInit={() => getSelectedOptionInit(client)}
                  onSelectFn={(options) =>
                    onSelectOptionHandler(client.id, options)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
