export const genders = {
  MALE: "Homme",
  FEMALE: "Femme",
  OTHER: "Ne se prononce pas",
};
export const durations = {
  SHORT: "Courte (<6mois)",
  MEAN: "Moyenne (entre 6 et 12 mois)",
  LONG: "Longue (>12mois)",
};
export const legalStatuses = {
  auto_entrepreneur: "Auto entrepreneur",
  self_employed: "Auto entrepreneur",
  society: "Société",
  wage_porting: "Portage salarial",
};
export const legalFilesStatuses = {
  PENDING: "En attente",
  REFUSED: "Refusé",
  APPROVED: "Approuvé",
};
export const contractsStatuses = {
  EXPIRED: "Expiré",
  ONGOING: "En cours",
};
export const contractsTypes = {
  CONTRACT: "Contrat",
  AVENANT: "Avenant",
};
export const timesheetStatuses = {
  MODIFYING: "Non soumis",
  PENDING_APPROVAL: "En attente d'approbation",
  PENDING: "En attente",
  ADMIN_APPROVED: "Approuvé",
  REFUSED: "Refusé",
};
export const factureStatuses = {
  PAYED: "Payée",
  PENDING: "En attente",
  APPROVED: "Approuvée",
  REFUSED: "Refusée",
  MODIFYING: "Modifiée"
};

export const mobilityLabels = {
  CITY: "Ville",
  DEPARTMENT: "Région",
  COUNTRY: "Pays",
  INTERNATIONAL: "International",
  TELETRAVAIL: "Télétravail",
};

export const applicationStatuses = {
  PENDING: "A l'étude",
  APPROVED: "Validée",
  REJECTED: "Non retenue",
};
export const legalDocumentsNames = {
  RIB: "RIB",
  DOMICILE: "Justificatif de domicile",
  URSSAF: "Attestation URSSAF",
  ASSURANCE_RESPO_CIVILE:
    "Attestation de souscription à une assurance de responsabilité civile professionnelle",
  INSEE: "Avis de situation INSEE",
  FISCALE: "Attestation fiscale",
  KBIS: "Extrait de Kbis",
  CONTRAT_TRAVAIL:
    "Contrat de travail réalisé par la société de portage salarial",
};

export const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
