import { useState } from "react";
import { durations, legalStatuses } from "../../../Helpers/data";
import { TextField } from "../../TextField";

export const MissionSelection = ({ currentConsultant }) => {
  const [currentMissionIndex, setIndex] = useState(0);

  return (
    <div className="col w-100 d-flex flex-column gap-2">
      {/* Troisieme section */}
      {currentConsultant.missions[0] && (
        <div className="card d-flex flex-column gap-3 p-4">
          <div className="small-text">Choisir la mission</div>
          <select
            name="mission"
            id="mission"
            onChange={(e) => setIndex(e.target.value)}
            value={currentMissionIndex}
            className="default-input"
          >
            {currentConsultant.missions.map((m, i) => {
              return (
                <option key={i} value={i}>
                  {m.label}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <div className="card w-100 gap-2 p-4">
        <h2>Mission</h2>

        {/* {currentConsultant?.missions[0] && (
          <EditButton
            buttonTitle={"Editer"}
            url={`/client/${currentConsultant.missions[currentMissionIndex].society.id}/mission/${currentConsultant.missions[currentMissionIndex].id}/modifier`}
            style={{ position: "absolute", right: "2em" }}
          />
        )} */}

        {currentConsultant.missions[0] ? (
          <div className="w-100 gap-4 flex d-flex">
            <div className="w-100">
              <TextField
                label={"Entreprise actuelle"}
                value={
                  currentConsultant.missions[currentMissionIndex].society.label
                }
              />
              <TextField
                label={"Site d'entreprise"}
                value={
                  currentConsultant.missions[currentMissionIndex].address.city
                }
              />
              <TextField
                label={"Statut"}
                value={
                  currentConsultant.society?.juridicStructure &&
                  legalStatuses[currentConsultant.society.juridicStructure]
                }
              />
              <TextField
                label={"Intitulé mission"}
                value={currentConsultant.missions[currentMissionIndex].label}
              />
              <TextField
                label={"Manager"}
                value={
                  currentConsultant.missions[currentMissionIndex].manager
                    ? `${currentConsultant.missions[currentMissionIndex].manager.lastName} ${currentConsultant.missions[currentMissionIndex].manager.firstName}`
                    : "Non renseigné"
                }
              />
            </div>

            <div className="w-100">
              <TextField
                label={"TJM achat"}
                value={`${currentConsultant.missions[currentMissionIndex].dailyAverage}€`}
              />

              <TextField
                label={"TJM revente"}
                value={`${currentConsultant.missions[currentMissionIndex].dailyAverageResale}€`}
              />

              <TextField
                label={"Marge"}
                value={`${Number(
                  currentConsultant.missions[currentMissionIndex]
                    .dailyAverageResale -
                    currentConsultant.missions[currentMissionIndex].dailyAverage
                )}€`}
              />

              <TextField
                label={"Durée de la mission"}
                value={
                  durations[
                    currentConsultant.missions[currentMissionIndex].duration
                  ]
                }
              />
            </div>
          </div>
        ) : (
          <div className="small-text">Aucune mission</div>
        )}
      </div>
    </div>
  );
};
