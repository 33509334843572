import { ProtectedApi } from "./../Helpers/api";

export const getSkills = async (search) => {
  return ProtectedApi.get(`/skills/search`, {
    params: {
      q: search,
    },
  });
};

export const getAllSkills = async () => {
  return ProtectedApi(`/skills`);
};
export const addSkill = async (label) => {
  return ProtectedApi.post(`/skills`, { label: label });
};
export const editSkill = async (id, label) => {
  return ProtectedApi.patch(`/skills/` + id, { label: label });
};
export const deleteSkill = async (id) => {
  return ProtectedApi.delete(`/skills/` + id);
};
