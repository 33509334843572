import React, { useState } from "react";
import { FiMail, FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { patchAdminRole } from "../../Services/Dashboard";
import styles from "./style.module.css";
const AdminCard = ({
  admin,
  deleteAdmin,
  deactivated,
  activateAdmin,
  roles,
}) => {
  const [selectedRole, setSelectedRole] = useState(admin.role.id);

  const onChangeRoleHandler = (e) => {
    setSelectedRole(e.target.value);
    patchAdminRole({ userId: admin.id, roleId: e.target.value });
  };

  return (
    <div
      className="w-100 card d-flex flex-row p-4 justify-content-between align-items-center"
      style={deactivated ? { backgroundColor: "#E3E3E3" } : {}}
    >
      <div className="d-flex flex-column gap-3">
        <div className="w-100 d-flex flex-row gap-2">
          <div className="d-flex flex-column gap-2">
            <div className="wow-subtitle bold">
              {admin.firstName + " " + admin.lastName}
            </div>
          </div>
        </div>
        <div className="w-100 small-text px-2">
          <FiMail /> {admin.email}
        </div>
        <div className="w-100 small-text px-2">
          <FiPhone /> {admin.phoneNumber}
        </div>
        <div className={`flex d-flex ${styles["div"]}`}>
          <select
            className="default-input"
            value={selectedRole}
            onChange={onChangeRoleHandler}
          >
            {roles.map((role, index) => (
              <option key={index} value={role.id}>
                {role.authority}
              </option>
            ))}
          </select>
        </div>
      </div>
      {deactivated ? (
        <div className="d-flex flex-column gap-3">
          <button
            className="default-btn"
            onClick={() => {
              activateAdmin(admin);
            }}
          >
            Réactiver cet admin
          </button>
        </div>
      ) : (
        <div className="d-flex flex-column gap-3">
          <Link to={`/create-admin/add/${admin.id}`}>
            <button className="default-btn">Modifier cet admin</button>
          </Link>
          <button
            className="default-btn danger-btn"
            onClick={() => {
              deleteAdmin(admin);
            }}
          >
            Désactiver cet admin
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminCard;
