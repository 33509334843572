import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CronMenu } from "./Components/CronMenu";
import Layout from "./Layout";
import ClientLayout from "./Layout/ClientLayout/index";
import ConsultantLayout from "./Layout/ConsultantLayout/index";
import SignIn from "./Pages/Auth/SignIn";
import AjouterManager from "./Pages/Client/AjouterManager";
import CarnetAdresses from "./Pages/Client/CarnetAdresses";
import ContratEtBonDeCommande from "./Pages/Client/ContratEtBonDeCommande";
import ModifierManager from "./Pages/Client/ModifierManager/index";
import AjouterClient from "./Pages/Clients/AjouterClient/index";
import Comptes from "./Pages/Clients/Comptes";
import ModifierClient from "./Pages/Clients/ModifierClient/index";
import { Candidatures } from "./Pages/Consultant/Candidatures";
import Documents from "./Pages/Consultant/Documents";
import FinDuMoisConsultant from "./Pages/Consultant/FinDuMois";
import IdentiteConsultant from "./Pages/Consultant/Identite/index";
import ModifierConsultant from "./Pages/Consultant/ModifierConsultant/index";
import { Consultants } from "./Pages/Consultants";
import { Matching } from "./Pages/Matching";

import AddAdmin from "./Pages/CreateAdmin/AddAdmin";
import CreateAdmin from "./Pages/CreateAdmin/index";
import Dashboard from "./Pages/Dashboard";
import AjouterMission from "./Pages/Missions/AjouterMission";
import ModifierMission from "./Pages/Missions/ModifierMission/index";
import MesMissions from "./Pages/Missions/MesMissions/index";
import FicheMission from "./Pages/Missions/FicheMission/index";
import NouvelleMission from "./Pages/Missions/NouvelleMission/index";
import Missions from "./Pages/Missions/Missions/index";
import Skills from "./Pages/Skills";
import "./Styles/Base.css";
import { SettingsClientsPage } from "./Pages/Settings/Clients";
import Jobs from "./Pages/Jobs";
import JobDepartments from "./Pages/JobDepartments";

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {/* Dashboard */}
          <Route path="/" exact>
            <Dashboard />
          </Route>
          {/* Missions */}
          <Route path="/missions/mes-missions" exact>
            <MesMissions />
          </Route>
          <Route path="/missions/toutes-les-missions" exact>
            <Missions />
          </Route>
          <Route path="/missions/editer/:id/" exact>
            <FicheMission />
          </Route>
          <Route path="/missions/ajouter/" exact>
            <NouvelleMission />
          </Route>
          {/* Consultant */}
          <Route path="/consultant/:id/modifier" exact>
            <ModifierConsultant />
          </Route>
          <Route path="/consultant/:id">
            <ConsultantLayout>
              <Switch>
                <Route path="/consultant/:id" exact>
                  <IdentiteConsultant />
                </Route>
                <Route path="/consultant/:id/candidatures" exact>
                  <Candidatures />
                </Route>
                <Route path="/consultant/:id/documents" exact>
                  <Documents />
                </Route>
                <Route path="/consultant/:id/fin-de-mois" exact>
                  <FinDuMoisConsultant />
                </Route>
              </Switch>
            </ConsultantLayout>
          </Route>
          {/* Modifier un client */}
          <Route path="/client/:id/modifier" exact>
            <ModifierClient />
          </Route>
          {/* Client */}
          <Route path="/client/:id">
            <ClientLayout>
              <Switch>
                <Route path="/client/:id/carnet-adresses" exact>
                  <CarnetAdresses />
                </Route>
                <Route
                  path="/client/:id/carnet-adresses/:idManager/modifier"
                  exact
                >
                  <ModifierManager />
                </Route>
                <Route path="/client/:id/contrats-bdc" exact>
                  <ContratEtBonDeCommande />
                </Route>
                <Route path="/client/:id/mission/:id/modifier" exact>
                  <ModifierMission />
                </Route>
                <Route path="/client/:id/ajouter-mission" exact>
                  <AjouterMission />
                </Route>
                {/* Ajouter un Manager */}
                <Route path="/client/:id/ajouter-manager" exact>
                  <AjouterManager />
                </Route>
              </Switch>
            </ClientLayout>
          </Route>
          {/* Clients */}
          <Route path="/clients/comptes" exact>
            <Comptes />
          </Route>
          <Route path="/clients/ajouter-client" exact>
            <AjouterClient />
          </Route>
          {/* Consultants */}
          <Route path="/consultants" exact>
            <Consultants />
          </Route>
          {/* Matching Consultants */}
          <Route path="/matching" exact>
            <Matching />
          </Route>
          {/* Jobs */}
          <Route path="/jobs" exact>
            <Jobs />
          </Route>
          {/* Job Departments */}
          <Route path="/job-departments" exact>
            <JobDepartments />
          </Route>
          {/* Compétences  */}
          <Route path="/skills" exact>
            <Skills />
          </Route>
          {/* Auth */}
          <Route path="/auth/sign-in">
            <SignIn />
          </Route>
          <Route path="/auth" render={() => <Redirect to="/auth/sign-in" />} />
          {/* Secret Menu Cron */}
          <Route path="/secret-menu-cron" exact>
            <CronMenu />
          </Route>
          {/* CreateAdmin */}
          <Route path="/create-admin" exact>
            <CreateAdmin />
          </Route>
          {/* AddAdmin */}
          <Route path="/create-admin/add/" exact>
            <AddAdmin />
          </Route>
          {/* EditAdmin */}
          <Route path="/create-admin/add/:id" exact>
            <AddAdmin />
          </Route>
          <Route path="/clients-settings" exact>
            <SettingsClientsPage />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
