import React from 'react'
import style from './style.module.css'


const Loader = ()=>{
return <div className={style['loader']}>
    <span></span>
</div>
}

export default Loader