import React from "react";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import { Actions } from "../../Actions";
import style from "../style.module.css";

const ListItem = ({ contract, onDelete, openPopup }) => {
  const actions = [
    {
      label: "Supprimer le contrat",
      color: "red",
      action: () => onDelete(contract, "admin_contract", "BDC"),
    },
    {
      label: "Modifier le contrat",
      color: "black",
      action: () => openPopup(1, contract),
    },
  ];
  return (
    <>
      <div className={`${style["contracts-table-item"]}`}>
        <div className="small-text">{contract.consultant.user.fullName}</div>
        <div className="small-text">{contract.mission.label}</div>
        <div className="small-text">
          <a
            className="text-decoration-underline cursor-pointer"
            href={
              contract?.file &&
              b64ToObjectUrl(
                contract.file.base64Document,
                contract.file.filetype
              )
            }
            target="_blank"
            rel="noreferrer"
          >
            {contract?.file?.filename}
          </a>
        </div>
        <div className="small-text">
          {contract?.signatureDate &&
            new Date(contract.signatureDate).toLocaleDateString()}
        </div>
        <div className="small-text">
          {new Date(contract.startDate).toLocaleDateString()}
        </div>
        <div className="small-text">
          {new Date(contract.expirationDate).toLocaleDateString()}
        </div>
        <div className="small-text">{contract.dailyRateAverage}</div>
        <div className="small-text">
          {contract.consultant?.affiliateManager
            ? contract.consultant?.affiliateManager?.fullName
            : "Non renseigné"}
        </div>
        <div className="small-text">{contract.reference}</div>
        <div>
          <div className={`${style["status"]}`} status={contract.status}></div>
        </div>
        <div>
          <Actions myActions={actions} />
        </div>
      </div>
    </>
  );
};

export default ListItem;
