import React from "react";
import style from "./style.module.css";

const Timeline = ({ items, onCheckboxChange }) => {
  const hasElements = (array) => {
    return array.length > 0;
  };

  const hasBotLabelElements = () => {
    return hasElements(items.filter((i) => !!i.botLabel));
  };

  return (
    <div className={style["timeline"]}>
      {items.map((item, index) => (
        <div key={index} className={style["timeline-item"]}>
          <div className={style["custom-checkbox"]}>
            <div>{item.topLabel}</div>
            <input
              value={item.value}
              type="checkbox"
              style={{ border: `2px solid ${item.color}` }}
              checked={item.checked}
              disabled={item?.disabled}
              onChange={() => onCheckboxChange(item.value)}
            />
            {hasBotLabelElements() && (
              <div
                style={{
                  height: "24px",
                  fontSize: 12,
                  fontWeight: 500,
                  width: "max-content",
                }}
              >
                {item.botLabel}
              </div>
            )}
          </div>
          {index < items.length - 1 && (
            <div
              className={style["line-connector"]}
              style={{
                background: `linear-gradient(to right,  ${item.color} 0%,${
                  items[index + 1].color
                } 100%)`,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Timeline;
