import React, { useState } from "react";
import style from "../style.module.css";
import { FiCheckCircle, FiMoreVertical, FiXSquare } from "react-icons/fi";
import { factureStatuses, months } from "../../../Helpers/data";
import Popup from "./../../Popup/index";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import toast from "../../../Helpers/toast";

const ListItem = ({
  facture,
  onApprove = (f) => f,
  onReject = (f) => f,
  onPay = (f) => f,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [confirmApprove, setConfirmApprove] = useState(false);
  return (
    <>
      <div className={`${style["factures-table-item"]}`}>
        <div className="small-text bold text-truncate">
          Facture {months[facture.month - 1]} {facture.year}
        </div>
        <a
          href={
            facture.file &&
            b64ToObjectUrl(facture.file.base64Document, facture.file.filetype)
          }
          target="_blank"
          rel="noreferrer"
          className="small-text d-none d-md-flex blue-text cursor-pointer"
        >
          {facture.file && "Voir"}
        </a>
        <div className="small-text d-none d-md-flex">
          {facture.createdAt &&
            new Date(facture.createdAt).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {facture.paymentDate &&
            new Date(facture.paymentDate).toLocaleDateString()}
        </div>
        <div
          className={`${style["factures-status"]} small-text`}
          status={facture.status}
        >
          {factureStatuses[facture.status]}
        </div>
        <div className="header-4 green-text text-center">
          <FiMoreVertical
            className="cursor-pointer"
            onClick={(e) => setShowMore(true)}
          />
        </div>
        {showMore && (
          <>
            <div
              className={`${style["show-more"]} d-flex flex-column align-items-center py-3 gap-3`}
            >
              <div
                className="small-text green-text cursor-pointer"
                onClick={(e) => {
                  setConfirmApprove(true);
                  setShowMore(false);
                }}
              >
                <FiCheckCircle className="mx-1" /> Valider la facture
              </div>
              <div
                className="small-text green-text cursor-pointer"
                onClick={(e) => {
                  onPay(facture.id).then(() => {
                    setShowMore(false);
                    toast("success", "La facture a été payée");
                  });
                }}
              >
                <FiCheckCircle className="mx-1" />
                Payer la facture
              </div>
              <div
                className="small-text red-text cursor-pointer"
                onClick={(e) => {
                  onReject(facture.id).then(() => {
                    setShowMore(false);
                    toast("success", "La facture a été rejetée");
                  });
                }}
              >
                <FiXSquare className="mx-1" />
                Rejeter la facture
              </div>
            </div>
            <div
              className={`${style["close-show-more"]}`}
              onClick={(e) => setShowMore(false)}
            ></div>
          </>
        )}
      </div>
      {confirmApprove && (
        <Popup
          size="sm"
          show={confirmApprove}
          onClose={(e) => setConfirmApprove(false)}
        >
          <div className="row p-4 gap-4 justify-content-center">
            <div className="col-12 small-text">
              Êtes-vous sûr(e) de vouloir valider la facture ?
            </div>
            <div className="col-5">
              <button
                className="default-btn danger-btn"
                onClick={(e) => setConfirmApprove(false)}
              >
                Annuler
              </button>
            </div>
            <div className="col-5">
              <button
                className="default-btn"
                onClick={(e) => {
                  onApprove(facture.id).then(() => {
                    setConfirmApprove(false);
                    toast("success", "La facture a été approuvée");
                  });
                }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ListItem;
