import React, { useEffect, useState } from "react";
import ConsultantCardPreview from "../../Components/ConsultantCard/ConsultantCardPreview";
import Loader from "../../Components/Loader";
import {
  getConsultants,
  getConsultantsCount,
  getConsultantsBySkillsAndSearchFilter,
} from "../../Services/Consultants";
import Skills from "./../../Components/Skills";
import Pagination from "./../../Components/Pagination";
import SearchBar from "../../Components/SearchBar";
import { EBusinessUnit } from "../../Helpers/enums/business-unit.enum";
import Timeline from "../../Components/Timeline";

export const Consultants = () => {
  const [consultants, setConsultants] = useState([]);
  const [page, setPage] = useState(1);
  const [nbPages, setNbPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [optionalSkills, setOptionalSkills] = useState([]);

  const storedConsultantStates =
    localStorage.getItem("consultantSelectedStates")?.split(",") ?? [];

  const [consultantStates, setConsultantStates] = useState([
    {
      topLabel: "Désactivé",
      value: "DEACTIVATED",
      color: "var(--danger)",
      checked: storedConsultantStates.includes("DEACTIVATED"),
    },
    {
      topLabel: "En attente",
      value: "PENDING",
      color: "var(--blue-ny)",
      checked: storedConsultantStates.includes("PENDING"),
    },
    {
      topLabel: "Qualification",
      value: "QUALIFICATION",
      color: "#2BA1B8",
      checked: storedConsultantStates.includes("QUALIFICATION"),
    },
    {
      topLabel: "Activé",
      value: "ACTIVATED",
      color: "var(--primary-green)",
      checked: storedConsultantStates.includes("ACTIVATED"),
    },
  ]);

  const businessUnit = Object.values(EBusinessUnit);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("ALL");

  const onChangeBusinessUnitHandler = (e) => {
    setSelectedBusinessUnit(e.target.value);
  };

  const getConsultantStateValues = () => {
    return consultantStates.filter((cs) => cs.checked).map((cs) => cs.value);
  };

  const handleConsultantStateChange = (selectedValue) => {
    const updatedStates = consultantStates.map((state) => {
      if (state.value === selectedValue) {
        return { ...state, checked: !state.checked };
      } else {
        return state;
      }
    });
    setConsultantStates(updatedStates);
    localStorage.setItem(
      "consultantSelectedStates",
      updatedStates.filter((s) => s.checked).map((s) => s.value)
    );
  };

  const handleSubmit = (newValue) => {
    setSearch(newValue);
    setPage(1);
  };

  const handleRequiredSkillsChange = (newValue) => {
    setRequiredSkills(newValue);
    setPage(1);
  };

  const handleOptionalSkillsChange = (newValue) => {
    setOptionalSkills(newValue);
    setPage(1);
  };

  useEffect(() => {
    getConsultantsCount("state").then((res) => {
      const updatedStates = consultantStates.map((state) => {
        return {
          ...state,
          botLabel: res.data.find((c) => c.state === state.value)?.count,
        };
      });
      setConsultantStates(updatedStates);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (requiredSkills.length || optionalSkills.length) {
      const requiredSkillIds = requiredSkills.map((skill) => skill.id);
      const optionalSkillsIds = optionalSkills.map((skill) => skill.id);
      getConsultantsBySkillsAndSearchFilter(
        requiredSkillIds,
        optionalSkillsIds,
        page - 1,
        search,
        getConsultantStateValues(),
        selectedBusinessUnit
      ).then((res) => {
        setConsultants(res.data.consultants);
        setNbPages(res.data.numberOfPages);
        setLoading(false);
      });
    } else {
      getConsultants(
        page - 1,
        search,
        undefined,
        getConsultantStateValues(),
        selectedBusinessUnit
      ).then((res) => {
        setConsultants(res.data.consultants);
        setNbPages(res.data.numberOfPages);
        setLoading(false);
      });
    }
  }, [
    search,
    page,
    requiredSkills,
    optionalSkills,
    consultantStates,
    selectedBusinessUnit,
  ]);

  return (
    <div className="container py-4 px-5">
      <div className="d-flex flex-column mb-4 gap-3">
        <div className="d-flex flex-row">
          <Timeline
            items={consultantStates}
            onCheckboxChange={handleConsultantStateChange}
          />
        </div>
        <div>
          <label htmlFor="finder">Finder :</label>
          <SearchBar
            onSubmit={handleSubmit}
            placeholder="Rechercher par nom ou prénom du consultant (ex: John)"
          />
        </div>
        <div className="d-flex gap-3 justify-content-between">
          <div style={{ width: "100%" }}>
            <label htmlFor="finder">Compétences obligatoires :</label>
            <Skills
              placeholder="Rechercher par compétences (ex: Active Directory)"
              titleHidden={true}
              onChangeSkillsHandler={handleRequiredSkillsChange}
              consultantSkills={requiredSkills}
            ></Skills>
          </div>
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <label htmlFor="finder">Business unit :</label>
            <select
              className="default-input"
              style={{ background: "white", minHeight: "54px" }}
              onChange={onChangeBusinessUnitHandler}
              value={selectedBusinessUnit}
            >
              <option>ALL</option>
              {businessUnit.map((value, index) => (
                <option value={value} key={index}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "100%" }}>
            <label htmlFor="finder">Compétences facultatives :</label>
            <Skills
              placeholder="Rechercher par compétences (ex: Active Directory)"
              titleHidden={true}
              onChangeSkillsHandler={handleOptionalSkillsChange}
              consultantSkills={optionalSkills}
              backgroundColor="black"
            ></Skills>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="w-100 position-relative card"
          style={{ height: "300px" }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          {!consultants.length ? (
            <div>Aucun consultant</div>
          ) : (
            <>
              <div className="d-flex flex-wrap gap-4 justify-content-center">
                {consultants.map((clt, i) => {
                  const state = consultantStates.find(
                    (cs) => cs.value === clt.state
                  );
                  return (
                    <ConsultantCardPreview
                      consultant={clt}
                      key={i}
                      state={state.topLabel}
                      stateColor={state.color}
                    />
                  );
                })}
              </div>
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  currentPage={page}
                  nbPages={nbPages}
                  setPage={setPage}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
