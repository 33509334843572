import axios from "axios";
import { addLoader, removeLoader } from "./files-loader";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const Api = axios.create({
  headers: {
    "content-type": "application/json",
    "access-control-allow-headers": "*",
    "access-control-allow-origin": "*",
  },
  withCredentials: true,
});

Api.defaults.baseURL = process.env.NODE_ENV !== "development" ? "/api" : "";

export const ProtectedApi = axios.create({
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-headers": "*",
    "access-control-allow-origin": "*",
  },
  withCredentials: true,
});

ProtectedApi.defaults.baseURL =
  process.env.NODE_ENV !== "development" ? "/api" : "";

const refreshAuthLogic = () =>
  axios
    .post("/auth/refresh", { id: sessionStorage.getItem("USER_ID") })
    .then((tokenRefreshResponse) => {
      return Promise.resolve();
    });

createAuthRefreshInterceptor(ProtectedApi, refreshAuthLogic);

export const ProtectedFilesApiMultiPart = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
    "access-control-allow-headers": "*",
  },
});
ProtectedFilesApiMultiPart.defaults.baseURL =
  process.env.NODE_ENV !== "development" ? "/api" : "";

ProtectedFilesApiMultiPart.interceptors.request.use(
  async (req) => {
    addLoader();
    // const accessToken = await getAccessToken();
    // req.headers.common["Authorization"] = "Bearer " + accessToken;
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);
ProtectedFilesApiMultiPart.interceptors.response.use(
  (res) => {
    removeLoader();
    return res;
  },
  async (error) => {
    removeLoader();
    if (error.response) {
      const originalConfig = error.config;
      if (error.response.status === 409 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          //const rs = await RefreshAccessToken();
          // const refreshToken = await getRefreshToken();
          // const accessToken = rs.data.accessToken;
          // persistCurrentUser({ refreshToken, accessToken });
          // ProtectedFilesApi.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
          return ProtectedApi(originalConfig);
        } catch (_error) {
          // removeCurrentUser();
          window.location.reload();
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default Api;
