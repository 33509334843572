import { useFormik } from "formik";
import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import PopupContrat from "../../../Pages/Consultants/PopupContrat";
import Popup from "../../Popup";
import style from "../style.module.css";
import { contractsStatuses, contractsTypes } from "./../../../Helpers/data";
import { getDocumentByIdAndType } from "../../../Services/Consultant";

const ListItem = ({
  contract,
  onTerminate = (f) => f,
  onDelete,
  setContractsHandler,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEndContrat, setOpenEndContrat] = useState(false);

  const form = useFormik({
    initialValues: {
      endDate: "",
    },
    onSubmit: (values) => {
      const body = { ...contract, endDate: values.endDate };
      onTerminate(body).then(() => setOpenEndContrat(false));
    },
  });

  const getContractFileHandler = (contractFile) => {
    if (!contractFile?._id) {
      return;
    }
    getDocumentByIdAndType(contractFile._id, "contract").then((res) => {
      const file = res.data;
      if (!file) {
        return;
      }
      const url = b64ToObjectUrl(file.base64Document, file.filetype);
      window.open(url, "blank");
    });
  };
  return (
    <>
      {open && (
        <Popup show={open} onClose={() => setOpen(false)}>
          <PopupContrat
            setOpen={setOpen}
            currentContratToUpdate={contract}
            setContractsHandler={setContractsHandler}
          />
        </Popup>
      )}
      {openEndContrat && (
        <Popup show={openEndContrat} onClose={() => setOpenEndContrat(false)}>
          <div className={`${style["container"]} py-4 px-5 w-75 m-auto`}>
            <div className="flex flex-col justify-content-between mb-4">
              <div>
                <label>Mission :</label>
                <span>&nbsp;{contract.mission?.label}</span>
              </div>
              <div>
                <label>Date de début :</label>
                <span>
                  &nbsp;
                  {new Date(contract.startDate).toLocaleDateString()}
                </span>
              </div>
              <div>
                <label>Date de signature :</label>
                <span>
                  &nbsp;
                  {new Date(contract.signatureDate).toLocaleDateString()}
                </span>
              </div>
              <div>
                <label>Expiration initiale :</label>
                <span>
                  &nbsp;
                  {new Date(
                    contract.initialExpirationDate
                  ).toLocaleDateString()}
                </span>
              </div>
              <div>
                <label>Type de contrat :</label>
                <span>&nbsp;{contract.type}</span>
              </div>
            </div>
            <form
              onSubmit={form.handleSubmit}
              className="flex d-flex justify-content-between"
            >
              <div>
                <label htmlFor="endDate">
                  Renseignez la date de fin effective du contrat
                </label>
                <input
                  id="endDate"
                  name="endDate"
                  className="default-input"
                  placeholder="Fin effective du contrat"
                  type="date"
                  required
                  value={form.values.endDate}
                  onChange={form.handleChange}
                />
              </div>
              <div className="w-25 mt-4">
                <button className="default-btn" type="submit">
                  Sauvegarder
                </button>
              </div>
            </form>
          </div>
        </Popup>
      )}
      <div className={`${style["contracts-table-item"]}`}>
        {showMore && (
          <>
            <div
              className={`${style["show-more"]} d-flex flex-column align-items-center justify-content-end py-3 gap-3`}
            >
              <div
                className="small-text red-text cursor-pointer"
                onClick={(e) => {
                  setShowMore(false);
                  setOpenEndContrat(true);
                }}
              >
                Terminer le contrat
              </div>
              {/* DEACTIVATE THE DELETION
              <div
                className="small-text red-text cursor-pointer"
                onClick={(e) => {
                  onDelete(contract);
                }}
              >
                Supprimer le contrat
              </div> */}

              <div
                className="small-text cursor-pointer text-center"
                onClick={(e) => {
                  setOpen(true);
                  setShowMore(false);
                }}
              >
                Modifier le contrat
              </div>
            </div>
            <div
              className={`${style["close-show-more"]}`}
              onClick={(e) => setShowMore(false)}
            ></div>
          </>
        )}
        <div className="small-text bold">{contractsTypes[contract.type]}</div>
        <div className="small-text d-none d-md-flex">
          {contract?.signatureDate &&
            new Date(contract.signatureDate).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {contract?.startDate &&
            new Date(contract.startDate).toLocaleDateString()}
        </div>
        {/* <div className="small-text d-none d-md-flex">
          {contract && new Date(contract.updatedAt).toLocaleDateString()}
        </div> */}
        <div className="small-text d-none d-md-flex">
          {contract?.expirationDate &&
            new Date(contract.initialExpirationDate).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {contract?.expirationDate &&
            new Date(contract.expirationDate).toLocaleDateString()}
        </div>
        <div className="small-text d-none d-md-flex">
          {contract?.dailyRateAverage && contract.dailyRateAverage}
        </div>
        <div
          className="small-text blue-text d-none d-md-flex cursor-pointer"
          rel="noreferrer"
          href="/"
          onClick={() => getContractFileHandler(contract?.file)}
        >
          Ouvrir
        </div>
        <div
          className={`${style["contract-status"]} small-text`}
          status={contract ? contract.status : ""}
        >
          {contract ? contractsStatuses[contract.status] : "Manquant"}
        </div>
        <div className="wow-body text-center cursor-pointer">
          <FiMoreVertical onClick={(e) => setShowMore(true)} />
        </div>
      </div>
    </>
  );
};

export default ListItem;
