import React from "react";
import { FiBriefcase, FiGlobe, FiPhone } from "react-icons/fi";
import { b64ToObjectUrl } from "../../Helpers/base64-decode";
import style from "./style.module.css";

const ClientCard = ({ client }) => {
  const routeToClientDetails = () => {
    window.location.pathname = `/client/${client.id}/carnet-adresses`;
  }

  return (
    <div className={`${style["container"]} card border-light-grey-small`} onClick={routeToClientDetails}>
      <div className="d-flex flex-column">
        <div className={`${style["img-container"]}`}>
          {client.logo ? (
            <img
              src={b64ToObjectUrl(
                client.logo.base64Document,
                client.logo.filetype
              )}
              alt="candidature img"
            />
          ) : (
            <img src={"/wow-circle-white.svg"} alt="wheel of work logo" />
          )}
        </div>
        <div className="d-flex flex-column gap-2 p-3">
          <div className={`${style["label-title"]} bold`}>{client.label}</div>
          {client.sector && (
            <div className="w-100 small-text">
              <FiBriefcase /> {client.sector.label}
            </div>
          )}
          {client.websiteUri && (
            <a
              href={
                client.websiteUri.includes("https://")
                  ? client.websiteUri
                  : `https://${client.websiteUri}`
              }
              className="w-100 small-text text-decoration-underline"
              target="_blank"
              rel="noreferrer"
            >
              <FiGlobe /> {client.websiteUri}
            </a>
          )}
          {client.phoneNumber && (
            <div className="w-100 small-text">
              <FiPhone /> {client.phoneNumber}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
