import { useState } from "react";
import style from "./style.module.css";
import { FiMoreVertical } from "react-icons/fi";

// myActions =
// [{
//  label:string,
//  color: string,
//  action: function
// }]

export const Actions = ({ myActions = [] }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      {showMore && (
        <div>
          <div
            className={`${style["show-more"]} d-flex flex-column align-items-center justify-content-end py-3 gap-3`}
          >
            {myActions.map((myA, index) => (
              <div
                key={index}
                className={`small-text ${myA.color}-text cursor-pointer`}
                onClick={() => {
                  myA.action();
                  setShowMore(false);
                }}
              >
                {myA.label}
              </div>
            ))}
          </div>
          <div
            className={`${style["close-show-more"]}`}
            onClick={(e) => setShowMore(false)}
          ></div>
        </div>
      )}
      <div className="wow-body text-center cursor-pointer">
        <FiMoreVertical onClick={(e) => setShowMore(true)} />
      </div>
    </div>
  );
};
