
import style from "./style.module.css";
import { GiBilledCap } from "react-icons/gi";
import { FiBriefcase } from 'react-icons/fi';
import { useHistory } from "react-router";
import { Card } from "../Card";


const StarterFinisherCard = ({ consultantId, consultantFullName, missionTitle, societyTitle }) => {
    const history = useHistory();
    const routeToConsultantProfile = () => {
        if(consultantId) {
            const internUrl = `consultant/${consultantId}`
            history.push(internUrl)
        }
    }
    return (
    <Card classes={`${style['container']}`}>
        <div className='d-flex flex-column justify-content-between' style={{height: '-webkit-fill-available'}} onClick={routeToConsultantProfile}>
            <div className='d-flex green-text bold' style={{fontSize: 16, fontWeight: 600}}>
                <span>{consultantFullName}</span>
            </div>
            <div className='d-flex flex-row gap-3' style={{overflowWrap: 'anywhere'}}>
                <div><GiBilledCap /> {missionTitle}</div>
                <div><FiBriefcase /> {societyTitle}</div>
            </div>
        </div>
    </Card>
    )
}

export default StarterFinisherCard;