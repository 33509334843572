import { useState } from "react";

const Switch = ({ onChangeHandler, initState = false }) => {
  const [isChecked, setIsChecked] = useState(initState);

  const onChangeSwitchHandler = (event) => {
    const isCheckedVal = event.target.checked;
    setIsChecked(isCheckedVal);
    onChangeHandler(isCheckedVal);
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChangeSwitchHandler}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
