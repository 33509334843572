import { ProtectedApi, ProtectedFilesApiMultiPart } from "../Helpers/api";

const contractAdminController = "/contract-admin-partnership";

export const getContractsBySocietyId = (societyId, contractType) => {
  const url = `${contractAdminController}/society?societyId=${societyId}` + (contractType ? `&contractType=${contractType}` : '')
  return ProtectedApi.get(url);
};

export const createAnAdminContract = (contract) => {
  return ProtectedApi.post(contractAdminController, { ...contract });
};

export const updateAnAdminContract = (contract) => {
  return ProtectedApi.patch(contractAdminController, { ...contract });
};

export const UploadAdminContractFile = async (formData, type, args) => {
  const urlArgs = Object.keys(args)
    .map((key) => `&${key}=${args[key]}`)
    .join("");
  const url = `${contractAdminController}/files/type?type=${type}${urlArgs}`;
  return ProtectedFilesApiMultiPart.post(url, formData);
};
