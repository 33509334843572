import { ProtectedApi } from "../Helpers/api";

export const ADMIN = "/admin";

export const getUser = (id) => {
  return ProtectedApi.get(`${ADMIN}/users/${id}`);
};

export const getAdminUsers = () => {
  return ProtectedApi.get(`${ADMIN}/users/allAdmins`);
};

export const createUser = (infos) => {
  return ProtectedApi.post(`${ADMIN}/auth/signup`, infos);
};

export const updateUser = (id, infos) => {
  return ProtectedApi.patch(`${ADMIN}/users/${id}`, infos);
};

export const deleteUser = (id) => {
  return ProtectedApi.delete(`${ADMIN}/users/${id}`);
};
